<template>
    <template v-if="imageExtentions.includes(file.Type)">
        <img :src="file.URL" @click="downloadFile()" :style="{width: width, height: height}"/>
    </template>
    <template v-else-if="videoExtensions.includes(file.Type)">
        <video controls @click="downloadFile()" :style="{width: width, height: height}">
            <source :src="file.URL">
        </video>
    </template>
    <template v-else>
        <iframe :src="getIframeSrc(file.URL)" @click="downloadFile()" 
            :style="{width: isYoutubeVideo ? '500px' : width, height: isYoutubeVideo ? '300px' : height}" 
            @error="handleError" frameborder="0" scrolling="no" allow="fullscreen"/>
    </template>
</template>

<script>
import { ref } from 'vue';
import { getSavedFileURLFromPath } from '@/helpers.js';
import fileNotFoundPng from '@/assets/icon/file-not-found.png';

export default {
	props: {
        file: {
			type: Object,
			required: true
		},
        width: {
            type: String,
            default: '125px'
        },
        height: {
            type: String,
            default: '125px'
        }
    },
    setup(props, {emit}){

        const imageExtentions = ref(['jpeg', 'jpg', 'png', 'svg', 'webp']);
        const videoExtensions = ref(['mp4', 'webm', 'ogg']);

        const isYoutubeVideo = ref(false);

        const downloadFile = () => {
            const link = document.createElement("a");
            link.target = '_blank';
            link.href = props.file.URL;
            link.download = props.file.Name;
            link.click();
        }

        const handleError = (event) => {
            event.target.src = fileNotFoundPng;
            emit('invalidFile');
        }

        const getIframeSrc = (fileURL) => {
            const youtubeURL = getYoutubeURL(fileURL);
            if (youtubeURL) {
                isYoutubeVideo.value = true;
                return youtubeURL;
            }
            return fileURL
        }

        const getYoutubeURL = (url) => {
            // Regular expression pattern to match YouTube video URLs
            var pattern = /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?$/;

            // Test the URL against the pattern
            var match = url.match(pattern);

            // If there's a match, return the video ID; otherwise, return null
            if (match && match[1]) {
                return 'https://www.youtube.com/embed/' + match[1];
            } else {
                return null;
            }
        }

        return {
            imageExtentions,
            videoExtensions,
            downloadFile,
            handleError,
            getYoutubeURL,
            getIframeSrc,
            isYoutubeVideo
        }
    }
}
</script>
<style lang="scss" scoped>
    img, source, iframe{
        object-fit: contain;
    }
</style>