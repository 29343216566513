import { dynamicTranslation } from '@/helpers.js';
import { i18n } from '@/plugins/i18n/lang.js'

export const validateForm = (form) =>{
   let error = null;
   let translations = i18n.global.messages.value[i18n.global.locale.value];

   if(form.Name.length > 100){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['article_category']['fields']['name'],
         value: 100
      })
      return error;
   }

   if(form.Description && form.Description.length > 200){
        error = dynamicTranslation(translations['general']['errors']['max_length'], {
        attribute: translations['administration']['article_category']['fields']['description'],
        value: 200
        })
        return error;
    }

   return error;
}