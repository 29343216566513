<template>
	<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
		<GlobSpinner :isLoading="isLoading"/>
		<GlobXpanel :noShadow="true" :tools="false"  :avoidHR="true">
			<p class="section-heading">{{ $t('administration.asset.surveillance.general') }}</p>
			<GlobRow>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.asset.fields.name')}}*
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.asset.fields.name')"
						v-model="asset.form.Name"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.asset.fields.description')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.asset.fields.description')"
						v-model="asset.form.Description"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="6" v-if="assetCategories.length">
					<label>
						{{!showAssetCategoryAddForm ? $t('administration.asset.fields.asset_category') : $t('administration.asset.create_category')}}
						<span class="add-extra-item" @click="showAssetCategoryAddForm = !showAssetCategoryAddForm"><i class="fas fa-plus-circle"></i></span>
						<template v-if="!showAssetCategoryAddForm">
							<InputDynamic
							:fullWidth="true"
							:type="'multiselect'"
							:placeholder="$t('administration.asset.fields.asset_category')"
							v-model="asset.form.AssetCategories"
							:data="assetCategories"
							:selected="asset.form.AssetCategories"
							/>
						</template>
						<template v-else>
						   <InputDynamic
								:fullWidth="true"
								:type="'text'"
								:placeholder="$t('administration.asset.fields.asset_category')"
								v-model="newCategoryName"
							/>
							<p class="text-danger" v-if="newCategoryNameError">{{newCategoryNameError}}</p>

							<button @click="addNewCategory()" class="button button--small mas-mt-2" :disabled="!newCategoryName">{{$t('general.button.save')}}</button>
							<button @click="showAssetCategoryAddForm = false" class="button button--small button--orange">{{$t('general.button.cancel')}}</button>

						</template>
					</label>
				</GlobCol>
			</GlobRow>
			<GlobRow>
				<GlobCol :medium="6" v-if="assetGroups.length">
					<label>
						{{!showAssetGroupAddForm ? $t('administration.asset.fields.asset_groups') : $t('administration.asset.create_group')}}
						<span class="add-extra-item" @click="showAssetGroupAddForm = !showAssetGroupAddForm"><i class="fas fa-plus-circle"></i></span>
						<template v-if="!showAssetGroupAddForm">
							<InputDynamic
								:fullWidth="true"
								:type="'virtualselect'"
								:placeholder="$t('administration.asset.fields.asset_groups')"
								v-model="asset.form.AssetGroup"
								:data="assetGroups"
								/>
						</template>
						<template v-else>
							<InputDynamic
								:fullWidth="true"
								:type="'text'"
								:placeholder="$t('administration.asset.fields.asset_category')"
								v-model="newGroupName"
							/>
							<p class="text-danger" v-if="newGroupNameError">{{newGroupNameError}}</p>

							<button @click="addNewGroup()" class="button button--small mas-mt-2" :disabled="!newGroupName">{{$t('general.button.save')}}</button>
							<button @click="showAssetGroupAddForm = false" class="button button--small button--orange">{{$t('general.button.cancel')}}</button>
						</template>

					</label>
				</GlobCol>
			</GlobRow>

			<GlobRow>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.asset.fields.matching_field')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.asset.fields.matching_field')"
						v-model="asset.form.MatchingField"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.asset.fields.parent')}}
						<InputDynamic
						:fullWidth="true"
						:type="'virtualselect'"
						:placeholder="$t('administration.asset.fields.parent')"
						v-model="asset.form.ParentId"
						:data="assetParents"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.asset.fields.manufacture')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.asset.fields.manufacture')"
						v-model="asset.form.Manufacture"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.asset.fields.model')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.asset.fields.model')"
						v-model="asset.form.Model"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.asset.fields.serial_number')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.asset.fields.serial_number')"
						v-model="asset.form.SerialNumber"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.asset.fields.production_year')}}
						<InputDynamic
						:fullWidth="true"
						:type="'number'"
						:placeholder="$t('administration.asset.fields.production_year')"
						v-model="asset.form.ProductionYear"
						:preventDecimal="true"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.asset.fields.installation_year')}}
						<InputDynamic
						:fullWidth="true"
						:type="'number'"
						:placeholder="$t('administration.asset.fields.installation_year')"
						v-model="asset.form.InstallationYear"
						:preventDecimal="true"
						/>
					</label>
				</GlobCol>
			</GlobRow>
			<p class="text-danger mas-mt-2" v-if="errorMessage">{{errorMessage}}</p>
			<button @click="save" class="button mas-mt-4" :disabled="isSaveButtonDisabled">{{$t('general.button.save')}}</button>
		</GlobXpanel>
	</GlobXpanel>

</template>

<script>
import {onMounted, reactive, ref, computed, watchEffect } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import {useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { validateForm } from "@/views/crud/Asset/common.js";

export default {
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.assets);
	const api = useApi();
	const router = useRouter();

	const notifications = useNotifications();
	const errorMessage = ref(null);
	const isLoading = ref(false);

	const asset = reactive({
		form: {
			Name: "",
			AssetCategories: []
		},
	});

	const assetCategories = ref([]);
	const assetGroups = ref([]);
	const assetParents = ref([]);

	onMounted(()=> {
		endpoint.value.getDependencies().then((res) => {
			assetCategories.value = res.asset_categories.map((item) => {
				return {
					name: item.Name,
					value: item.Id
				}
			});
			assetGroups.value = res.asset_groups.map((item) => {
				return {
					name: item.Name,
					value: item.Id
				}
			});

			assetParents.value = res.assets.map((item) => {
				return {
					name: item.Name,
					value: item.Id
				}
			});
		});
	})

    const save = () => {
		
		errorMessage.value = validateForm(asset.form);

		if(errorMessage.value){
			return false;
		}

		isLoading.value = true;
		endpoint.value.create(asset.form).then((res) => {
			isLoading.value = false;
			notifications.push(
				{
					msg: tm('administration')['asset']['create_success']
				},
				"success",
				false
			);
			emit('created', res.asset)
		});
    };

	const isSaveButtonDisabled = computed(() => {
		return !asset.form.Name ? true : false
	})

		//Add category
	const showAssetCategoryAddForm = ref(false);
	const newCategoryName = ref(null);
	const newCategoryNameError = ref(false);

	const addNewCategory = () => {
		newCategoryNameError.value = false;
		if(newCategoryName.value.length > 50){
			newCategoryNameError.value = dynamicTranslation(tm('general.errors.max_length'),
			 {
				attribute: tm('administration.asset.fields.asset_category'),
				value: 50
			 }
			)
			return false;
		}
		isLoading.value = true;
		endpoint.value.createCategory({Name:newCategoryName.value}).then((res) => {
			assetCategories.value.push({
				name: res.data.Name,
				value: res.data.Id,
			})
			asset.form.AssetCategories.push(res.data.Id)
			showAssetCategoryAddForm.value = false;
			isLoading.value = false;
		})
	}

	//Add group
	const showAssetGroupAddForm = ref(false);
	const newGroupName = ref(null);
	const newGroupNameError = ref(false);

	const addNewGroup = () => {
		newGroupNameError.value = false;
		if(newGroupName.value.length > 50){
			newGroupNameError.value = dynamicTranslation(tm('general.errors.max_length'),
			 {
				attribute: tm('administration.asset.fields.asset_category'),
				value: 50
			 }
			)
			return false;
		}
		isLoading.value = true;
		endpoint.value.createGroup({Name:newGroupName.value}).then((res) => {
			assetGroups.value.push({
				name: res.data.Name,
				value: res.data.Id,
			})
			asset.form.AssetGroup = res.data.Id;
			showAssetGroupAddForm.value = false;
			isLoading.value = false;
		})
	}

    return {
      save,
	  errorMessage,
	  asset,
	  isLoading,
	  isSaveButtonDisabled,
	  assetCategories,
	  assetGroups,
	  assetParents,
	 //Add category
	  showAssetCategoryAddForm,
	  newCategoryName,
	  newCategoryNameError,
	  addNewCategory,
	  //Add group
	  showAssetGroupAddForm,
	  newGroupName,
	  newGroupNameError,
	  addNewGroup
    };
  },
};
</script>

<style lang="scss">
.mas-edit-asset {
  &__image {
    width: 200px;
    display: block;
    margin-bottom: 0.5rem;
  }
}
.mas-asset-lockers {
  display: flex;
	margin: 0.5rem 1rem 2rem 1rem;
	> div {
		margin-right: 2rem;
	}
  p {
    font-size: 0.8rem;
    text-decoration: uppercase;
  }
  &__list {
		display: flex;
		&--small {
			font-size: .6rem;
		}
  }
  span {
    padding: 0.35rem 1rem;
    border-radius: 4px;
		@include elevation(1);
    margin-right: 1.5rem;
		font-size: 0.8rem;
		align-items: center;
		display: flex;
		a {
			display: flex;
			font-size: 0.8rem;
			align-items: center;
		}
    i {
      color: $mas-steelGray;
      margin-right: 0.8rem;
    }
  }
}
</style>
