
import StockPlace from "@/views/crud/StockPlace/StockPlace.vue";

export const stockPlace = [{
  path: "/admin/view/stock-places",
  component: StockPlace,
  isCRUD: true,
  name: "StockPlace",
  nav: {
    name: "Admin",
    icon: "fa-warehouse"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Stock place" }]
  },
}
]
