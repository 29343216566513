<template>
    <div class="stopped-minutes-dash">
        <GlobXpanel>
             <h2>{{ $t('dashboard.stopped_minutes') }}</h2>
             <p class="count">{{ stoppedMinutes }}</p>
        </GlobXpanel>
    </div>
</template>

<script>

export default {
    props: ["stoppedMinutes"],
    setup(props) {
        return {
        }
    }
}
</script>
<style lang="scss">
.stopped-minutes-dash {
    .ips-xpanel__content {
        padding-top: 4px;
    }

    h2 {
        text-align: center;
    }

    p.count{
        margin: 0;
        text-align: center;
        font-weight: bold;
        font-size: 70px;
    }
}
</style>
