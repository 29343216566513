<template>
	<div class="grid-container" :class="showBorderBottom ? 'border-bottom': ''">
		<div class="grid-x" :class="margin ? ' grid-margin-x grid-margin-y' : ''">
			<slot/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		margin: {
			required: false,
			default: true
		},
		showBorderBottom: {
			required: false,
			default: false
		},
	}
}
</script>

<style lang="scss">
	.grid-container {
		width: 100%;
		max-width: 100% !important;
		padding-left: 0 !important;


	}

	.grid-container.border-bottom{
		border-bottom: 1px solid $mas-lightGray;
	}
</style>