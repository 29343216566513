<template>
    <GlobXpanel :noShadow="true" :avoidHR="true" :isCRUD="true">
        <GlobSpinner :isLoading="isLoading" />
        <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
            <GlobRow>
                <GlobCol :medium="3">
                    <label>
                        {{$t('administration.service_description.fields.name')}}*
                        <InputDynamic :fullWidth="true" :type="'text'"
                            :placeholder="$t('administration.service_description.fields.name')"
                            v-model="serviceDescription.form.Name" />
                    </label>
                </GlobCol>
                <GlobCol :medium="6" v-if="assetGroups.length">
                    <label>
                        {{$t('administration.service_description.fields.asset_groups')}}
                            <InputDynamic :fullWidth="true" :type="'multiselect'"
                                :placeholder="$t('administration.service_description.fields.asset_groups')"
                                v-model="serviceDescription.form.AssetGroup" :data="assetGroups" />
                    </label>
                </GlobCol>
            </GlobRow>
            <GlobRow>
                <GlobCol :medium="9">
                    <label>
                        {{$t('administration.service_description.fields.description')}}*
                        <InputDynamic :fullWidth="true" :type="'textarea'"
                            :rows="10"
                            :placeholder="$t('administration.service_description.fields.description')"
                            v-model="serviceDescription.form.Description" />
                    </label>
                </GlobCol>
            </GlobRow>

            <p class="text-danger mas-mt-2" v-if="errorMessage">{{errorMessage}}</p>
            <button @click="save" class="button mas-mt-4"
                :disabled="isSaveButtonDisabled">{{$t('general.button.save')}}</button>
        </GlobXpanel>
    </GlobXpanel>

</template>

<script>
import {onMounted, reactive, ref, computed, watchEffect } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import {useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { validateForm } from "@/views/crud/ServiceDescription/common.js";

export default {
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.serviceDescriptions);
	const api = useApi();
	const router = useRouter();

	const notifications = useNotifications();
	const errorMessage = ref(null);
	const isLoading = ref(false);

	const serviceDescription = reactive({
		form: {
			Name: "",
		},
	});

	const assetGroups = ref([]);
	onMounted(()=> {
		endpoint.value.getDependencies().then((res) => {
			assetGroups.value = res.asset_groups.map((item) => {
				return {
					name: item.Name,
					value: item.Id
				}
			});
		});

	})

    const save = () => {
        errorMessage.value = validateForm(serviceDescription.form);

		if(errorMessage.value){
			return false;
		}
		isLoading.value = true;
		endpoint.value.create(serviceDescription.form).then((res) => {
			isLoading.value = false;
			if(res.errors && res.errors.Description){
				errorMessage.value = tm(res.errors.Description[0]);
			}else{
				notifications.push(
					{
						msg: tm('administration')['service_description']['create_success']
					},
					"success",
					false
				);
				emit('created', res.serviceDescription)
			}
		});
    };

	const isSaveButtonDisabled = computed(() => {
		return !serviceDescription.form.Description || !serviceDescription.form.Name ? true : false
	})


    return {
      save,
	  errorMessage,
	  serviceDescription,
	  isLoading,
	  isSaveButtonDisabled,
	  assetGroups
    };
  },
};
</script>
