<template>
	<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
		<GlobSpinner :isLoading="isLoading"/>
		<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
			<GlobRow>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.stock_place.fields.name')}}*
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.stock_place.fields.name')"
						v-model="stockPlace.form.Name"
						/>
					</label>
				</GlobCol>
				 <GlobCol :medium="3">
						<label>
							{{ $t('administration.stock_place.fields.parent') }}
							<InputDynamic
							:fullWidth="true"
							:type="'virtualselect'"
	                        :data="parents"
							:placeholder="$t('administration.stock_place.fields.parent')"
							v-model="stockPlace.form.ParentId"
							/>
						</label>
				</GlobCol>
				<GlobCol :medium="12">
					<p class="text-danger mas-mt-2" v-if="errorMessage">{{ errorMessage }}</p>
					<button @click="save" class="button mas-mt-4" :disabled="isSaveButtonDisabled">{{ $t('general.button.save') }}</button>
				</GlobCol>
			</GlobRow>
		</GlobXpanel>
	</GlobXpanel>

</template>

<script>
import {onMounted, reactive, ref, computed, watchEffect } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import {useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { validateForm } from "@/views/crud/StockPlace/common.js";

export default {
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.stockPlaces);
	const api = useApi();
	const router = useRouter();

	const notifications = useNotifications();
	const errorMessage = ref(null);
    const parents = ref([]);
	const isLoading = ref(false);

	const stockPlace = reactive({
		form: {
			Name: "",
            ParentId: "",
		},
	});


	onMounted(()=> {
        isLoading.value = true;
        endpoint.value.getDependencies().then((res) => {
            parents.value = res.stock_places;
            isLoading.value = false;
        });
	})

    const save = () => {
		
		errorMessage.value = validateForm(stockPlace.form);

		if(errorMessage.value){
			return false;
		}

		isLoading.value = true;
		endpoint.value.create(stockPlace.form).then((res) => {
			isLoading.value = false;
			if(res.success){
				notifications.push(
					{
						msg: tm('administration')['stock_place']['create_success']
					},
					"success",
					false
				);
				emit('created')
				emit('close')
			}else{
				for(let errorField in res.errors){
				 	let error = JSON.parse(res.errors[errorField][0]);
					errorMessage.value =  tm(error.error).replace('{attribute}', tm(error.attribute));
					break;
				}
			}

			
		});
    };

	const isSaveButtonDisabled = computed(() => {
		return !stockPlace.form.Name ? true : false
	})


    return {
      save,
	  errorMessage,
	  stockPlace,
	  isLoading,
	  isSaveButtonDisabled,
      parents
    };
  },
};
</script>

<style lang="scss">
</style>
