import Cookies from "js-cookie";
import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL_PHP;
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.defaults.transformRequest = (data, headers) => {
	headers["Authorization"] = Cookies.get("MASFrontendSessionKey");
	return data;
}
export const php = axios.create({
  baseURL: process.env.BASE_URL_PHP,
  headers: {
		accept: "application/json, multipart/form-data",
		'Content-Type': "application/json"
  },
  transformRequest: (data, headers) => {
    headers["Authorization"] = Cookies.get("MASFrontendSessionKey");
    return JSON.stringify(data);
  }
});

export const asp = axios.create({
  baseURL: process.env.BASE_URL_ASP,
  headers: {
    accept: "application/json",
		'Content-Type': "application/json"
  },
  transformRequest: (data, headers) => {
    headers["Authorization"] = Cookies.get("MASFrontendSessionKey");
    return data;
  }
});

export const createPhpCrud = (endpoint) => ({
  async get(data = null) {
    const res = await php.get(`${endpoint}`, data);
    return res.data;
  },
  async getById(id, includeDependencies = false) {
    const res = await php.get(`${endpoint}/${id}?includeDependencies=${includeDependencies}`);
    return res.data;
  },
  async update(id, data) {
    const res = await php.put(`${endpoint}/${id}`, data);
    return res.data;
  },
  async create(data) {
    const res = await php.post(`${endpoint}`, data);
    return res.data;
  },
  async delete(id) {
    const res = await php.delete(`${endpoint}/${id}`);
    return res.data;
  }
});

export const body = {
  customers: createPhpCrud("/customer"),
    authenticate: async () => {
      return php.get("/currentuser").then((res) => res.data);
    },
  async login(data, notifications) {
    return new Promise((resolve, reject) => {
      php
        .post("login", data)
        .then((res) => {
          if (res.data.success == 1) {
            Cookies.set("MASFrontendSessionKey", res.data.SessionKey)
            resolve(res.data);
          } else {
            notifications.push(
              {
                title: "Wrong credentials",
                msg: "Du har angett felaktiga inloggninsuppgifter"
              },
              "error",
              false,
              false
            );
            reject(res.data);
          }
        })
        .catch((_) => {
          notifications.push(
            {
              title: "Connections problems",
              msg: "Någonting gick fel, kontrollera din internetanslutning."
            },
            "error",
            false,
            false
          );
        });
    });
  },
  async logout() {
    return php.post("logout").then((res) => {
      Cookies.remove("MASFrontendSessionKey");
      return res;
    });
  },
  articles: {
    ...createPhpCrud("/article"),
    async addOrUpdateArticleSupplier(id, data){
      const res = await php.post(`/article/${id}/add-or-update-supplier`, data);
      return res.data;
	  },
	async getDependencies() {
		const res = await php.get(`/article/get-dependencies`);
		return res.data;
	},
    async deleteArticleSupplier(id, supplierId){
      const res = await php.delete(`/article/${id}/delete-supplier/${supplierId}`);
      return res.data;
    }
  },
  assets: {
	...createPhpCrud("/asset"),
    async getDependencies(id, data){
      const res = await php.get(`/asset/get-dependencies`);
      return res.data;
    },
    async createCategory(data){
      const res = await php.post(`/asset/create-category`, data);
      return res.data;
    },
    async createGroup(data){
      const res = await php.post(`/asset/create-group`, data);
      return res.data;
    },
    async getOeeReasons(id){
      const res = await php.get(`/asset/${id}/get-oee-reasons`);
      return res.data;
    },
    async addArticle(id, data){
      const res = await php.post(`/asset/${id}/add-article`, data);
      return res.data;
    },
    async removeArticle(id, assetArticleId){
      const res = await php.delete(`/asset/${id}/remove-article/${assetArticleId}`);
      return res.data;
    },
    async updateArticle(id, assetArticleId, data){
      const res = await php.post(`/asset/${id}/update-article/${assetArticleId}`, data);
      return res.data;
    },
    async startUnplannedMaintenance(id, data){
      const res = await php.post(`/asset/${id}/start-unplanned-maintenance`, data);
      return res.data;
    },
	//Maintenance plans
	async saveOrUpdateMaintenancePlan(id,data){
		const res = await php.post(`/asset/${id}/save-or-update-maintenance-plan`, data);
		return res.data;
	},
  async deleteMaintenancePlan(planId){
		const res = await php.delete(`/asset/delete-maintenance-plan/${planId}`);
		return res.data;
	},

	// Asset service reasons
	async addServiceReason(id, data){
		const res = await php.post(`/asset/${id}/add-service-reason`, data);
		return res.data;
	},
  async removeServiceReason(id, assetServiceReasonRelationId){
		const res = await php.delete(`/asset/${id}/remove-service-reason/${assetServiceReasonRelationId}`);
		return res.data;
	},

  //surveillance/OEE
  async addOrUpdateAssetSurveillance(id, data){
		const res = await php.post(`/asset/${id}/add-oee-connection`, data);
		return res.data;
	},
  async addSurveillanceServer(id, data){
    const res = await php.post(`/asset/${id}/add-new-oee-server`, data);
		return res.data;
  },
  async removeAssetSurveillance(id){
    const res = await php.delete(`/asset/${id}/remove-oee-connection`);
		return res.data;
  },

  //Attachement
  async getNotAddedFiles(id){
    const res = await php.get(`/asset/${id}/get-not-added-files`);
    return res.data;
  },
  async addFileToAsset(id, fileLibraryId){
		const res = await php.post(`/asset/${id}/add-file-to-asset/${fileLibraryId}`);
		return res.data;
	},
  async removeFileFromAsset(id, assetMediaId){
		const res = await php.delete(`/asset/${id}/remove-file-from-asset/${assetMediaId}`);
		return res.data;
	},
  async sortFiles(id, files){
		const res = await php.post(`/asset/${id}/sort-files`, files);
		return res.data;
	},

  //Oee reasons
  async addOrUpdateAssetOeeReason(id, data){
		const res = await php.post(`/asset/${id}/add-or-update-oee-reason`, data);
		return res.data;
	},
  async deleteAssetOeeReason(id, reasonId){
		const res = await php.delete(`/asset/${id}/delete-oee-reason/${reasonId}`);
		return res.data;
	},
  

  },
  articleCategories: {
	...createPhpCrud("/article-category"),
  },
  dashboard: {
    async index() {
      const res = await php.get('/dashboard');
      return res.data;
    }
  },
  troubleshootGuides: {
    ...createPhpCrud("/troubleshooting"),
    async create(data){
      const res = await axios.post(`/troubleshooting`, data);
      return res.data;
    },
    async getDependencies(){
		const res = await php.get(`/troubleshooting/get-dependencies`);
		return res.data;
	},
    async update(id, data){
      const res = await axios.post(`/troubleshooting/${id}`, data);
      return res.data;
    },
    async searchTags(id, data){
      const res = await php.get(`troubleshooting/search-tags`, {params: data});
      return res.data;
    },
    async addorRemoveFile(id, data){
      const res = await php.post(`troubleshooting/${id}/add-or-remove-file`, data);
      return res.data;
    },
  },
  serviceDescriptions: {
	...createPhpCrud("/service-description"),
  async get(data = {}) {
    const res = await php.get('/service-description', {params: data});
    return res.data;
    },
	async getDependencies(id, data){
		const res = await php.get(`/service-description/get-dependencies`);
		return res.data;
	},
	async saveOrUpdateCheckList(id, data){
		const res = await axios.post(`/service-description/${id}/save-or-update-check-list`, data);
		return res.data;
	},
  async deleteCheckList(id, checkListId){
		const res = await php.delete(`/service-description/${id}/delete-check-list/${checkListId}`);
		return res.data;
	},
  async getNotAddedFiles(id){
    const res = await php.get(`/service-description/${id}/get-not-added-files`);
    return res.data;
  },
  async addFileToDescription(id, fileLibraryId){
		const res = await php.post(`/service-description/${id}/add-file-to-description/${fileLibraryId}`);
		return res.data;
	},
  async removeFileFromDescription(id, descriptionMediaId){
		const res = await php.delete(`/service-description/${id}/remove-file-from-description/${descriptionMediaId}`);
		return res.data;
	},
  async sortFiles(id, files){
		const res = await php.post(`/service-description/${id}/sort-files`, files);
		return res.data;
	},
  },
  stockPlaces: {
    ...createPhpCrud("/stock-place"),
    async getDependencies(){
      const res = await php.get(`/stock-place/get-dependencies`);
      return res.data;
	  },
  },
  maintenancePlans: {
    async get(data = {}) {
      const res = await php.get('maintenance-plan', {params: data});
      return res.data;
    },
    async getServiceLogs(id) {
      const res = await php.get(`maintenance-plan/${id}/service-logs`);
      return res.data;
    },
    async getServiceDetails(id) {
      const res = await php.get(`maintenance-plan/${id}/execute-service-details`);
      return res.data;
    },
    async  startService(id) {
      const res = await php.post(`maintenance-plan/${id}/start-service`);
      return res.data;
    },
    async joinToService(id){
      const res = await php.post(`maintenance-plan/${id}/join-to-service`);
      return res.data;
    },
    async updateStatus(id, data){
      const res = await php.post(`maintenance-plan/${id}/update-status`, data);
      return res.data;
    },
    async uploadFile(id, data){
      const res = await axios.post(`maintenance-plan/${id}/upload-file`, data);
      return res.data;
    },
    async deleteFile(id, fileId){
      const res = await php.delete(`maintenance-plan/${id}/delete-file/${fileId}`);
      return res.data;
    },
    async searchTags(id, data){
      const res = await php.get(`maintenance-plan/${id}/search-tags`, {params: data});
      return res.data;
    },
    async updateExternalSupplierBooking(id, data){
      const res = await php.put(`maintenance-plan/${id}/update-external-supplier-booking`, data);
      return res.data;
    },
    async getSinglePlaningDependencies(){
      const res = await php.get(`maintenance-plan/single-planing/dependencies`);
      return res.data;
    },
	async saveSinglePlaning(data){
		const res = await php.post(`maintenance-plan/single-planing`, data);
		return res.data;
	}
  },
  serviceLogs: {
    async get(data = {}) {
      const res = await php.get('service-logs', {params: data});
      return res.data;
    },
    async getDetails(id) {
      const res = await php.get(`service-logs/${id}/details`);
      return res.data;
    },
    async getApprovalDetails(id) {
      const res = await php.get(`service-logs/${id}/approval-details`);
      return res.data;
    },
    async updateApprovalStatus(id, data) {
      const res = await php.post(`service-logs/${id}/update-approval-status`, data);
      return res.data;
    },

  },
  oee: {
    async get(data = {}) {
      const res = await php.get('oee', {params: data});
      return res.data;
    },
    async saveReport(id, data) {
      const res = await php.post(`oee/${id}/save-report`, data);
      return res.data;
    },
    async getApprovalDetails(id) {
      const res = await php.get(`oee/${id}/approval-details`);
      return res.data;
    },
    async updateApprovalStatus(id, data) {
      const res = await php.post(`oee/${id}/update-approval-status`, data);
      return res.data;
    },
  },
  fileLibrary: {
    ...createPhpCrud("/file-library"),
    async create(data){
      const res = await axios.post(`file-library`, data);
      return res.data;
    },
    async update(id, data){
      const res = await axios.post(`file-library/${id}`, data);
      return res.data;
    },
  },
  apiKeys: {
	...createPhpCrud("/api-key"),
  },
  users: {
	...createPhpCrud("/user"),
  },
  suppliers: {
    ...createPhpCrud("/supplier"),
      async getDependencies(){
        const res = await php.get(`/supplier/get-dependencies`);
        return res.data;
      },
  },
  php,
  asp,
  filterURLs: {
    maintenancePlans: '/maintenance-plan',
    serviceLogs: '/service-logs',
    oee: '/oee'
  }
};