<template>
  <div class="locale-changer">
	<span :class="`fi fi-${getFlag($i18n.locale)} fis`"></span> 
    <select v-model="$i18n.locale" @change="setLang($i18n.locale)" style="border: none; background-color: transparent; margin-right: 1rem;">
      <option v-for="(lang, i) in langs" :value="lang.val" :key="`Lang${i}`">{{ lang.name }}</option>
    </select>
  </div>
</template>

<script>
import langs from './langs.json';
import Cookies from "js-cookie";

export default {
  name: 'locale-changer',
   setup() {
    const setLang = (lang) => {
		Cookies.set('KIS_LANG', lang);
	}
	const getFlag = (val) => {
        return langs.find(i => i.val == val) ? langs.find(i => i.val == val).flag : null
	}
	
    return {
		  langs,
		  getFlag,
		  setLang,
		}
	}
}
</script>

<style lang="scss" scoped>
.locale-changer{
  display : flex;
  select{
    color: $ma-cornflowerBlue;
    font-weight: 400;
		font-size: 16px;
  }
}

.fis{
  height: auto;
  width: 30px;
  margin-right: 0.2rem;
  border-radius: 50px;

}

select:focus{
  outline: none;
}
</style>