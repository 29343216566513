<template>
	<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true" class="file-library">
		<GlobSpinner :isLoading="isLoading"/>
		<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
			<GlobRow>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.file_library.fields.name')}}*
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.file_library.fields.name')"
						v-model="fileLibrary.Name"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="6">
					<label>
						{{$t('administration.file_library.fields.description')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.file_library.fields.description')"
						v-model="fileLibrary.Description"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<GlobQrCode 
                        :size="100"
                        :enableDownload="false" 
                        :content="generateQRCodeContentURL('filelibrary', fileLibrary.Id, 'mobile')" 
                        @enlargeQRCodeActive="(val) => $emit('styleParentModal', val)" />
				</GlobCol>
			</GlobRow>
			<GlobRow :class="fileLibrary.Type ? 'preview-image-show' : ''">
				<template v-if="fileLibrary.Type">
					<GlobCol :medium="3" v-if="fileLibrary.Type">
						<span class="uploaded-file-type">{{fileLibrary.Type}}</span>
					</GlobCol>
					<GlobCol :medium="6" class="preview" v-if="fileLibrary.URL">
						<GlobFilePreview 
							:width="'200px'"
							:height="'200px'"
							:file ="{
								Type: fileLibrary.Type,
								URL: fileLibrary.URL
							}"/>
					</GlobCol>
					<GlobCol :medium="3"></GlobCol>
				</template>
				<template v-if="fileLibrary.ExternalLink && showLinkPreview">
					<GlobCol :medium="6" class="preview">
						<GlobFilePreview 
							@invalidFile="invalidExternalLink = true;"
							:width="'200px'"
							:height="'200px'"
							:file ="{
								URL: fileLibrary.ExternalLink
							}"/>
					</GlobCol>
					<GlobCol :medium="6"></GlobCol>
				</template>

				<!-- Uploaded and changed time -->
				<GlobCol :medium="2" class="mas-mt-5">
					<label>
						{{$t('administration.file_library.fields.uploaded')}}
						<h4 class="date-record">{{fileLibrary.Uploaded}}</h4>
					</label>
                </GlobCol>
				<GlobCol :medium="3" class="mas-mt-5">
					<label>
						{{$t('administration.file_library.fields.changed')}}
						<h4 class="date-record">{{fileLibrary.Changed}}</h4>
					</label>
                </GlobCol>
				<GlobCol :medium="7"></GlobCol>

				<!-- Show used places-->
				<GlobCol :medium="6" class="mas-mt-5" v-if="usedIn && usedIn.length">
					<label>
						{{$t('administration.file_library.fields.used_in')}}
						<div v-for="usedInDescription in usedIn" :key="usedInDescription.Id">
							<h4 class="date-record">{{usedInDescription.service_description.Name}}</h4>
						</div>
					</label>
                </GlobCol>
			</GlobRow>
			<GlobRow>
				<GlobCol :medium="12" class="mas-mt-5">
						<label>
						{{$t('administration.file_library.fields.web_link')}}
						<InputDynamic
							:fullWidth="true"
							:type="'text'"
							:placeholder="$t('administration.file_library.fields.web_link')"
							v-model="fileLibrary.ExternalLink"
							:inputIcon="'fas fa-sync'"
							@inputIconClicked="fetchURL()"
							:disabled="fileLibrary.Type"
						 />
						 <p class="text-danger" v-if="invalidExternalLink">{{$t('administration.file_library.invalid_link')}}</p>
					</label>
				 </GlobCol>
                 <GlobCol :medium="12" class="file-upload-area"  :class="fileLibrary.ExternalLink ? 'disable-dropzone' : ''">
				 	<span class="refresh" @click="getData()">{{$t('general.refresh_attached_files')}}<i class="fas fa-redo-alt"></i></span>
                    <GlobFileUpload 
                        :addedFiles="addedFiles"
						@filesAdded="(files) => filesAdded(files)" 
						@filesRemoved="fileRemoved()"/>
                 </GlobCol>
			</GlobRow>

			<p class="text-danger mas-mt-2" v-if="errorMessage">{{errorMessage}}</p>
			<button @click="save" class="button mas-mt-4" :disabled="isSaveButtonDisabled">{{$t('general.button.save')}}</button>
			<button @click="$emit('showDeleteConfirmModal')" class="button button--red">{{$t('general.button.delete')}}</button>
		</GlobXpanel>
	</GlobXpanel>

</template>

<script>
import {onMounted, reactive, ref, computed, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import {useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { validateForm } from "@/views/crud/FileLibrary/common.js";
import { generateQRCodeContentURL } from '@/helpers.js';

export default {
  props:['itemId'],
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.fileLibrary);
	const api = useApi();
	const router = useRouter();

	const isMounted = ref(true)
	const notifications = useNotifications();
	const errorMessage = ref(null);
	const isLoading = ref(false);

	const fileLibrary = reactive({
		    Id: "",
			Name: "",
			Description: "",
            Type: "",
			URL: "",
            File: null,
			ExternalLink: null,
            IsFileRemoved: false
	});
	const showLinkPreview = ref(false);
	const invalidExternalLink = ref(false);

	const usedIn = ref([]);

    const addedFiles = ref([]);

    onMounted(() => {
		getData();
    })

	const getData = () => {
		isLoading.value = true;
		endpoint.value.getById(props.itemId).then((res) => {
			fileLibrary.Id = res.data.Id;
            fileLibrary.Name = res.data.Name;
            fileLibrary.Description = res.data.Description;

			fileLibrary.Uploaded = res.data.Uploaded;
			fileLibrary.Changed = res.data.Changed;
			fileLibrary.Type = null;

			fileLibrary.ExternalLink = res.data.ExternalLink;
			fetchURL();

			addedFiles.value = [];
			if(res.data.URL){
				fileLibrary.Type = res.data.Type;
				fileLibrary.URL = res.data.URL;
				
				addedFiles.value.push({
					'Id': res.data.Id,
					'URL': res.data.URL
				})
			}


			usedIn.value = res.data.ServiceDescriptions;

            isLoading.value = false;
			showLinkPreview.value = true;
        })
	}

	

	const filesAdded = (files) => {
		if(files){			
			fileLibrary.File = files[0];
			fileLibrary.Type = files[0].name.split('.').pop();	
			fileLibrary.URL =  URL.createObjectURL(files[0]);
		}
	}

	const fileRemoved = () => {
		fileLibrary.File = null;
		fileLibrary.Type = null;
		fileLibrary.URL = null;
        fileLibrary.IsFileRemoved = 1;
	}

    const save = () => {
		
		errorMessage.value = validateForm(fileLibrary);

		if(errorMessage.value){
			return false;
		}

		isLoading.value = true;

		let formData = new FormData();

		Object.keys(fileLibrary).forEach((key) => {
			formData.append(key, fileLibrary[key] ? fileLibrary[key] : '')
		})

		endpoint.value.update(props.itemId, formData).then((res) => {
			if(res.success){
				notifications.push(
					{
						msg: tm('administration')['file_library']['update_success']
					},
					"success",
					false
				);
				emit('updated', res.data)
			}else{
				for(let errorField in res.errors){
				 	let error = JSON.parse(res.errors[errorField][0]);
					errorMessage.value =  tm(error.error).replace('{attribute}', tm(error.attribute));
					break;
				}
			}
			isLoading.value = false;
		});
    };

	const isSaveButtonDisabled = computed(() => {
		if(!fileLibrary.Name){
			return true;
		}

		if(fileLibrary.ExternalLink && invalidExternalLink.value){
			return true;
		}

		if(!fileLibrary.Type && !fileLibrary.ExternalLink){
			return true;
		}

		return false;
	})

	const fetchURL = () => {
		if(fileLibrary.ExternalLink){
			showLinkPreview.value = true;
		}
	}

	watch(() => fileLibrary.ExternalLink, (newVal, oldVal) => {
		invalidExternalLink.value = false;
		if(!isMounted.value){
			 showLinkPreview.value = false;
		}else{
			isMounted.value = false;
		}
        
      }
    );


    return {
	  getData,
      save,
	  errorMessage,
	  fileLibrary,
	  isLoading,
	  isSaveButtonDisabled,
	  filesAdded,
	  fileRemoved,
      addedFiles,
	  usedIn,
	  generateQRCodeContentURL,
	  showLinkPreview,
	  fetchURL,
	  invalidExternalLink
	};
  },
};
</script>

<style lang="scss" scoped>
.file-library{
	.date-record{
		margin: 0;
    	margin-top: 4px;
	}
	.preview-image-show{
		min-height: 300px;
	}
	.preview{
		// position: relative;
		// img, embed{
		// 	position: absolute;
    	// 	right: 0;
		// 	border: 1px solid $mas-lightInputBorder;
		// 	border-radius: 5px;
		// 	padding: 1px;
		// 	width: 300px;
		// 	height: 300px;
		// }

		// video{
		// 	position: absolute;
    	// 	right: 0;
		// 	width: 300px;
		// 	height: 300px;
		// }
	}

	.file-upload-area{
		display: grid;
		row-gap: 1px;
		row-gap: 10px;

		span.refresh{
			font-size: 16px;
    		text-align: end;
			cursor: pointer;

			i{
				margin-left: 7px;
			}
		}
	}
}
</style>
