<template>
<vue3dLoader
    :height="500"
    :filePath="filePath"
  ></vue3dLoader>
</template>

<script>

 import { vue3dLoader } from "vue-3d-loader";
export default {
	props: ['filePath'],
    components: {
        vue3dLoader
    },
    setup(props) {
	}
}
</script>