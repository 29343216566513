export { createNav } from './config'
import { createRouter, createWebHistory } from "vue-router";
import * as collections from './modules/index'
import { standard, modules, createRoutes } from './config';
import Home from "@/views/Home";
import Login from "@/root/Login";
import Test3D from "@/views/3dTest/Index.vue";



import * as crud from "./modules/crud"

const {admin, maintenancePlan } = collections;
const routesList = [{
	name: "Home",
	path: "/",
	component: Home,
	nav: {
		name: 'general.menu.start',
		icon: "home",
	}
},

{
	name: "Login",
	path: "/login",
	component: Login,
	meta: {
		panelMode: true
	}
},
{
	path: "/test-3d",
	name: "test3d",
	component: Test3D,
},
maintenancePlan,
admin

];

const {article, asset, serviceDescription, fileLibrary, apiKey, user, articleCategory, supplier, stockPlace, troubleshootGuide} = crud;

const crudRoutes = [...article, ...asset, ...serviceDescription, ...fileLibrary, ...apiKey, ...user, ...articleCategory, ...supplier, ...stockPlace, ...troubleshootGuide] ;

export const routes = [...routesList, ...crudRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: createRoutes(routes, [...standard, ...modules])
});

export default router;
