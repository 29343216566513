<template>
	<div class="mas-status-bubble" :class="`mas-status-bubble--${color}`"></div>
</template>

<script>
export default {
	props: ['color']
}
</script>

<style lang="scss">
	.mas-status-bubble {
		width: 1em;
		height: 1em;
		border-radius: 100px;
		&--green {
			background-color: $mas-green;
			border: .2em solid lighten($mas-green, 8%)
		}
		&--orange {
			background-color: $mas-orange;
			border: .2em solid lighten($mas-orange, 8%)
		}
		&--red {
			background-color: $mas-red;
			border: .2em solid lighten($mas-red, 8%)
		}
	}
</style>