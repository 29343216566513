<template>
	<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
		<GlobSpinner :isLoading="isLoading"/>
		<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
			<p class="section-heading">{{ $t('general.general') }}</p>
			<GlobRow>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.supplier.fields.name')}}*
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.supplier.fields.name')"
						v-model="supplier.form.Name"
						/>
					</label>
				</GlobCol>
                <GlobCol :medium="9"></GlobCol>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.supplier.fields.address')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.supplier.fields.address')"
						v-model="supplier.form.Address"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.supplier.fields.postal')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.supplier.fields.postal')"
						v-model="supplier.form.Postal"
						/>
					</label>
				</GlobCol>
                <GlobCol :medium="3">
					<label>
						{{$t('administration.supplier.fields.city')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.supplier.fields.city')"
						v-model="supplier.form.City"
						/>
					</label>
				</GlobCol>
                <GlobCol :medium="3"></GlobCol>
                 <GlobCol :medium="3">
					<label>
						{{$t('administration.supplier.fields.country')}}
						<InputDynamic
						:fullWidth="true"
						:type="'virtualselect'"
                        :data="countries"
						:placeholder="$t('administration.supplier.fields.country')"
						v-model="supplier.form.CountryId"
						/>
					</label>
				</GlobCol>
			</GlobRow>
		</GlobXpanel>
        <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" class="mas-mt-4">
			<p class="section-heading">{{ $t('administration.supplier.contacts') }}</p>
			<GlobRow>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.supplier.fields.contact_person')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.supplier.fields.contact_person')"
						v-model="supplier.form.ContactName"
						/>
					</label>
				</GlobCol>
                <GlobCol :medium="3">
					<label>
						{{$t('administration.supplier.fields.phone')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.supplier.fields.phone')"
						v-model="supplier.form.ContactPhone"
						/>
					</label>
				</GlobCol>
                <GlobCol :medium="3">
					<label>
						{{$t('administration.supplier.fields.email')}}
						<InputDynamic
						:fullWidth="true"
						:type="'email'"
						:placeholder="$t('administration.supplier.fields.email')"
						v-model="supplier.form.ContactEmail"
						/>
					</label>
				</GlobCol>
                <GlobCol :medium="12">
					<p class="text-danger mas-mt-2" v-if="errorMessage">{{errorMessage}}</p>
					<button @click="save" class="button mas-mt-4" :disabled="isSaveButtonDisabled">{{$t('general.button.save')}}</button>
                    <button @click="$emit('showDeleteConfirmModal', true)"
						class="button button--red">{{$t('general.button.delete')}}</button>
				</GlobCol>
			</GlobRow>
		</GlobXpanel>
	</GlobXpanel>

</template>

<script>
import {onMounted, reactive, ref, computed, watchEffect } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import {useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { validateForm } from "@/views/crud/Supplier/common.js";

export default {
  props: ['itemId'],
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.suppliers);
	const api = useApi();
	const router = useRouter();

	const notifications = useNotifications();
	const errorMessage = ref(null);
    const countries = ref([]);
	const isLoading = ref(false);

	const supplier = reactive({
		form: {
			Name: "",
            Address: "",
            Postal: "",
            City: "",
            CountryId: "",
            ContactName: "",
            ContactPhone: "",
            ContactEmail: ""
		},
	});


	onMounted(()=> {
        getSupplier();
	})

    const getSupplier = () => {
        isLoading.value = true;
        endpoint.value.getById(props.itemId).then(function(res){
			supplier.form = res.supplier;
			countries.value = res.countries;
            isLoading.value = false;
        })
    }

    const save = () => {
		
		errorMessage.value = validateForm(supplier.form);

		if(errorMessage.value){
			return false;
		}

		isLoading.value = true;
		endpoint.value.update(props.itemId, supplier.form).then((res) => {
			isLoading.value = false;
			if(res.success){
				notifications.push(
					{
						msg: tm('administration')['supplier']['update_success']
					},
					"success",
					false
				);
				emit('updated', res.supplier)
			}else{
				for(let errorField in res.errors){
				 	let error = JSON.parse(res.errors[errorField][0]);
					errorMessage.value =  tm(error.error).replace('{attribute}', tm(error.attribute));
					break;
				}
			}

			
		});
    };

	const isSaveButtonDisabled = computed(() => {
		return !supplier.form.Name ? true : false
	})


    return {
      save,
	  errorMessage,
	  supplier,
	  isLoading,
	  isSaveButtonDisabled,
      countries,
      getSupplier
    };
  },
};
</script>

<style lang="scss">
</style>
