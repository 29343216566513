<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="12">
				<GlobXpanel :title="$t('administration.file_library.heading')">
					<button @click="showCreateModal = true" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">{{$t('general.create_new')}}</button>
					<InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
					<GlobTable
						:maxHeight="50"
						:data="{
							head : mainListHead,
							body : filteredItems
						}"
						:actions="actions"
						:itemRenderFunctions="renderFunctions"
					/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>

<!-- Create fileLibrary modal -->
<GlobModal :widthInPx="1000" :title="$t('administration.file_library.create')" @close="showCreateModal = false" :show="showCreateModal" :isCRUD="true" v-if="showCreateModal">
	<Create @created="(fileLibrary) => created(fileLibrary)" />
</GlobModal>
<!-- Edit fileLibrary modal -->
<GlobModal :widthInPx="1100" 
	:title="selectedItem.Name" 
	@close="selectedItem = null" 
	:show="selectedItem && selectedItem.Id && !hideEditModal" 
	v-if="selectedItem && selectedItem.Id" 
	:isCRUD="true" 
	:makeBackground="styleParentModal">
	<Edit 
		:itemId="selectedItem.Id" 
		:loading="itemModalLoading"
		:reloadData="reloadEdit"
		@updated="(fileLibrary) => updated(fileLibrary)"
		@showDeleteConfirmModal="showDeleteConfirmModal = true"
		@styleParentModal="(val) => styleParentModal = val"
			
		/>
</GlobModal>
	<!-- Delete confirm modal -->
	<GlobConsequence :show="showDeleteConfirmModal" 
		@close="showDeleteConfirmModal=false"  
		@confirm = "deleteItem()"
		:title="$t('administration.file_library.delete')" 
		:message="$t('administration.file_library.delete_confirm')"  />
</template>

<script>
import { onMounted, computed, watch, ref, watchEffect } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import Create from "@/views/crud/FileLibrary/Create.vue";
import Edit from "@/views/crud/FileLibrary/Edit.vue";

export default {
components: {
	Navbar,
	Create,
	Edit
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.fileLibrary);
    
	const selectedItem = ref(null);
	const styleParentModal = ref(false);

	const showCreateModal = ref(false);
	const showDeleteConfirmModal = ref(false)
	const reloadEdit = ref(false);
	const itemModalLoading = ref(false);
	const hideEditModal = computed(() =>{
		return showDeleteConfirmModal.value ? true : false;
	})


	const isLoading = ref(false);
	const notifications = useNotifications();
	const loaded = ref(false);
	const searchString = ref('');
	const mainList  = ref([]);
	const mainListHead = ref([
			{
				Alias: "Name",
				Value: computed(() => tm('administration')['file_library']['fields']['name'])
			},
			{
				Alias: "Type",
				Value: computed(() => tm('administration')['file_library']['fields']['type'])
			},
			{
				Alias: "Description",
				Value: computed(() => tm('administration')['file_library']['fields']['description'])
			},
			{
				Alias: "Uploaded",
				Value: computed(() => tm('administration')['file_library']['fields']['uploaded'])
			},
			{
				Alias: "Changed",
				Value: computed(() => tm('administration')['file_library']['fields']['changed'])
			},
			{
				Alias: "InUse",
				Value: computed(() => tm('administration')['file_library']['fields']['in_use'])
			},


		]);
	
	const actions = computed(() => { return [
		{
			name: tm('general')['button']['edit'],
			color: "green",
				action: (obj) => {
					selectedItem.value = obj;
				}
		}
		];
	});

	const renderFunctions = computed(()=> {
		return {
			InUse(obj){
				return obj.ServiceDescriptions.length
			},
		}
	})
	
	 onMounted(async() => {
          loadMainList();
	 });

	const loadMainList = async() => {
		isLoading.value = true;
		endpoint.value.get().then(function(res){
           mainList.value = res.data;
		   isLoading.value = false;
		})
	}

	const filteredItems = computed(() => {
		return searchString.value.length > 0 ? mainList.value
				.filter(
					i => (i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase()))
					||  (i.Description && i.Description.toLowerCase().match(searchString.value.toLowerCase()))
					) : mainList.value;
	})

	const dataObject = ref({});

	const created = (fileLibrary) => {
		showCreateModal.value = false;  
		selectedItem.value = fileLibrary;
		loadMainList();
	}

	const updated = (fileLibrary) => {
		let index = mainList.value.findIndex((i) => i.Id == fileLibrary.Id)
		mainList.value[index].Name = fileLibrary.Name;
		mainList.value[index].Description = fileLibrary.Description;
		mainList.value[index].Type = fileLibrary.Type;
		mainList.value[index].Uploaded = fileLibrary.Uploaded;
		selectedItem.value = null
	}

	const deleteItem = () => {
		showDeleteConfirmModal.value = false;
		itemModalLoading.value = !itemModalLoading.value;
		isLoading.value = true;
		endpoint.value.delete(selectedItem.value.Id).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['file_library']['delete_success']
					},
					"success",
					false
			);
			selectedItem.value = null;
			loadMainList();
		});
	}



	const getItem = (itemId) => {
		endpoint.value.getById(itemId).then(function(res){
			updated(res.data)
			isLoading.value = false;
		});
	}


    return {
		loadMainList,
		filteredItems,
		endpoint,
		mainList,
		mainListHead,
		searchString,
		actions,
		routes,
		loaded,
		dataObject,
		isLoading,
		selectedItem,
		hideEditModal,
		showCreateModal,
		created,
		reloadEdit,
		itemModalLoading,
		showDeleteConfirmModal,
		deleteItem,
		updated,
		renderFunctions,
		styleParentModal
    };
  }
};
</script>