
import ServiceDescription from "@/views/crud/ServiceDescription/ServiceDescriptions.vue";

export const serviceDescription = [{
  path: "/admin/view/service-descriptions",
  component: ServiceDescription,
  isCRUD: true,
  name: "ServiceDescription",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Service description" }]
  },
}
]