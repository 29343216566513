<template>
    <GlobXpanel :noShadow="true" :avoidHR="true" :isCRUD="true">
        <GlobSpinner :isLoading="isLoading" />
        <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
            <p class="section-heading">{{ $t('administration.troubleshoot_guide.problem') }}</p>
            <GlobRow>
                <GlobCol :medium="4">
                    <label>
                        {{ $t('administration.troubleshoot_guide.fields.problem_heading') }}*
                        <InputDynamic :fullWidth="true" :type="'text'"
                            :placeholder="$t('administration.troubleshoot_guide.fields.problem_heading')"
                            v-model="form.ProblemHeading" />
                    </label>
                </GlobCol>
                <GlobCol :medium="8">
                    <label>
                        {{ $t('administration.troubleshoot_guide.fields.problem_description') }}
                        <InputDynamic :fullWidth="true" :type="'textarea'" :rows="6"
                            :placeholder="$t('administration.troubleshoot_guide.fields.problem_heading')"
                            v-model="form.ProblemDescription" />
                    </label>
                </GlobCol>
                <GlobCol :medium="12">
                    <label>
                        {{ $t('administration.troubleshoot_guide.fields.assets') }}
                    </label>
                    <InputDynamic :fullWidth="true" :type="'multiselect'" v-model="form.Assets" :data="assets"
                        :selected="form.Assets" />
                </GlobCol>
                <GlobCol :medium="12">
                    <label>
                        {{ $t('administration.troubleshoot_guide.fields.attachments') }}
                    </label>
                    <GlobFileLibrary 
                            class="mas-mt-3"
                            @showFileLibraryModal="(val) => $emit('showFileLibraryModal', val)"
                            @inputChanged = "(file) => attachmentAddedOrRemoved(file)"
                            :addedFiles="addedFiles">
                        </GlobFileLibrary>
                        <template  v-if="attachments && attachments.length">
                            <div class="grid-container">
                                <div class="grid-x add-file" v-sortable="{ disabled: false, sorting: attachments, callback: sortingCallback }">
                                    <GlobCol class="files-section " :medium="3" v-for="attachment in attachments" :key="'library-file-' + attachment.Id">
                                        <div class='parent'>
                                            <p>{{ attachment.Name.length > 10 ? attachment.Name.slice(0, 10) + '...' : attachment.Name }}</p>
                                            <template v-if="(['jpeg', 'jpg', 'png', 'svg', 'webp']).includes(attachment.Type)">
                                                <div class="thumb" :style="`background-image: url('${getSavedFileURLFromPath(attachment.Path)}')`" 
                                                    @click="downloadFile(attachment.Path, attachment.Name + '.' + attachment.Type)"
                                                    >
                                                    <span :data-test="'background-image: url(' + getSavedFileURLFromPath(attachment.Path) + ')'" class="remove" @click.stop="removeFileFromGuide(attachment)">-</span>
                                                </div>	
                                            </template>
                                            <template v-else>
                                                <div class="thumb" :style="'background-image: url(' + bgThumb + ')'" 
                                                    @click="downloadFile(attachment.Path, attachment.Name + '.' + attachment.Type)"
                                                    >
                                                    <span class="remove" @click.stop="removeFileFromGuide(attachment)">-</span>
                                                </div>	
                                            </template>				
                                        </div>
                                    </GlobCol>
                                </div>
                            </div>
        				</template>
                </GlobCol>

                <GlobCol :medium="12">
                    <label>
                        {{ $t('administration.troubleshoot_guide.fields.tags') }}
                    </label>
                    <InputDynamic :fullWidth="true" :type="'multiselect'" v-model="form.Tags" :data="[]"
                        :selected="selectedTags" :searchAction="tagSearchAction" :enableSearchAndAdd="true" />
                </GlobCol>
            </GlobRow>
        </GlobXpanel>
        <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" class="mas-mt-4">
            <p class="section-heading">{{ $t('administration.troubleshoot_guide.solution') }}</p>
            <GlobRow>
                <GlobCol :medium="4">
                    <label>
                        {{ $t('administration.troubleshoot_guide.fields.solution_heading') }}*
                        <InputDynamic :fullWidth="true" :type="'text'"
                            :placeholder="$t('administration.troubleshoot_guide.fields.solution_heading')"
                            v-model="form.SolutionHeading" />
                    </label>
                </GlobCol>
                <GlobCol :medium="8">
                    <label>
                        {{ $t('administration.troubleshoot_guide.fields.solution_description') }}
                        <InputDynamic :fullWidth="true" :type="'textarea'" :rows="6"
                            :placeholder="$t('administration.troubleshoot_guide.fields.solution_description')"
                            v-model="form.SolutionDescription" />
                    </label>
                </GlobCol>
                <GlobCol :medium="12">
                    <p class="text-danger mas-mt-2" v-if="errorMessage">{{ errorMessage }}</p>
                    <button @click="save" class="button mas-mt-4" :disabled="isSaveButtonDisabled">{{
                        $t('general.button.save') }}</button>

                    <button  @click="$emit('showDeleteConfirmModal', true)" class="button button--red mas-mt-4">{{
                            $t('general.button.delete') }}</button>
                </GlobCol>
            </GlobRow>
        </GlobXpanel>
    </GlobXpanel>
</template>

<script>
import { onMounted, reactive, ref, computed } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { validateForm } from "@/views/crud/TroubleshootGuide/common.js";
import { getSavedFileURLFromPath } from '@/helpers.js';
import bgThumb from '@/assets/icon/image-thumbnail.png';
import _ from 'lodash';

export default {
    props: ['itemId'],
    setup(props, { emit }) {
        const { tm } = useI18n();
        const api = useApi();
        const endpoint = api.troubleshootGuides;
        const router = useRouter();

        const notifications = useNotifications();
        const errorMessage = ref(null);
        const tags = ref([]);
        const assets = ref([]);
        const isLoading = ref(false);

        let form = reactive({
            ProblemHeading: "",
            ProblemDescription: "",
            SolutionHeading: "",
            SolutionDescription: "",
            Tags: [],
            Assets: []
        });

        const selectedTags = ref([]);
        const selectedAssets = ref([]);

        const attachments = ref([]);


        onMounted(() => {
            isLoading.value = true;
            endpoint.getById(props.itemId, true).then(function (res) {
                assets.value = res.assets;
                tags.value = res.tags;

                form.ProblemHeading = res.data.ProblemHeading;
                form.ProblemDescription = res.data.ProblemDescription;
                form.SolutionHeading = res.data.SolutionHeading;
                form.SolutionDescription = res.data.SolutionDescription;

                //Assets
                form.Assets = res.data.assets.map((item) => item.Id);
                form.Tags = res.data.tags.map((item) => item.Id);
                selectedTags.value = res.data.tags.map((item) => {
                    return {
                        name: item.Name,
                        value: item.Id,
                    }
                });

                //Attachments
                attachments.value =  _.cloneDeep(res.data.attachments);

                isLoading.value = false;
            })
        })

        const tagSearchAction = (searchKey, selectedOptionsIds) => {
            return endpoint.searchTags({
                'searchKey': searchKey,
                'selectedOptions': selectedOptionsIds
            }).then((res) => {
                return res.tags;
            })
        }

        const save = () => {
            errorMessage.value = validateForm(form);

            if (errorMessage.value) {
                return false;
            }

            let formData = new FormData();

            Object.keys(form).forEach((key) => {
                if (key != 'Tags' && key != 'Assets') {
                    formData.append(key, form[key] ? form[key] : '')
                }

            })

            Object.keys(attachments.value).forEach(function (key) {

                //Suppose if it is an existing filr
                let id = null;
                if (attachments.value[key]['Id']) {
                    id = attachments.value[key]['Id'];
                } else if (attachments.value[key]['id']) {
                    id = attachments.value[key]['id'];
                }
                if (id)
                {
                    formData.append('CurrentAttachments[' + key + ']', id)
                } else {
                    formData.append('Attachments[' + key + ']', attachments.value[key])
                }
                
            })

            Object.keys(form.Tags).forEach(function (key) {
                if (typeof form.Tags[key] == 'number') {
                    formData.append('Tags[' + key + ']', form.Tags[key])
                } else {
                    formData.append('NewTags[' + key + ']', form.Tags[key])
                }
            })

            Object.keys(form.Assets).forEach(function (key) {
                formData.append('Assets[' + key + ']', form.Assets[key])
            })


            isLoading.value = true;
            endpoint.update(props.itemId, formData).then((res) => {
                isLoading.value = false;
                if (res.success) {
                    notifications.push(
                        {
                            msg: tm('administration')['troubleshoot_guide']['update_success']
                        },
                        "success",
                        false
                    );
                    emit('updated')
                } else {
                    for (let errorField in res.errors) {
                        let error = JSON.parse(res.errors[errorField][0]);
                        errorMessage.value = tm(error.error).replace('{attribute}', tm(error.attribute));
                        break;
                    }
                }


            });
        };

        const isSaveButtonDisabled = computed(() => {
            return !form.ProblemHeading || !form.SolutionHeading ? true : false
        })

        const attachmentAddedOrRemoved = (item) => {
            if (item.value) {
                addFileToGuide(item.item);
            } else {
                removeFileFromGuide(item.item);
            }
        }

        const addFileToGuide = (file) => {
            isLoading.value = true;
            endpoint.addorRemoveFile(props.itemId, {
                FileLibraryId: file.Id,
                IsAdd: true
            }).then((res) => {
                attachments.value = res.data.attachments;
                notifications.push(
                    {
                        msg: tm('administration')['troubleshoot_guide']['file_added_success']
                    },
                    "success",
                    false
                );
                isLoading.value = false;
            });
        }

        const removeFileFromGuide = (file) => {
            isLoading.value = true;
            endpoint.addorRemoveFile(props.itemId, {
                FileLibraryId: file.Id,
                IsAdd: false
            }).then((res) => {
                attachments.value = res.data.attachments;
                notifications.push(
                    {
                        msg: tm('administration')['troubleshoot_guide']['file_removed_success']
                    },
                    "success",
                    false
                );
                isLoading.value = false;
            });
        }

        const addedFiles = computed(() => {
            return attachments.value.map(file => parseInt(file.Id))
        })

        const downloadFile = (path, filename) => {
            const url = getSavedFileURLFromPath(path);
            const link = document.createElement("a");
            link.target = '_blank';
            link.href = url;
            link.download = filename;
            link.click();
        }


        return {
            form,
            save,
            errorMessage,
            isLoading,
            isSaveButtonDisabled,
            tags,
            tagSearchAction,
            selectedTags,
            selectedAssets,
            assets,
            addedFiles,
            attachmentAddedOrRemoved,
            downloadFile,
            attachments,
            getSavedFileURLFromPath,
            removeFileFromGuide,
            bgThumb
        };
    },
};
</script>
