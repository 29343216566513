import { dynamicTranslation } from '@/helpers.js';
import { i18n } from '@/plugins/i18n/lang.js'
import moment from 'moment';

export const validateForm = (form) =>{
   let error = null;
   let translations = i18n.global.messages.value[i18n.global.locale.value];

   if(form.Name.length > 50){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['asset']['fields']['name'],
         value: 50
      })
      return error;
   }

   if(form.Description && form.Description.length > 100){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['asset']['fields']['description'],
         value: 100
      })
      return error;
   }

   if(form.Manufacture && form.Manufacture.length > 50){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['asset']['fields']['manufacture'],
         value: 50
      })
      return error;
   }

   if(form.Model && form.Model.length > 50){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['asset']['fields']['model'],
         value: 50
      })
      return error;
   }

   if(form.SerialNumber && form.SerialNumber.length > 50){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['asset']['fields']['serial_number'],
         value: 50
      })
      return error;
   }

   return error;
}

export const validateMaintenancePlanForm = (form) =>{
   let error = null;
   let translations = i18n.global.messages.value[i18n.global.locale.value];

   if(form.Name.length > 150){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['asset']['plan']['fields']['name'],
         value: 150
      })
      return error;
   }

   if(form.Frequency <= 0){
      error = translations['administration']['asset']['plan']['invalid_frequency']
      return error;
   }

   if(moment(form.StartDate).diff(moment(form.StopDate), 'days') > 1){
      error = translations['general']['errors']['invalid_start_and_stop_date']
      return error;
   }

   if(!moment(form.FirstServiceDate).isBetween(moment(form.StartDate), moment(form.StopDate), null, '[]')){
      error = translations['administration']['asset']['plan']['invalid_first_service_date']
      return error;
   }

   if(form.ReminderDays <= 0){
      error = translations['administration']['asset']['plan']['invalid_reminder_days']
      return error;
   }

   return error;
}

export const validateSurveillanceForm = (form) => {
   
   let error = null;
   let translations = i18n.global.messages.value[i18n.global.locale.value];

   if(form.Topic && form.Topic.length > 200){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['asset']['surveillance']['fields']['topic'],
         value: 200
      })
      return error;
   }
}

export const validateSurveillanceServerForm = (form) => {
   
   let error = null;
   let translations = i18n.global.messages.value[i18n.global.locale.value];

   if(form.Alias && form.Alias.length > 50){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['asset']['surveillance']['fields']['alias'],
         value: 50
      })
      return error;
   }

   if(form.Server.length > 100){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['asset']['surveillance']['fields']['server'],
         value: 100
      })
      return error;
   }


   if(form.ServerUser && form.ServerUser.length > 100){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['asset']['surveillance']['fields']['username'],
         value: 100
      })
      return error;
   }

   if(form.ServerPassword && form.ServerPassword.length > 100){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['asset']['surveillance']['fields']['password'],
         value: 100
      })
      return error;
   }

}

export const matchingFields = [
   {
      name: 'Id',
      value: 'Id'
   },
   {
      name: 'Name',
      value: 'Name'
   },
   {
      name: 'SerialNumber',
      value: 'SerialNumber'
   }
];