<template>
	<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true" class="file-library">
		<GlobSpinner :isLoading="isLoading"/>
		<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
			<GlobRow>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.file_library.fields.name')}}*
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.file_library.fields.name')"
						v-model="fileLibrary.Name"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="6">
					<label>
						{{$t('administration.file_library.fields.description')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.file_library.fields.description')"
						v-model="fileLibrary.Description"
						/>
					</label>
				</GlobCol>
			</GlobRow>
			<GlobRow>
			    <GlobCol :medium="3" v-if="fileLibrary.Type">
                    <span class="uploaded-file-type">{{fileLibrary.Type}}</span>
                </GlobCol>
				 <GlobCol :medium="6" v-if="showPreview">
					 <GlobFilePreview 
					 	@invalidFile="invalidExternalLink = true;"
					 	:width="'150px'"
						:height="'150px'"
					 	:file ="{
							 URL: fileLibrary.ExternalLink
					 	}"/>
				 </GlobCol>
			</GlobRow>
			<GlobRow>
				 <GlobCol :medium="12" class="mas-mt-5">
						<label>
						{{$t('administration.file_library.fields.web_link')}}
						<InputDynamic
							:fullWidth="true"
							:type="'text'"
							:placeholder="$t('administration.file_library.fields.web_link')"
							v-model="fileLibrary.ExternalLink"
							:inputIcon="'fas fa-sync'"
							@inputIconClicked="fetchURL()"
							:disabled="fileLibrary.File"
						 />
						 <p class="text-danger" v-if="invalidExternalLink">{{$t('administration.file_library.invalid_link')}}</p>
					</label>
				 </GlobCol>
                 <GlobCol :medium="12" :class="fileLibrary.ExternalLink ? 'disable-dropzone' : ''">
                    <GlobFileUpload 
						@filesAdded="(files) => filesAdded(files)" 
						@filesRemoved="fileRemoved()"/>
                 </GlobCol>
			</GlobRow>

			<p class="text-danger mas-mt-2" v-if="errorMessage">{{errorMessage}}</p>
			<button @click="save" class="button mas-mt-4" :disabled="isSaveButtonDisabled">{{$t('general.button.save')}}</button>
		</GlobXpanel>
	</GlobXpanel>

</template>

<script>
import {onMounted, reactive, ref, computed, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import {useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { validateForm } from "@/views/crud/FileLibrary/common.js";
import { dynamicTranslation } from '@/helpers.js';

export default {
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.fileLibrary);
	const api = useApi();
	const router = useRouter();

	const showPreview = ref(false);
	const invalidExternalLink = ref(false);
	const notifications = useNotifications();
	const errorMessage = ref(null);
	const isLoading = ref(false);

	const fileLibrary = reactive({
			Name: "",
			Description: "",
            Type: "",
            File: null,
			ExternalLink: ''
	});

	const filesAdded = (files) => {
 		fileLibrary.File = files[0];
		fileLibrary.Type = files[0].name.split('.').pop();
	}

	const fileRemoved = () => {
		fileLibrary.File = null;
		fileLibrary.Type = "";
	}

    const save = () => {	
		errorMessage.value = validateForm(fileLibrary);

		if(errorMessage.value){
			return false;
		}

		isLoading.value = true;

		let formData = new FormData();

		Object.keys(fileLibrary).forEach((key) => {
			formData.append(key, fileLibrary[key] ? fileLibrary[key] : '')
		})

		endpoint.value.create(formData).then((res) => {
			if(res.success){
				notifications.push(
					{
						msg: tm('administration')['file_library']['create_success']
					},
					"success",
						false
					);
				emit('created', res.file_library)
			}else{
				for(let errorField in res.errors){
				 	let error = JSON.parse(res.errors[errorField][0]);
					errorMessage.value =  tm(error.error).replace('{attribute}', tm(error.attribute));
					break;
				}
			}


			isLoading.value = false;
		});
    };

	const isSaveButtonDisabled = computed(() => {
		if(!fileLibrary.Name){
			return true;
		}

		if(fileLibrary.ExternalLink && invalidExternalLink.value){
			return true;
		}
		return false;
	})

	const fetchURL = () => {
		if(fileLibrary.ExternalLink){
			showPreview.value = true;
		}
	}

	watch(() => fileLibrary.ExternalLink, (newVal, oldVal) => {
         showPreview.value = false;
		 invalidExternalLink.value = false;
      }
    );


    return {
	  fetchURL,
      save,
	  errorMessage,
	  fileLibrary,
	  isLoading,
	  isSaveButtonDisabled,
	  filesAdded,
	  fileRemoved,
	  showPreview,
	  invalidExternalLink
    };
  },
};
</script>

<style lang="scss">
.file-library{
	.uploaded-file-type{
        font-size: 16px;
        border: 1px solid;
        display: block;
        width: 5rem;
        height: 40px;
        text-align: center;
        padding-top: 5px;
        background-color: $mas-lightGray;
		border-radius: 6px;
		border: 1px solid #c9c9c9;
    }

	.disable-dropzone{
		opacity: .3;
    	pointer-events: none;
	}

}
</style>
