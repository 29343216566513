<template>
	<GlobXpanel :noShadow="true" :avoidHR="true" :isCRUD="true">
		<GlobSpinner :isLoading="isLoading" />
		<div>
			<p class="section-heading">{{ $t('general.general') }}</p>
			<GlobRow>
				<GlobCol :medium="3">
					<label>
						{{ $t('administration.article.fields.article_number') }}*
						<InputDynamic :fullWidth="true" styling="margin-bottom: .5rem;" :type="'text'"
							:placeholder="$t('administration.article.fields.article_number')"
							v-model="article.form.ArticleNumber" :disabled="true" />
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<label>
						{{ $t('administration.article.fields.name') }}*
						<InputDynamic :fullWidth="true" styling="margin-bottom: .5rem;" :type="'text'"
							:placeholder="$t('administration.article.fields.name')" v-model="article.form.Name" />
					</label>
				</GlobCol>
				<GlobCol :medium="6">
					<label>
						{{ $t('administration.article.fields.description') }}
						<InputDynamic :fullWidth="true" styling="margin-bottom: .5rem;" :type="'text'"
							:placeholder="$t('administration.article.fields.description')"
							v-model="article.form.Description" />
					</label>
				</GlobCol>

				<GlobCol :medium="6" v-if="articleCategories.value && articleCategories.value.length">
					<label>
						{{ $t('administration.article.fields.article_category') }}
						<InputDynamic :fullWidth="true" styling="margin-bottom: .5rem;" :type="'multiselect'"
							:placeholder="$t('administration.article.fields.article_category')"
							v-model="article.form.ArticleCategory" :data="articleCategories.value"
							:selected="article.form.ArticleCategory" />
					</label>
				</GlobCol>
				<GlobCol :medium="3" v-if="stockPlaces.value && stockPlaces.value.length">
					<label>
						{{ $t('administration.article.fields.default_stock_place') }}
						<InputDynamic :fullWidth="true" styling="margin-bottom: .5rem;" :type="'virtualselect'"
							:data="stockPlaces.value" :placeholder="$t('administration.article.fields.default_stock_place')"
							v-model="article.form.DefaultStockPlaceId" />
					</label>
				</GlobCol>
			</GlobRow>
			<GlobRow>
				<GlobCol :medium="6"></GlobCol>
				<GlobCol :medium="6" class="mas-mt-5">
					<GlobQrCode :name="article.form.Name" :content="qrCodeContent" :downloadFileName="'Article-' + article.form.Name" @enlargeQRCodeActive="(val) => enlargeQRCodeActive(val)"/>
				</GlobCol>
			</GlobRow>
			<button @click="save" class="button mas-mt-4"
				:disabled="!article.form.Name">{{ $t('general.button.save') }}</button>
			<button @click="$emit('showArticleDeleteConfirmModal', true)"
				class="button button--red">{{ $t('general.button.delete') }}</button>
		</div>
		<div class="mas-mt-3">
			<p class="section-heading">{{ $t('administration.article.suppliers') }}</p>
			<div class="crud-expand">
				<p>{{ $t('administration.article.add_supplier') }}</p>
				<span><i class="fas fa-plus-circle"
						@click="showArticleSupplierFields = !showArticleSupplierFields;"></i></span>
			</div>
			<template v-if="showArticleSupplierFields">
				<GlobRow>
					<GlobCol :medium="4">
						<label>
							{{ $t('administration.article.fields.supplier') }}*
							<InputDynamic :fullWidth="true" :type="'virtualselect'"
								:placeholder="$t('administration.article.fields.supplier')"
								v-model="articleSupplier.SupplierId" :data="suppliers" />
						</label>
					</GlobCol>
					<GlobCol :medium="4">
						<label>
							{{ $t('administration.article.fields.suplier_article_number') }}
							<InputDynamic :fullWidth="true" :type="'text'"
								:placeholder="$t('administration.article.fields.suplier_article_number')"
								v-model="articleSupplier.SupplierArticleNumber" :data="suppliers" />
						</label>
					</GlobCol>
					<GlobCol :medium="4">
						<label>
							{{ $t('administration.article.fields.lead_time_delivery') }}
							<InputDynamic :fullWidth="true" :type="'number'"
								:placeholder="$t('administration.article.fields.lead_time_delivery')"
								v-model="articleSupplier.LeadTimeDelivery" :data="suppliers" />
						</label>
					</GlobCol>
					<GlobCol :medium="4">
						<label>
							{{ $t('administration.article.fields.price') }}
							<InputDynamic :fullWidth="true" :type="'number'"
								:placeholder="$t('administration.article.fields.price')" v-model="articleSupplier.Price"
								:data="suppliers" />
						</label>
					</GlobCol>
					<GlobCol :medium="4">
						<label>
							{{ $t('administration.article.fields.currency') }}
							<InputDynamic :fullWidth="true" :type="'virtualselect'"
								:placeholder="$t('administration.article.fields.currency')"
								v-model="articleSupplier.CurrencyId" :data="currencies" />
						</label>
					</GlobCol>
				</GlobRow>
				<button @click="saveArticleSuppplier()" class="button mas-mt-4"
					:disabled="!articleSupplier.SupplierId">{{ $t('general.button.save') }}</button>
				<button class="button button--orange"
					@click="clearArticleSupplierObject()">{{ $t('general.button.clear') }}</button>
			</template>

			<GlobTable :maxHeight="50" :itemRenderFunctions="renderFunctions" :data="{
				head: articleSupplierListHead,
				body: articleSupplierListBody
			}" :actions="actions" />
		</div>
		<div v-if="errorMessage">
			<p class="text-danger mt-2">{{ errorMessage }}</p>
		</div>
	</GlobXpanel>
</template>

<script>
import { onMounted, reactive, ref, computed, watchEffect, watch, toRaw } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import { useRoute, useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { generateQRCodeContentURL } from '@/helpers.js';

export default {
	props: ["manager", "router", "articleId", "reloadData", "loading"],
	setup(props, { emit }) {
		const { tm } = useI18n();
		const endpoint = computed(() => api.articles);
		const api = useApi();
		const router = useRouter();
		const route = useRoute();

		const notifications = useNotifications();
		const reloadDataLocal = ref(props.reloadData);

		watch(() => props.reloadData, (selection, prevSelection) => {
			getArticle();
		})

		watch(() => props.loading, (selection, prevSelection) => {
			isLoading.value = true;
		})

		const isLoading = ref(true);
		const errorMessage = ref(null);

		const article = reactive({
			form: {
				Id: "",
				Name: "",
				Description: "",
				DefaultStockPlaceId: "",
				ArticleNumber: "",
				ArticleCategory: [],
			},
		});

		const articleCategories = reactive([]);
		const stockPlaces = reactive([]);
		const currencies = ref([]);
		const suppliers = ref([]);
		const showArticleSupplierFields = ref(false);
		const articleSupplier = reactive({
			SupplierId: '',
			SupplierArticleNumber: ''
		});

		onMounted(async () => {
			getArticle();
		});

		const getArticle = (updateMainList = false) => {
			isLoading.value = true;
			endpoint.value.getById(props.articleId, true).then(function (res) {
				if (!res.data) {
					notifications.push(
						{
							msg: tm('general')['something_went_wrong']
						},
						"warning",
						false
					);
					router.push(`/admin/view/articles`);
				}
				article.form.Id = res.data.Id;
				article.form.Name = res.data.Name;
				article.form.ArticleNumber = res.data.ArticleNumber;
				article.form.Description = res.data.Description;
				article.form.DefaultStockPlaceId = res.data.DefaultStockPlaceId;
				article.form.ArticleCategory = res.data.ArticleCategoryRelations.map((item) => {
					return item.ArticleCategoryId;
				});
				articleCategories.value = res.categories.map((item) => {
					return {
						'name': item.Name,
						'value': item.Id
					}
				});

				stockPlaces.value = res.stock_places.map((item) => {
					return {
						'name': item.Name,
						'value': item.Id
					}
				})

				currencies.value = res.currencies;
				suppliers.value = res.suppliers.map((item) => {
					return {
						'name': item.Name,
						'value': item.Id
					}
				});

				articleSupplierListBody.value = res.data.ArticleSuppliers;


				isLoading.value = false;

				if (updateMainList) {
					emit('articleUpdated', res.data);
				}
			});
		};


		const formData = new FormData();



		const data = ref(article.form);

		const save = () => {
			errorMessage.value = null;
			isLoading.value = true;
			endpoint.value.update(data.value.Id, data.value).then((res) => {
				//Check whether any error exists when create item
				if (res && res.success == 0 && res.errors) {
					for (const item in res.errors) {
						errorMessage.value = tm(res.errors[item][0]);
						break;
					}
					return false;
				}
				notifications.push(
					{
						msg: tm('administration')['article']['update_success']
					},
					"success",
					false
				);
				isLoading.value = false;
				getArticle(true)
			});
		};

		const saveArticleSuppplier = () => {
			isLoading.value = true;
			endpoint.value.addOrUpdateArticleSupplier(data.value.Id, articleSupplier).then((res) => {

				notifications.push(
					{
						msg: tm('administration')['article']['article_supplier_save_success']
					},
					"success",
					false
				);
				clearArticleSupplierObject();
				isLoading.value = false;
				getArticle();
			});
		}
		const clearArticleSupplierObject = () => {
			articleSupplier.Id = '';
			articleSupplier.SupplierId = '';
			articleSupplier.SupplierArticleNumber = '';
		}

		const articleSupplierListBody = ref([]);
		const articleSupplierListHead = ref([
			{
				Alias: "SupplierName",
				Value: computed(() => tm('administration')['article']['fields']['supplier'])
			},
			{
				Alias: "SupplierArticleNumber",
				Value: computed(() => tm('administration')['article']['fields']['suplier_article_number'])
			},
			{
				Alias: "LeadTimeDelivery",
				Value: computed(() => tm('administration')['article']['fields']['lead_time_delivery'])
			},
			{
				Alias: "Price",
				Value: computed(() => tm('administration')['article']['fields']['price'])
			},

		]);

		const renderFunctions = computed(() => {
			return {
				SupplierName(obj) {
					return obj.supplier.Name;
				},
				LeadTimeDelivery(obj) {
					if (obj.LeadTimeDelivery) {
						return obj.LeadTimeDelivery + ' days';
					}
				},
				Price(obj) {
					if (obj.Price) {
						let price = obj.Price;
						if (obj.currency) {
							price = price + ' ' + obj.currency.Code;
						}
						return price;
					}
				},
			}
		})

		const actions = computed(() => {
			return [
				{
					name: tm('general')['button']['edit'],
					color: "green",
					action: (obj) => {
						showArticleSupplierFields.value = false;
						articleSupplier.Id = obj.Id;
						articleSupplier.SupplierId = obj.SupplierId;
						articleSupplier.SupplierArticleNumber = obj.SupplierArticleNumber;
						articleSupplier.LeadTimeDelivery = obj.LeadTimeDelivery;
						articleSupplier.Price = obj.Price;
						articleSupplier.CurrencyId = obj.CurrencyId;
						showArticleSupplierFields.value = true;
						// setTimeout(() => {
						// 	showArticleSupplierFields.value = true;
						// }, 5000)
					}
				},
				{
					name: tm('general')['button']['delete'],
					color: "red",
					action: (obj) => {
						isLoading.value = true;
						endpoint.value.deleteArticleSupplier(obj.ArticleId, obj.Id).then((res) => {
							notifications.push(
								{
									msg: tm('administration')['article']['article_supplier_delete_success']
								},
								"success",
								false
							);
							getArticle()
						});
					}
				},
			];
		});

		const qrCodeContent = computed(() => {
			if (article.form.Id) {
				return generateQRCodeContentURL('article', article.form.Id)
			} else {
				return '';
			}
		})

		const enlargeQRCodeActive = (val) => {
			emit('enlargeQRCodeActive', val)
		}

		return {
			isLoading,
			save,
			errorMessage,
			article,
			reloadDataLocal,
			articleCategories,
			stockPlaces,
			suppliers,
			showArticleSupplierFields,
			articleSupplier,
			saveArticleSuppplier,
			clearArticleSupplierObject,
			...props,
			articleSupplierListHead,
			articleSupplierListBody,
			renderFunctions,
			actions,
			currencies,
			qrCodeContent,
			enlargeQRCodeActive
		};
	},
};
</script>

<style lang="scss">
.mas-edit-article {
	&__image {
		width: 200px;
		display: block;
		margin-bottom: 0.5rem;
	}
}

.mas-article-lockers {
	display: flex;
	margin: 0.5rem 1rem 2rem 1rem;

	>div {
		margin-right: 2rem;
	}

	p {
		font-size: 0.8rem;
		text-decoration: uppercase;
	}

	&__list {
		display: flex;

		&--small {
			font-size: .6rem;
		}
	}

	span {
		padding: 0.35rem 1rem;
		border-radius: 4px;
		@include elevation(1);
		margin-right: 1.5rem;
		font-size: 0.8rem;
		align-items: center;
		display: flex;

		a {
			display: flex;
			font-size: 0.8rem;
			align-items: center;
		}

		i {
			color: $mas-steelGray;
			margin-right: 0.8rem;
		}
	}
}

.crud-expand {
	display: flex;
	margin-bottom: 1rem;
	margin-top: 1rem;

	p {
		margin: 0;
		font-size: 16px !important;
		color: $ma-bluish !important;
	}

	span {
		margin-left: 3px;
		cursor: pointer;

		i {
			color: $ma-greenishGray100;
			font-size: 24px;
		}
	}
}
</style>
