<template>
    <GlobSpinner :isLoading="isLoading" />
    <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" :isCRUD="true">
       <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
            <GlobRow>
                <GlobCol :medium="4">
                        <label>
                            {{$t('maintenance_plan.single_planing.fields.asset')}}*
                            <InputDynamic
                            :fullWidth="true"
                            :type="'virtualselect'"
                            :placeholder="$t('maintenance_plan.single_planing.fields.asset')"
                            v-model="form.AssetId"
                            :data="assets"
                            />
                        </label>
                </GlobCol>
            </GlobRow>
            <GlobRow>
                <GlobCol :medium="4">
                        <label>
                            {{$t('maintenance_plan.single_planing.fields.name')}}
                            <InputDynamic
                            :fullWidth="true"
                            :type="'text'"
                            :placeholder="$t('maintenance_plan.single_planing.fields.name')"
                            v-model="form.Name"
                            />
                        </label>
                </GlobCol>
            </GlobRow>
            <GlobRow>
                <GlobCol :medium="4">
                        <label>
                            {{$t('maintenance_plan.single_planing.fields.execution_date')}}*
                            <InputDynamic
                            :fullWidth="true"
                            :type="'date'"
                            :placeholder="$t('maintenance_plan.single_planing.fields.execution_date')"
                            v-model="form.MaintenanceDate"
                            />
                        </label>
                </GlobCol>
                <GlobCol :medium="4">
                        <label>
                            {{$t('maintenance_plan.single_planing.fields.service_description')}}
                            <InputDynamic
                            :fullWidth="true"
                            :type="'virtualselect'"
                            :placeholder="$t('maintenance_plan.single_planing.fields.service_description')"
                            v-model="form.ServiceDescriptionId"
                            :data="serviceDescriptions"
                            />
                        </label>
                </GlobCol>
            </GlobRow>
            <GlobRow>
                <GlobCol :medium="4">
                    <label>
                        {{$t('maintenance_plan.single_planing.fields.supplier')}}
                        <InputDynamic
                        :fullWidth="true"
                        :type="'multiselect'"
                        :placeholder="$t('maintenance_plan.single_planing.fields.supplier')"
                        v-model="form.Suppliers"
                        :data="supplierList"
                        :selected="form.Suppliers"
                        />
                    </label>
                </GlobCol>
                <GlobCol :medium="4">
                    <label>
                        {{$t('maintenance_plan.single_planing.fields.supplier_booked')}}
                        <InputDynamic
                        :fullWidth="true"
                        :type="'checkbox'"
                        v-model="form.SupplierBookingConfirmed"
                        />
                    </label>
                </GlobCol>
                <GlobCol :medium="4">
                    <label>
                        {{$t('maintenance_plan.single_planing.fields.booked_date')}}
                        <InputDynamic
                        :fullWidth="true"
                        :type="'date'"
                        :placeholder="$t('maintenance_plan.single_planing.fields.booked_date')"
                        v-model="form.SupplierConfirmedDate"
                        />
                    </label>
                </GlobCol>
            </GlobRow>
            <GlobRow>
                <GlobCol :medium="12">
                    <label>
                            {{$t('maintenance_plan.single_planing.fields.description')}}
                            <InputDynamic
                            :fullWidth="true"
                            :type="'textarea'"
                            :rows="6"
                            :placeholder="$t('maintenance_plan.single_planing.fields.description')"
                            v-model="form.Description"
                            />
                        </label>
                </GlobCol>
            </GlobRow>
             <GlobCol :medium="12">
                    <p class="text-danger" v-if="errorMessage">{{errorMessage}}</p>
                    <button type="button" class="button mas-mt-5" :disabled="isSaveButtonDisabled" @click="save()">{{$t('general.button.save')}}</button>
            </GlobCol>
        </GlobXpanel>
    </GlobXpanel>
</template>
<script>
import { onMounted, ref, reactive, computed, watch} from "vue";
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";

export default {
components: {},
setup(props, {emit}) {
        const { tm } = useI18n();
        const api = useApi();
        const notifications = useNotifications();
        const isLoading = ref(false);
        const endpoint = computed(() => api.maintenancePlans);

        const assets = ref([]);
        const serviceDescriptions = ref([]);
        const supplierList = ref([]);
        const errorMessage = ref('');
        const form = ref({
            SupplierBookingConfirmed: false
        });

        onMounted(() => {
            isLoading.value = true;
            endpoint.value.getSinglePlaningDependencies().then((res) => {
                assets.value = res.assets;
                supplierList.value = res.suppliers;
                isLoading.value = false;
            })

        })

        watch(() => form.value.AssetId, () => {
			if(form.value.AssetId && typeof form.value.AssetId != 'object'){
				isLoading.value = true;
				api.serviceDescriptions.get({'AssetId': form.value.AssetId}).then((res) => {

					serviceDescriptions.value = res.data.map((item) => {
						return {
							name: item.Name,
							value: item.Id
						}
					})
					isLoading.value = false;
				})
			}else{
				serviceDescriptions.value = [];
			}
	    });

        const isSaveButtonDisabled = computed(() => !form.value.AssetId || !form.value.MaintenanceDate ? true : false)

        const save = () => {
            isLoading.value = true;
            endpoint.value.saveSinglePlaning(form.value).then((res) => {
                if(res.success){
                    notifications.push(
                        {
                            msg: tm('maintenance_plan')['single_planing']['create_success']
                        },
                        "success",
                        false
                    );
                    emit('singlPlanCreated')
                }else{
                    for(let errorField in res.errors){
                        let error = JSON.parse(res.errors[errorField][0]);
                        errorMessage.value =  tm(error.error).replace('{attribute}', tm(error.attribute)).replace('{value}', error.value);
                        break;
				    }
                }
                isLoading.value = false;

            });
        }

        return {
            isLoading,
            form,
            assets,
            serviceDescriptions,
            supplierList,
            isSaveButtonDisabled,
            save,
            errorMessage
        }
    }
}
</script>

<style lang="scss">
</style>