<template>
  <div style="position: relative;">
    <transition-group name="fade" mode="out-in">
      <div style="position: absolute;" :key="'loader'" class="lazy-loader" v-if="items.body === null">
        <div class="lazy-loader__head" :style="`height: 32px`"></div>
        <ul class="lazy-loader__body">
          <li
            :style="`height: 32px`"
            v-for="n in maxHeight ? Math.floor(maxHeightPixels / 32) : 10"
            :key="'lazy-' + n"
          ></li>
        </ul>
      </div>
      <div
        class="ips-table"
        :key="'table'"
        v-if="items.body !== null"
        :style="maxHeight ? `max-height: ${maxHeight}vh;` : ''"
      >
        <table class="table table-striped jambo_table bulk_action">
          <thead>
            <tr class="ips-table__head">
              <th v-if="bulkactions" style="position: sticky; left: -1px; ">
                <InputCheckbox
                  :name="'checkboxHeader'"
                  :value="selected.length == items.body.length"
                  @input="(e) => checkAll(e.val)"
                />
              </th>
              <th
                class="column-title"
                :class="sortingBy == item.Alias ? getSortingArrow() : ''"
                @click="sort(item.Alias)"
                v-for="item in items.head"
                :key="`table-header-${item.Alias}`"
              >
                {{ item.Value }}
              </th>
              <th v-if="actions"></th>
            </tr>
          </thead>
          <tbody
            v-sortable="{ disabled: !sortable, sorting: items.body, callback: sortingCallback, selected: selected }"
          >
            <tr
              v-draggable="isDraggable && { data: () => getDragData(item) }"
              :class="`ips-table__row ${isEven(index)} pointer item-${item[0]} ${isDraggable ? 'draggable' : ''}`"
              v-for="(item, index) in items.body"
              :key="generateId(item.id)"
              @drop="(e) => saveInfo(e, JSON.stringify(item))"
              @click="clickRow ? clickRow(item) : checkRow(!selected.includes(item), item, $event)"
            >
              <td
                v-if="bulkactions"
                class="a-center row-checkbox"
                style="position: sticky; margin-top: -1px; left: -1px;"
              >
                <InputCheckbox :name="'checkboxHeader'" :value="selected.find((i) => i.Id == item.Id)" />
              </td>
              <td
                :style="isTouch ? 'padding: 1.5rem .5rem;' : ''"
                v-for="entry in items.head"
                :key="'table-item' + entry.Alias"
                v-html="renderer.render(item, entry.Alias)"
              ></td>
              <td v-if="actions">
                <span class="table-action" v-for="action in actions" :key="'action-' + action.name">
                  <span v-if="action.if ? (typeof action.if == 'function' ? action.if(item) : action.if) : true">
                    <span v-if="action.type">
                      <InputDynamic
                        :type="action.type"
                        :data="action.data"
                        :placeholder="typeof action.name == 'function' ? action.name(item) : action.name"
                        @input="(e) => action.action(item, e)"
                      />
                    </span>
                    <span v-else>
                      <button
                        class="button button--small button--action"
                        v-html="typeof action.name == 'function' ? action.name(item) : action.name"
                        :class="generateButtonClasslist(action, item)"
                        @click="execAction(action, item, item.Id, $event)"
                        :title="action.description && action.description"
                      ></button>
                      <div v-show="visibleReqs.includes(item.Id)" v-if="action.requirements">
                        <div class="table-action__popup">
                          <p v-html="action.requirements.consequence(item)"></p>
                          <button
                            class="button button--small"
                            @click="
                              action.action(item);
                              visibleReqs = [];
                            "
                          >
                            Ja
                          </button>
                          <button class="button button--small button--red" @click="visibleReqs = []">Nej</button>
                        </div>
                        <div class="table-action__backdrop"></div>
                      </div>
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
          <Summary
            v-if="!disableSummary"
            :extraCell="bulkactions ? true : false"
            :extraCellAfter="actions"
            :head="items.head"
            :items="items.body"
          />
        </table>
      </div>
    </transition-group>
    <p class="ips-table__empty" v-if="items.body && items.body.length == 0">Den här tabellen är tom</p>
    <Bulkactions
      v-if="bulkactions && items.body !== null"
      :selected="selected"
      :bulkactions="bulkactions"
      :useButtons="useButtons"
      @reset-selection="resetSelection()"
    />
    <div v-if="dragging" :style="`left: ${mouse.x}px; top: ${mouse.y}px`" class="bulkselected-counter">
      {{ selected.length ? selected.length : 1 }}
    </div>
  </div>
</template>

<script>
import { props, sorting, useItemRenderer, useBulkactions, execBulkaction, execAction } from "./table-old/common.js";
import Bulkactions from "@/components/globals/table-old/Bulkactions";
import Summary from "@/components/globals/table-old/Summary";
import { ref, reactive, computed, onMounted, watchEffect } from "vue";
import { isEven, JSONtoCSV, generateId } from "@/services/utils";
import { useRenderFunctions } from "./table-old/renderFunctions";
export default {
  components: {
    Bulkactions,
    Summary
  },
  emits: ["on-sorting"],
  props: {
    ...props
  },
  setup(props, { emit }) {
    const {
      items,
      maxHeight,
      itemRenderFunctions,
      bulkactions,
      isDraggable,
      diffDataKey,
      useButtons,
      enableRenderFunctions
    } = reactive(props);

    let mouse = reactive({ x: 0, y: 0 });
    let localitems = reactive([]);
    let dragging = ref(false);
    const init = () => {
      //Check if rows are draggable. Add mouse position data.
      if (isDraggable) {
        window.ondragover = (e) => {
          let pos = { x: e.clientX - 20, y: e.clientY - 50 };
          mouse.value = pos;
        };
      }
      //Create local data array.
      localitems.value = items.value;
    };

    //Calculate culumn width based on text length.
    const columnWidth = reactive({});
    const getColumnWidth = () => {
      if (items.body) {
        columnWidth.value = items.head.reduce((itemAcc, itemCurr) => {
          itemAcc[itemCurr.Alias] = items.body.reduce((acc, curr) => {
            if (!itemRenderFunctions[itemCurr.Alias]) {
              return curr[itemCurr.Alias] && (curr[itemCurr.Alias] + "").trim().length > acc
                ? parseInt((curr[itemCurr.Alias] + "").trim().length)
                : acc;
            } else {
              return acc;
            }
          }, itemCurr.Value.length);
          return itemAcc;
        }, {});
      }
    };

    //Sorting
    let descending = ref(true);
    let sortingBy = ref("");
    const sortingArrow = computed(() => (descending.value ? "sorting-up" : "sorting-down"));
    const sort = (key) => {
      sortingBy.value = key;
      localitems.body.sort(sorting);
      descending.value = !descending.value;
    };

    //Sortable
    const sortingCallback = (items) => {
      emit("on-sorting", items);
    };

    //Item rendering
    const renderFunctions = itemRenderFunctions || (enableRenderFunctions && useRenderFunctions());
    const itemSize = ref(diffDataKey ? 46 : 32);
    const multiplier = 25;
    const renderer = useItemRenderer(renderFunctions);
    const maxHeightPixels = computed(() => Math.floor(window.innerHeight * (maxHeight.value / 100)));

    //Bulkactions
    let selected = ref([]);
    const executer = useBulkactions(bulkactions, selected, useButtons);

    const resetSelection = () => {
      selected.value = [];
    };

    const checkAll = (val) => {
      selected.value = val ? items.body : [];
    };

    const checkRow = (val, item, e) => {
      e.stopPropagation();
      const length = selected.value.length - 1;
      if (e.shiftKey && selected.value.length) {
        const source = items.body.indexOf(selected.value[length]);
        const dest = items.body.indexOf(item) + 1;
        const selection = items.body.slice(Math.min(source, dest - 1), Math.max(source + 1, dest));
        selected.value = selection;
      } else if (e.shiftKey && !selected.value.length) {
        const source = item;
        if (val) {
          selected.value = [item];
        } else {
          selected.value = selected.value.filter((i) => i != item);
        }
      } else if (!e.shiftKey) {
        if (val) {
          selected.value.push(item);
        } else {
          selected.value = selected.value.filter((i) => i != item);
        }
      }
    };

    //Actions
    let visibleReqs = reactive([]);
    const execAction = (action, item, e) => {
      e.stopPropagation();
      if (action.requirements) {
        visibleReqs = [item.Id];
      } else {
        action.action(item);
      }
    };

    const generateButtonClasslist = (action) => {
      const style = `button--${action.buttonStyle}`;
      const color = `button--${action.color}`;
      return style + " " + color;
    };

    //data
    const csvData = computed(() => (items.value.body ? JSONtoCSV(items.value.body, items.value.head) : ""));

    watchEffect(() => {
      getColumnWidth();
      selected.value = selected.value.filter((s) => items.body.find((nv) => nv.Id === s.Id));
    });

    onMounted(() => {
      init();
    });

    return {
      renderer,
      maxHeightPixels,
      itemSize,
      sortingArrow,
      checkAll,
      checkRow,
      sortingCallback,
      executer,
      execAction,
      visibleReqs,
      dragging,
      localitems,
      mouse,
      csvData,
      generateButtonClasslist,
      sortingBy,
      columnWidth,
      multiplier,
      isEven,
      sort,
      selected,
      useButtons,
      bulkactions,
      resetSelection,
      generateId
    };
  }
};
</script>

<style lang="scss" scoped>
.scroller {
  height: 100%;
}

.ips-table {
  margin-bottom: 32px;
  font-size: 0.8rem;
  width: 100%;
  overflow-y: auto;
  @include scrollbar(4px);
  table {
    width: 100%;
  }
  &__empty {
    padding: 0.5rem 1rem;
    border-radius: 7px;
    color: darken($mas-lightGray, 5%);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 2rem auto;
    text-align: center;
  }

  &__row,
  &__head {
    padding: 0 0.5rem;
    text-align: left;
    th,
    td {
      padding: 0.5rem;
    }
    &--virtual {
      justify-content: space-between;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }

  &__head {
    cursor: pointer;
    color: white;
    background-color: rgba(52, 73, 94, 1);
    z-index: 2;
  }

  &__row {
    &--even {
      background-color: #f2f2f2;
    }
    &.draggable {
      cursor: grab;
    }
  }

  .vue-recycle-scroller {
    position: relative;
    &__item-wrapper {
      overflow: visible;
    }
    &__item-view {
    }
    &__slot {
      overflow: visible;
      position: sticky;
      z-index: 2;
      width: auto;
      &:first-child {
        top: -1px;
      }
      &:nth-child(3) {
        bottom: -1px;
      }
    }
    @include scrollbar(4px);
  }

  &__cell {
    padding: 0.25rem 0;
    flex-direction: column;
    display: inline-flex;
    justify-content: center;
    &:first-child {
      padding-left: 0.5rem;
      padding-right: 0.25rem;
    }
    &--head {
      background-color: rgba(52, 73, 94, 1);
      padding: 0.5rem 0;
      font-weight: 700;
      position: relative;
      &.sorting-up {
        &:after {
          content: " \25B2";
          display: inline;
          position: absolute;
          left: -1rem;
          z-index: 9;
        }
      }
      &.sorting-down {
        &:after {
          content: " \25BC";
          display: inline;
          position: absolute;
          left: -1rem;
          z-index: 9;
        }
      }
    }
    &--even {
      background-color: #f2f2f2;
    }
    &.draggable {
      cursor: grab;
    }
  }
  .vue-recycle-scroller {
    &__item-view {
      //width: auto !important;
      &.hover {
        background-color: darken(#f2f2f2, 5%);
        .ips-table__cell,
        .ips-table__row {
          background-color: darken(#f2f2f2, 5%);
        }
      }
    }
  }
  &--virtual {
    overflow-y: visible;
  }
  &--virtual &__row,
  &--virtual &__row {
    justify-content: space-between;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}

.bulkselected-counter {
  padding: 1rem;
  background-color: $mas-green;
  width: 2rem;
  height: 2rem;
  color: white;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  font-size: 0.8rem;
  font-weight: 700;
  border: 2px solid $mas-green;
  &:after {
    content: "";
    border-bottom: 2px solid $mas-green;
    border-right: 2px solid $mas-green;

    width: 12px;
    height: 12px;
    display: block;
    transform: rotate(45deg);
    background-color: $mas-green;
    bottom: -8px;
    left: 1rem;
    margin-left: -6px;
    position: absolute;
  }
}

.lazy-loader {
  width: 100%;
  &__head {
    background-color: $mas-green;
  }
  &__body {
    padding: 0;
    list-style-type: none;
    margin: 0;
    width: 100%;
    li {
      &:nth-child(odd) {
        background: #f2f2f2;
        background-image: linear-gradient(to right, #f2f2f2 0%, #edeef1 30%, #f8f7f7 50%, #edeef1 70%, #f2f2f2 100%);
        background-repeat: no-repeat;
        background-size: 600px 400px;
        position: relative;
        width: 100%;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeholderShimmer;
        animation-timing-function: linear;
      }
    }
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: top left;
  }

  50% {
    background-position: top right;
  }

  100% {
    background-position: top left;
  }
}

.status-icon {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  display: block;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.2;
  text-align: center;
  border-radius: 100px;
  display: block;
  &--true {
    background-color: green;
    color: white;
  }
  &--false {
    background-color: red;
    color: white;
  }
}

.reserved-items {
  position: absolute;
  right: -1px;
  margin-top: 0.25rem;
  z-index: 2;
  padding: 0.5rem;
  color: white;
  font-weight: 700;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  background-color: $mas-green;
  &__popup {
    display: none;
    position: absolute;
    z-index: 9;
    width: 120px;
    left: -140px;
    top: 0;
    background-color: white;
    color: black;
    border: 1px solid #e6e9ed;
    p {
      text-align: center;
      margin: 0.5rem 0;
    }
    &:after {
      display: block;
      content: "";
      background-color: white;
      border-top: 1px solid #e6e9ed;
      border-right: 1px solid #e6e9ed;
      transform: rotate(45deg);
      position: absolute;
      width: 0.8rem;
      height: 0.8rem;
      right: -0.4rem;
      top: 0.6rem;
    }
  }
}

.table-action {
  &__popup {
    position: fixed;
    background-color: white;
    border-radius: 10px;
    z-index: 9;
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid #e6e9ed;
    top: 40%;
    left: 40%;
  }
  &__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
  }
}

.table-responsive {
  overflow-y: auto;
  padding-right: 1rem;
  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }
  &::-webkit-scrollbar-track {
    background: #e6e6e6;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
}

.table {
  margin-bottom: 0;
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;

  tr {
    &.sortable-selected {
      td {
        background-color: rgba(26, 150, 156, 0.3) !important;
      }
    }
    cursor: pointer;
    &:hover {
      .reserved-items__popup {
        display: block;
      }
    }
    &.draggable {
      cursor: grab;
    }
    &.hovered {
      border: 2px dashed gray;
    }
    .row-checkbox {
      max-width: 2rem;
    }
    &:nth-child(odd) {
      background-color: #f2f2f2 !important;
      .row-checkbox {
        background-color: #f2f2f2;
      }
    }
    &:nth-child(even) {
      background-color: white !important;
      .row-checkbox {
        background-color: white;
      }
    }
  }
  th {
    color: white;
    position: sticky;
    top: -1px;
    background-color: rgba(52, 73, 94, 1);
    z-index: 2;
    vertical-align: center;
    &.sorting-up {
      &:after {
        content: " \25B2";
        display: inline;
      }
    }
    &.sorting-down {
      &:after {
        content: " \25BC";
        display: inline;
      }
    }
  }
  th,
  td {
    white-space: nowrap;
    vertical-align: middle;
  }
}
</style>
