<template>
   <div>
     <GlobXpanel :title="$t('dashboard.todays_maintenance')">
        <GlobTable
            :maxHeight="50"
            :data="{
                head: head,
                body: data
            }"
            :itemRenderFunctions="renderFunctions"
            :rowStylingFunction="rowStylingFunction"
        />
    </GlobXpanel>
   </div>
</template>

<script>

import { ref, computed } from "vue";
import { useI18n } from 'vue-i18n';

export default {
    props: ["data"],
    setup(props) {

        const { tm } = useI18n();

        const head = ref([
            {
                Alias: "AssetName",
                Value: computed(() => tm('dashboard')['asset'])
            },
            {
                Alias: "MaintenancePlanName",
                Value: computed(() => tm('dashboard')['maintenance_plan'])
            },
            {
                Alias: "Type",
                Value: computed(() => tm('dashboard')['type'])
            },
            {
                Alias: "ServiceStatus",
                Value: computed(() => tm('dashboard')['status'])
            }

        ]);

        const rowStylingFunction = (obj) => {
            if (obj.ServiceStatus == 'done') {
                return 'today-maintenance-done-status';
            }
        }

        const renderFunctions = computed(() => {
            return {
                ServiceStatus(obj) {
                    if (obj.ServiceStatus) {
                        return tm('dashboard')['status_list'][obj.ServiceStatus];
                    } else {
                        return '';
                    }
                }
            }
        });

        return {
            head,
            renderFunctions,
            rowStylingFunction
        }
    }
}
</script>
