<template>

	<div class="ips-custom-dropdown-virtual-search-multi">
		<div class="dropdown-search__tag-cloud" v-if="!showSelectedItemInBottom">
			<span v-for="(tag, index) in inputArray" :key="tag + '_' + index" class="dropdown-search__tag" :class="{'dropdown-search__tag__disabled': disabled}"><a class="dropdown-search__remove-tag" @click="removeTag(tag)">&times;</a><span>{{getTagName(tag)}}</span></span>
		</div>
		<div class="dropdown-search" :value="inputArray">
			<input :disabled="checkDisable()" type="text" class="mas-input" @click="focus" @focus="focus" v-model="inputString" @blur="blur" ref="input" :placeholder="placeholder ? placeholder : 'Sök'" @keyup.enter="selectFirst" @input="selectByText">
			<a v-if="inputString" @click="clear()" class="clear">&times;</a>
			<div v-if="showDropDown && isSearchDone && data.length > 0" class="dropdown-search__dropdown">
				<RecycleScroller class="scroller dropdown-list"
				:items="filterData"
				:item-size="itemSize"
					key-field="value"
				>
			<template v-slot="{ item }">
					<li class="dropdown-list__item" @click="setValue(item)" >{{item.name}}</li>
			</template>
			</RecycleScroller>
			</div>
		</div>
		<div class="dropdown-search__tag-cloud" v-if="showSelectedItemInBottom">
			<span v-for="(tag, index) in inputArray" :key="tag + '_' + index" class="dropdown-search__tag" :class="{'dropdown-search__tag__disabled': disabled}"><a class="dropdown-search__remove-tag" @click="removeTag(tag)">&times;</a><span>{{getTagName(tag)}}</span></span>
		</div>
	
	</div>
</template>

<script>
import { dropdownBody, dropdownSearchMethods } from '@/mixins/dropdown.js'
export default {
	/* 
MIXIN BODY:

props: placeholder, data, value
data: searching(bool) inputString(string)
methods: setValue(item), clear, focus, blur(e), filterArrayByString(data, filterValue)
computed: active, showDropDown, filterData
watch: value(val)

*/  
	mixins: [dropdownBody, dropdownSearchMethods],
	props:['showSelectedItemInBottom', 'activeIfEmptyData'],
	data() {
		return { 
			inputArray: [],
			itemSize: 34,
			heightLimit: 250,
			preventBlur: false,
			isMulti: true,
			isSearchDone: true
		}
	},
	activated() {
		this.inputArray = [];
	},
	methods: {
		removeTag(tag) {
			if (this.inputArray.includes(tag)) {
				this.inputArray = this.inputArray.filter(item => item != tag)
				this.$emit('input', this.inputArray)
			}
		},
		selectFirst() {
			const filterData = this.filterData;
			if (filterData.length > 0) {
				this.setValue(filterData[0]);
			this.inputString = '';

			}
		},
		getHeight() {
			const itemSizeHeight = this.filterData.length * this.itemSize;
			return itemSizeHeight <= this.heightLimit ? '' : `height: ${this.heightLimit}px;`;
		},
		setValue(item) {
			this.preventBlur = true;
			!this.inputArray.includes(item.value) && this.inputArray.push(item.value);
			this.$emit('input', this.inputArray)
		},
		blur(e) {
			setTimeout(() => {
				this.isSearching = false;
				}, 400);
		},
		getTagName(tagValue) {
			if(typeof this.data[0] == 'object') {
				const name = this.data.find(i => i.value === tagValue);
				return name ? name.name : 'undefined';
			} else {
				return tagValue;
			}
		},
		selectByText() {
			if (this.filterData.length == 1 && this.filterData[0].name.toString().toLowerCase() == this.inputString.toLowerCase()) {
				this.setValue(this.filterData[0]);
			this.inputString = '';
			}
		},
		checkDisable(){
			return this.data.length <= 0 && !this.activeIfEmptyData ? true : false;
		},
		clear() {
			this.$emit('searchKey', '')
			this.inputString = '';
		},
	},
	computed: {
		formattedData() {
			return typeof this.data[0] == 'object' ? this.data : this.data.map(i => {
				return {value: i, name: i ? i : ''}
			})
		},
		filterData() {
			if(this.inputString){
				return this.formattedData.filter(i => i.name.toString().replace(null, '').toLowerCase().indexOf(this.inputString.toLowerCase()) !== -1);
			}else{
				return this.formattedData;
			}
		},
		showDropDown() {
			return this.isSearching;
		}
	},
	watch: {
		value(val) {
			if (!this.ignoreValueWatch) {
				this.inputArray = Array.isArray(val) ? val : [];
			}
		},
		inputString(){
			this.isSearchDone = false;
			this.$emit('searchKey', this.inputString)
			setTimeout(() => this.isSearchDone = true, .1);
		}
	}
}
</script>

<style lang="scss">

	.ips-custom-dropdown-virtual-search-multi{
		.dropdown-search {
			position: relative;

			.clear {
				position: absolute;
				right: .8rem;
				top: 1.1rem;
				line-height: 0;
				font-weight: 500;
				font-size: 2rem;
				cursor: pointer;
			}
			input {
				width: 100%;
				border: 1px solid #C6DFE1;
				padding-right: 2rem;
				&:disabled {
					opacity: .5;
					background-color: #E6E9ED;
				}
			}
			&__dropdown {
				max-height: 400px;
				position: absolute;
				background-color: white;
				border-radius: 5px;
				border: 1px solid #E6E9ED;
				z-index: 20;
				top: 2.5rem;
				overflow-y: auto;
				width: 100%;

			}
			&__tag-cloud {

			}

			&__tag {
				padding: .2rem .5rem;
				border-radius: 100px;
				color: $mas-white;
                font-weight: 900;
				display: inline-flex;
				align-items: center;
				margin: .2rem 0;
				margin-right: .2rem;
				font-size: 0.65rem;
                background-color: $mas-greenBlue;
			}

			&__remove-tag {
				margin: 0 .5rem 0 0;
				font-size: 1.1rem;
				cursor: pointer;
				line-height: 0;
                color: $mas-white;
                text-decoration: none;
			}
		}
		.dropdown-list {
			list-style-type: none;
			padding: 0;
			margin-bottom: 0;
			&__item {
				padding: .5rem 1.5rem;
				cursor: pointer;
				border-bottom: 1px solid #E6E9ED;
				&:last-child {
					border-bottom: none;
				}
				&:hover {
					position: relative;
					background-color: #E6E9ED;
					z-index: 21;
				}
			}
		}
		.dropdown-search__tag__disabled{
			pointer-events: none;
			opacity: 0.5;
			background-color: #E6E9ED;
			color: unset;
		}
		&.dropdown-search {
			position: relative;

			.clear {
				position: absolute;
				right: .8rem;
				top: 1.1rem;
				line-height: 0;
				font-weight: 500;
				font-size: 2rem;
				cursor: pointer;
			}
			input {
				width: 100%;
				border: 1px solid #ccc;
				padding-right: 2rem;
				&:disabled {
					opacity: .5;
					background-color: #E6E9ED;
				}
			}
			.dropdown-search__dropdown {
				position: absolute;
				background-color: white;
				border-radius: 5px;
				border: 1px solid #E6E9ED;
				z-index: 20;
				top: 2.5rem;
				overflow-y: auto;
				width: 100%;

			}
		}
		.dropdown-list {
			list-style-type: none;
			padding: 0;
			margin-bottom: 0;
			.dropdown-list__item {
				padding: .5rem 1.5rem;
				cursor: pointer;
				border-bottom: 1px solid #E6E9ED;
				&:last-child {
					border-bottom: none;
				}
				&:hover {
					position: relative;
					background-color: #E6E9ED;
					z-index: 21;
				}
			}
		}
		&.dropdown-search {
			.dropdown-search__dropdown {
				width: unset;
				min-width: 100%;
				max-width: 150%;
				max-height: 250px;
			}
		}
		.vue-recycle-scroller.ready .vue-recycle-scroller__item-view {
			position: relative;
			transform: none !important;
			li{
				word-wrap: break-word;
			}
		}

		.vue-recycle-scroller__item-wrapper{
			min-height: unset !important;
		}
	}
</style>