<template>
	<div class="ips-status-field">
		<div class="home-welcome-messge">
			<p class="welcome">{{ $t('general.welcome') }},</p> <p class="user-name">{{user.Name }}</p>
		</div>
		<div class="ips-status-field__right">
		   <NotificationsToggle />
		   <User/>
		  <LocaleChanger />
		</div>
	</div>
	<div class="ips-status-field__left">
		<Breadcrumbs/>
	</div>
	<div id="loader-portal"></div>
	<div id="status-field-portal"></div>
</template>

<script>
import User from './status/User';
import Breadcrumbs from './status/Breadcrumbs';
import NotificationsToggle from './status/NotificationCenter/Toggle';
import { useStore } from 'vuex'
import { computed } from 'vue';
export default {
	setup() {

		const store = useStore();
		const user = computed(() => store.state.USER);

		return {
			user
		}
	},
	components: {
		User,
		NotificationsToggle,
		Breadcrumbs
	},
}
</script>

<style lang="scss" scoped>
	.ips-status-field {
		z-index: 9;
		top: 0;
		height: 55px;
		background-color: transparent;
		padding-left: .5rem;
		display: flex;
		align-items: center;
		font-size: .8rem;
		&__right {
			display: flex;
			align-items: center;
			margin-left: auto;
			width: 100%;
			justify-content: flex-end;
			margin-right: 21px;
		}

		&__left {
			display: flex;
			padding-bottom: 5px;
			margin-top: 18px;
			padding-left: 21px;
    		padding-right: 21px;
		}
	}

	//New version
	.ips-status-field{
		padding-left: 14px;
	}

	.home-welcome-messge{
		color: $mas-steelGrayV1;
		display: contents;
		.user-name{
			font-weight: bolder;
			color: #465666;
			margin-left: 15px;
		}
		p{
			margin: 0;
			display: inline-block;
			font-family: 'Inter';
			font-style: normal;
			font-size: 30px;
			line-height: 36px;

		}
		.welcome{
			font-weight: 100;
		}
		}
</style>