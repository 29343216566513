
import Article from "@/views/crud/Article/Articles.vue";

export const article = [{
  path: "/admin/view/articles",
  component: Article,
  isCRUD: true,
  name: "Article",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Articles" }]
  },
}
]
