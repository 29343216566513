<template>
    <div>
        <Navbar :items="routes" />
        <GlobRow>
            <GlobSpinner :isLoading="isLoading" />
            <GlobCol :medium="12">
                <GlobXpanel :title="$t('administration.stock_place.stock_places')">
                    <button @click="showCreateModal = true" style="margin-bottom: 1rem; display: inline-block;"
                        class="button button--margin button--small">{{$t('general.create_new')}}</button>
                    <InputText type="text" v-model="searchString" :placeholder="$t('general.search')"
                        :style="'margin-left: 1rem; padding: .4rem 1rem'" />
                    <button @click="treeView = !treeView" class="button button--margin button--small float-right">{{ !treeView ? $t('administration.asset.tree_view') : $t('administration.asset.table_view')}}</button>
                    <template v-if="!treeView">
                        <GlobTable :maxHeight="50" 
                                    :data="{
                                            head : mainListHead,
                                            body : filteredItems
                                    }" 
                                    :actions="actions" />
                    </template>
                    <template v-else>
                        <vue3TreeVue :items="filteredTreeItems"
                           class="basic-tree-view"
						   @onSelect="onTreeItemSelected">

                           <!-- Add icons -->
							<template v-slot:item-prepend-icon="treeViewItem" >
								<div class="tree-icons">
                                <!-- Manually add guide line for parent nodes, since it is not working -> Start -->
                                <div class="guide-line" v-if="treeViewItem.Level == 0 && (!treeViewItem.children || !treeViewItem.children.length)"></div>
                                <!-- Manually add guide line for parent nodes, since it is not working -> end -->
								<template v-if="treeViewItem.children">
									<img src="@/assets/icon/machine-icon.png"
										:alt="treeViewItem.name"
										height="15" width="15" />
								</template>
								<template v-else>
									<img src="@/assets/icon/engine-icon.png"
										:alt="treeViewItem.name"
										height="15" width="15" />
								</template>
								</div>
							</template>
						</vue3TreeVue>
                    </template>
                </GlobXpanel>
            </GlobCol>
        </GlobRow>
    </div>

    <!-- Create StockPlace modal -->
    <GlobModal :widthInPx="1000" :title="$t('administration.stock_place.create')" @close="showCreateModal = false"
        :show="showCreateModal" :isCRUD="true" v-if="showCreateModal">
        <Create :roles="roles" @created="(stockPlace) => loadMainList()" @close="showCreateModal = false" />
    </GlobModal>

    <!-- Edit StockPlace modal -->
    <GlobModal :widthInPx="1100" :title="selectedItem.Name ? selectedItem.Name : selectedItem.ArticleNumber"
        @close="selectedItem = null " :show="selectedItem && selectedItem.Id && !hideEditModal"
        v-if="selectedItem && selectedItem.Id" :isCRUD="true">
        <Edit :roles="roles" :itemId="selectedItem.Id" @updated="(stockPlace) => updated(stockPlace)"
            @showDeleteConfirmModal="showDeleteConfirmModal = true"
            @showPlanDeleteConfirmModal="(id) => selectedMaintenancePlanId = id"
            @showAssetServiceReasonDeleteModal="(id) => selectedAssetServiceRelationId = id"
            @showAssetSurveillanceDeleteModal="showAssetSurveillanceDeleteModal = true" />
    </GlobModal>
    <!-- Delete confirm modal -->
    <GlobConsequence :show="showDeleteConfirmModal" @close="showDeleteConfirmModal=false" @confirm="deleteItem()"
        :title="$t('administration.stock_place.delete_stock_place')" :message="$t('administration.stock_place.delete_confirm')" />
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import Create from "@/views/crud/StockPlace/Create.vue";
import Edit from "@/views/crud/StockPlace/Edit.vue";
import vue3TreeVue from 'vue3-tree-vue';

export default {
    components: {
        Navbar,
        Create,
        Edit,
        vue3TreeVue
    },
    setup() {
        const { tm } = useI18n();
        const api = useApi();
        const router = useRouter();
        const endpoint = computed(() => api.stockPlaces);

        const roles = ref([]);
        const selectedItem = ref(null);

        const showCreateModal = ref(false);
        const showDeleteConfirmModal = ref(false)

        const hideEditModal = computed(() => {
            return showDeleteConfirmModal.value ? true : false;
        })

        const isLoading = ref(false);
        const treeView = ref(false);
        const notifications = useNotifications();
        const searchString = ref('');
        const treeViewData = ref([]);
        const mainList = ref([]);
        const mainListHead = ref([
            {
                Alias: "Name",
                Value: computed(() => tm('administration')['stock_place']['fields']['name'])
            },
            {
                Alias: "ParentName",
                Value: computed(() => tm('administration')['stock_place']['fields']['parent'])
            },
        ]);

        const actions = computed(() => {
            return [
                {
                    name: tm('general')['button']['edit'],
                    color: "green",
                    action: (obj) => {
                        selectedItem.value = obj;
                    }
                }
            ];
        });

        onMounted(async () => {
            loadMainList();
        });

        const loadMainList = async () => {
            isLoading.value = true;
            endpoint.value.get().then(function (res) {
                roles.value = res.roles;
                mainList.value = res.data;
                treeViewData.value = res.tree;
                isLoading.value = false;
            })
        }

        const filteredItems = computed(() => {
            return searchString.value.length > 0 ? mainList.value
                .filter(
                    i => (i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase()))
                ) : mainList.value;
        })

        const filteredTreeItems = computed(() => {
            return searchString.value.length > 0 ? treeViewData.value
                    .filter(
                        i => (i.name && i.name.toLowerCase().match(searchString.value.toLowerCase()))
                            || (i.ParentName && i.ParentName.toLowerCase().match(searchString.value.toLowerCase()))
                        ) : treeViewData.value;
        })


        const created = (stockPlace) => {
            showCreateModal.value = false;
            loadMainList();
        }

        const updated = (stockPlace) => {
            selectedItem.value = null;
            loadMainList();
        }

        const deleteItem = () => {
            showDeleteConfirmModal.value = false;
            isLoading.value = true;
            endpoint.value.delete(selectedItem.value.Id).then((res) => {
                notifications.push(
                    {
                        msg: tm('administration')['stock_place']['delete_success']
                    },
                    "success",
                    false
                );
                selectedItem.value = null;
                loadMainList();
            });
        }

        const onTreeItemSelected = (item) => {
            selectedItem.value = {
                'Id': item.id,
                'Name': item.name
            }
        }


        return {
            treeView,
            loadMainList,
            filteredItems,
            filteredTreeItems,
            onTreeItemSelected,
            endpoint,
            mainList,
            treeViewData,
            mainListHead,
            searchString,
            actions,
            roles,
            routes,
            isLoading,
            selectedItem,
            hideEditModal,
            showCreateModal,
            created,
            //delete
            showDeleteConfirmModal,
            deleteItem,
            updated
        };
    }
};
</script>