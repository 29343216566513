<template>
    <div>
        <Navbar :items="routes" />
        <GlobRow>
            <GlobSpinner :isLoading="isLoading" />
            <GlobCol :medium="12">
                <GlobXpanel :title="$t('administration.troubleshoot_guide.troubleshoot_guides')">
                    <button @click="showCreateModal = true" style="margin-bottom: 1rem; display: inline-block;"
                        class="button button--margin button--small">{{ $t('general.create_new') }}</button>
                    <InputText type="text" v-model="searchString" :placeholder="$t('general.search')"
                        :style="'margin-left: 1rem; padding: .4rem 1rem'" />
                    <GlobTable :maxHeight="50" :data="{
                        head: mainListHead,
                        body: filteredItems
                    }" :actions="actions" 
                    :itemRenderFunctions="renderFunctions"
                    />
                </GlobXpanel>
            </GlobCol>
        </GlobRow>
    </div>

    <!-- Create guide modal -->
    <GlobModal :widthInPx="1000" :title="$t('administration.troubleshoot_guide.create')" @close="showCreateModal = false"
        :show="showCreateModal && !showFileLibraryModal" :isCRUD="true" v-if="showCreateModal">
        <Create :roles="roles" 
            @created="(guide) => loadMainList()" 
            @close="showCreateModal = false" 
            @showFileLibraryModal="(val) => showFileLibraryModal = val"	/>
    </GlobModal>

    <!-- Edit guide modal -->
    <GlobModal :widthInPx="1100" :title="selectedItem.ProblemHeading"
        @close="selectedItem = null" :show="selectedItem && selectedItem.Id && !hideEditModal"
        v-if="selectedItem && selectedItem.Id" :isCRUD="true">
        <Edit :itemId="selectedItem.Id" @updated="(guide) => updated(guide)"
            @showDeleteConfirmModal="showDeleteConfirmModal = true"
            @showFileLibraryModal="(val) => showFileLibraryModal = val"
            />
    </GlobModal>
    <!-- Delete confirm modal -->
    <GlobConsequence :show="showDeleteConfirmModal" @close="showDeleteConfirmModal = false" @confirm="deleteItem()"
        :title="$t('administration.troubleshoot_guide.delete_guide')" :message="$t('administration.troubleshoot_guide.delete_confirm')" />
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import Create from "@/views/crud/TroubleshootGuide/Create.vue";
import Edit from "@/views/crud/TroubleshootGuide/Edit.vue";

export default {
    components: {
        Navbar,
        Create,
        Edit
    },
    setup() {
        const { tm } = useI18n();
        const api = useApi();
        const router = useRouter();
        const endpoint = computed(() => api.troubleshootGuides);

        const roles = ref([]);
        const selectedItem = ref(null);

        const showCreateModal = ref(false);
        const showDeleteConfirmModal = ref(false)

        const showFileLibraryModal = ref(false);

        const hideEditModal = computed(() => {
            return showDeleteConfirmModal.value || showFileLibraryModal.value ? true : false;
        })

        const isLoading = ref(false);
        const notifications = useNotifications();
        const searchString = ref('');
        const mainList = ref([]);
        const mainListHead = ref([
            {
                Alias: "ProblemHeading",
                Value: computed(() => tm('administration')['troubleshoot_guide']['fields']['problem_heading'])
            },
            {
                Alias: "SolutionHeading",
                Value: computed(() => tm('administration')['troubleshoot_guide']['fields']['solution_heading'])
            },
            {
                Alias: "tags",
                Value: computed(() => tm('administration')['troubleshoot_guide']['fields']['tags'])
            },
            {
                Alias: "assets",
                Value: computed(() => tm('administration')['troubleshoot_guide']['fields']['assets'])
            }
        ]);

        const renderFunctions = computed(() => {
            return {
                tags(obj) {
                    if (obj.tags && obj.tags.length) {
                        return obj.tags.map((tag) => tag.Name).join(' ,');
                    } else {
                        return '';
                    }
                },
                assets(obj) {
                    if (obj.assets && obj.assets.length) {
                        return obj.assets.map((tag) => tag.Name).join(' ,');
                    } else {
                        return '';
                    }
                },
            }
        });

        const actions = computed(() => {
            return [
                {
                    name: tm('general')['button']['edit'],
                    color: "green",
                    action: (obj) => {
                        selectedItem.value = obj;
                    }
                }
            ];
        });

        onMounted(async () => {
            loadMainList();
        });

        const loadMainList = async () => {
            isLoading.value = true;
            endpoint.value.get().then(function (res) {
                roles.value = res.roles;
                mainList.value = res.data;
                isLoading.value = false;
            })
        }

        const filteredItems = computed(() => {
            return searchString.value.length > 0 ? mainList.value
                .filter(
                    i => (i.ProblemHeading && i.ProblemHeading.toLowerCase().match(searchString.value.toLowerCase()))
                ) : mainList.value;
        })


        const created = () => {
            showCreateModal.value = false;
            loadMainList();
        }

        const updated = () => {
            selectedItem.value = null;
            loadMainList();
        }

        const deleteItem = () => {
            showDeleteConfirmModal.value = false;
            isLoading.value = true;
            endpoint.value.delete(selectedItem.value.Id).then((res) => {
                notifications.push(
                    {
                        msg: tm('administration')['troubleshoot_guide']['delete_success']
                    },
                    "success",
                    false
                );
                selectedItem.value = null;
                loadMainList();
            });
        }


        return {
            loadMainList,
            filteredItems,
            endpoint,
            mainList,
            mainListHead,
            searchString,
            actions,
            roles,
            routes,
            isLoading,
            selectedItem,
            hideEditModal,
            showCreateModal,
            created,
            renderFunctions,
            showFileLibraryModal,
            //delete
            showDeleteConfirmModal,
            deleteItem,
            updated
        };
    }
};
</script>