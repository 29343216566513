<template>
    <div>
        <GlobXpanel :title="$t('dashboard.delayed_maintenance')">
            <GlobTable :maxHeight="30" :data="{
                head: head,
                body: data
            }" />
        </GlobXpanel>
    </div>
</template>

<script>

import { ref, computed } from "vue";
import { useI18n } from 'vue-i18n';

export default {
    props: ["data"],
    setup(props) {

        const { tm } = useI18n();

        const head = ref([
            {
                Alias: "AssetName",
                Value: computed(() => tm('dashboard')['asset'])
            },
            {
                Alias: "ExecutionDate",
                Value: computed(() => tm('dashboard')['date'])
            }

        ]);

        return {
            head
        }
    }
}
</script>
