<template>
	<label class="ma-toggle" :class="small ? 'ma-toggle--small' : ''" :style="`width:${width}px;`">
		<input
			type="checkbox"
			class="ma-toggle__input"
			:checked="value"
			@input="toggle"
		/>
		<span class="slider">
			<div class="slider__wheel"
			:class="toggled ? 'toggled' : ''">{{toggled ? toggles.on : toggles.off}}
			</div>
		</span>
	</label>
</template>

<script>
import { ref } from 'vue';
export default {
	props: {
		value: {
			type: Boolean,
			default: false
		},
		toggles: {
			type: Object,
            default() {
                return {
                    on: 'on',
                    off: 'off'
                }
            }
		},
		small: {
			type: Boolean,
			required: false
		},
		width: {
			type: Number,
			default: 80
		}
	},
    emits: ["update:modelValue", "change"],
    setup(props, { emit }){

        const toggled = ref(props.value);

        const toggle = (event) => {
            emit('update:modelValue', event.target.checked);
            toggled.value = event.target.checked
        }
        return {
            toggled,
            toggle,
            props
        }
    }
}
</script>

<style lang="scss" scoped>
.ma-toggle {
  position: relative;
  display: inline-block;
  width: 120px;
	height: 34px;
	&--small {
		height: 24px;
		.slider__wheel {
			height: 16px;
		}
	}
}

/* Hide default HTML checkbox */
.ma-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
	border-radius: 5px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	transition: all .3s;
	background-color: darken($mas-lightGray, 5%);
	&__wheel {
        font-size: .8rem;
		color: $mas-carbonGrey;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
	  height: 26px;
	  left: 4px;
		padding-left: .5rem;
		padding-right: .5rem;
	  bottom: 4px;
	  background-color: white;
	  border-radius: 3px;
		transition: all .3s;
		&.toggled {
			transition: all .3s;
			box-shadow: 0 0 1px $mas-blue;
			right: 4px;
			left: auto;
			margin-right: 0;
		}
	}
}

input:checked	+ .slider  {
	background-color: $mas-parisGreen;
}
</style>