<template>
	<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
		<GlobSpinner :isLoading="isLoading"/>
		<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
			<GlobRow>
				<GlobCol :medium="3">
					<label style="font-size: .8rem; max-width: 800px; display: block;">
						{{$t('administration.article.fields.name')}}*
						<InputDynamic
						:fullWidth="true"
						styling="margin-bottom: .5rem;"
						:type="'text'"
						:placeholder="$t('administration.article.fields.name')"
						v-model="article.form.Name"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<label style="font-size: .8rem; max-width: 800px; display: block;">
						{{$t('administration.article.fields.article_number')}}*
						<InputDynamic
						:fullWidth="true"
						styling="margin-bottom: .5rem;"
						:type="'text'"
						:placeholder="$t('administration.article.fields.article_number')"
						v-model="article.form.ArticleNumber"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="6">
						<label>
							{{ $t('administration.article.fields.description') }}
							<InputDynamic
							:fullWidth="true"
							styling="margin-bottom: .5rem;"
							:type="'text'"
							:placeholder="$t('administration.article.fields.description')"
							v-model="article.form.Description"
							/>
						</label>
					</GlobCol>
				<GlobCol :medium="6" v-if="articleCategories.value && articleCategories.value.length">
					<label>
						{{$t('administration.article.fields.article_category')}}
						<InputDynamic
						:fullWidth="true"
						styling="margin-bottom: .5rem;"
						:type="'multiselect'"
						:placeholder="$t('administration.article.fields.article_category')"
						v-model="article.form.ArticleCategory"
						:data="articleCategories.value"
						:selected="article.form.ArticleCategory"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3" v-if="stockPlaces.value && stockPlaces.value.length">
						<label>
							{{ $t('administration.article.fields.default_stock_place') }}
							<InputDynamic
							:fullWidth="true"
							styling="margin-bottom: .5rem;"
							:type="'virtualselect'"
							:data="stockPlaces.value"
							:placeholder="$t('administration.article.fields.default_stock_place')"
							v-model="article.form.DefaultStockPlaceId"
							/>
						</label>
					</GlobCol>
			</GlobRow>
			<div v-if="errorMessage">
				<span class="text-danger" >{{errorMessage}}</span>
			</div>
			<button @click="save" class="button mas-mt-4" :disabled="isSaveButtonDisabled">{{$t('general.button.save')}}</button>
		</GlobXpanel>
	</GlobXpanel>

</template>

<script>
import {onMounted, reactive, ref, computed } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import { useNotifications } from "@/plugins/notifications";

export default {
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.articles);
	const api = useApi();

	const articleCategories = reactive([]);
	const stockPlaces = reactive([]);
	const notifications = useNotifications();
	const errorMessage = ref(null);
	const isLoading = ref(false);

	const article = reactive({
		form: {
			ArticleNumber: "",
		},
	});

    const save = () => {
		errorMessage.value = null;
		isLoading.value = true;
		endpoint.value.create(article.form).then((res) => {
			isLoading.value = false;
			if(res.errors && res.errors.ArticleNumber){
				errorMessage.value = tm(res.errors.ArticleNumber[0]);
			}else{
				notifications.push(
					{
						msg: tm('administration')['article']['create_success']
					},
					"success",
					false
				);
				emit('articleCreated', res.article)
			}
		});
    };

	const isSaveButtonDisabled = computed(() => {
		return !article.form.ArticleNumber || !article.form.Name ? true : false
	})

	onMounted(() => {
		loadDependencies();
	})

	const loadDependencies = () => {
		isLoading.value = true;	
		endpoint.value.getDependencies().then((res) => {
			articleCategories.value = res.categories.map((item) => {
					return {
						'name': item.Name,
						'value': item.Id
					}
			});

			stockPlaces.value = res.stock_places.map((item) => {
				return {
					'name': item.Name,
					'value': item.Id
				}
			});

			isLoading.value = false;
		})
	}

    return {
      save,
	  errorMessage,
	  article,
	  isLoading,
	  isSaveButtonDisabled,
	 articleCategories,
	  stockPlaces,
	  loadDependencies
    };
  },
};
</script>

<style lang="scss">
.mas-edit-article {
  &__image {
    width: 200px;
    display: block;
    margin-bottom: 0.5rem;
  }
}
.mas-article-lockers {
  display: flex;
	margin: 0.5rem 1rem 2rem 1rem;
	> div {
		margin-right: 2rem;
	}
  p {
    font-size: 0.8rem;
    text-decoration: uppercase;
  }
  &__list {
		display: flex;
		&--small {
			font-size: .6rem;
		}
  }
  span {
    padding: 0.35rem 1rem;
    border-radius: 4px;
		@include elevation(1);
    margin-right: 1.5rem;
		font-size: 0.8rem;
		align-items: center;
		display: flex;
		a {
			display: flex;
			font-size: 0.8rem;
			align-items: center;
		}
    i {
      color: $mas-steelGray;
      margin-right: 0.8rem;
    }
  }
}
</style>
