<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="quickSelected ? 7 :12">
				<GlobXpanel :title="$t('administration.service_description.service_descriptions')">
					<button @click="showCreateModal = true" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">{{$t('general.create_new')}}</button>
					<InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
					<GlobTable
						:maxHeight="50"
						:data="{
							head : mainListHead,
							body : filteredItems
						}"
						:itemRenderFunctions="renderFunctions"
						:actions="actions"
					/>
				</GlobXpanel>
			</GlobCol>
			<GlobCol v-if="quickSelected" :medium="5">
				<GlobXpanel title="Snabbredigera">
					<div class="ips-admin-fieldgroup">
							<div class="ips-admin-quickedit">
								<label>
								{{$t('administration.service_description.fields.name')}}*
								<InputDynamic
									:fullWidth="true"
									styling="width: 100%; padding: .5rem;"
									:type="'text'"
									v-model="dataObject.Name"
								/>
								</label>
                                <label>
								{{$t('administration.service_description.fields.description')}}*
								<InputDynamic
									:fullWidth="true"
									styling="width: 100%; padding: .5rem;"
									:type="'textarea'"
									v-model="dataObject.Description"
									:rows="6"
								/>
								</label>
								<button :disabled="isSaveButtonDisabled" @click="save" class="button mas-mt-3">
								{{$t('general.button.save')}}
								</button>
								<button @click="quickSelected = false; dataObject = {};" class="button button--orange">
								{{$t('general.button.cancel')}}
								</button>
							</div>
					</div>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>

<!-- Create serviceDescription modal -->
<GlobModal :widthInPx="1000" :title="$t('administration.service_description.create')" @close="showCreateModal = false" :show="showCreateModal" :isCRUD="true" v-if="showCreateModal">
	<Create @created="(serviceDescription) => created(serviceDescription)" />
</GlobModal>
<!-- Edit serviceDescription modal -->
<GlobModal :widthInPx="1000" :title="selectedItem.Name ? selectedItem.Name : selectedItem.Description" @close="selectedItem = null" :show="selectedItem && selectedItem.Id && !hideEditModal" v-if="selectedItem && selectedItem.Id" :isCRUD="true">
	<Edit 
		:itemId="selectedItem.Id" 
		:loading="itemModalLoading"
		:reloadData="reloadEditModal"
		@refreshList = "(newServiceDesciptionId) => loadItems(newServiceDesciptionId)"
		@updated="(serviceDescription) => updated(serviceDescription)"
		@showDeleteConfirmModal="showDeleteConfirmModal = true"
		@showFileLibraryModal="(val) => showFileLibraryModal = val"/>
</GlobModal>
	<!-- Delete confirm modal -->
	<GlobConsequence :show="showDeleteConfirmModal" 
		@close="showDeleteConfirmModal=false"  
		@confirm = "deleteItem()"
		:title="$t('administration.service_description.delete_service_description')" 
		:message="$t('administration.service_description.delete_confirm')"  />


</template>



<script>
import { onMounted, computed, watch, ref, watchEffect } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import Create from "@/views/crud/ServiceDescription/Create.vue";
import Edit from "@/views/crud/ServiceDescription/Edit.vue";

export default {
components: {
	Navbar,
	Create,
	Edit
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.serviceDescriptions);
    
	const selectedItem = ref(null);

	const showCreateModal = ref(false);
	const showDeleteConfirmModal = ref(false)
	const reloadEditModal = ref(false);
	const itemModalLoading = ref(false);
	const hideEditModal = computed(() =>{
		return showDeleteConfirmModal.value || showFileLibraryModal.value ? true : false;
	})

	const isLoading = ref(false);
	const notifications = useNotifications();
	const loaded = ref(false);
	const searchString = ref('');
	const mainList  = ref([]);
	const mainListHead = ref([
			{
				Alias: "Id",
				Value: computed(() => 'Id')
			},
			{
				Alias: "Name",
				Value: computed(() => tm('administration')['service_description']['fields']['name'])
			},
			{
				Alias: "Description",
				Value: computed(() => tm('administration')['service_description']['fields']['description'])
			},

		]);
	
	const actions = computed(() => { return [
		{
			name: tm('general')['button']['edit'],
			color: "green",
				action: (obj) => {
					selectedItem.value = obj;
				}
		},
		{
			name: tm('general')['button']['quick_edit'],
			color: "blue",
			action: (obj) => {
				setEditor(obj);
			}
		},
		];
	});

	const renderFunctions = computed(()=> {
		return {
			Description(obj){
				return obj.Description.length > 150 ?
					   obj.Description.substring(0, 150) + "..." : 
					   obj.Description;
			}
		}
	});
	
	 onMounted(async() => {
          loadItems();
	 });

	const loadItems = async(newServiceDesciptionId = null) => {
		isLoading.value = true;
		endpoint.value.get().then(function(res){
           mainList.value = res.data;
		   if(newServiceDesciptionId){
			   selectedItem.value = res.data.find(i=> i.Id == newServiceDesciptionId)
		   }
		   isLoading.value = false;
		})
	}

	

	const filteredItems = computed(() => {
		return searchString.value.length > 0 ? mainList.value
				.filter(
					i => (i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase()))
					||  (i.Description && i.Description.toLowerCase().match(searchString.value.toLowerCase()))
					) : mainList.value;
	})

	const dataObject = ref({});
	const dataObjectOriginal = ref({});
	const quickSelected = ref(false);
	const setEditor = (obj) => {
		dataObject.value.Id = obj.Id;
		dataObject.value.Name = obj.Name;
		dataObject.value.Description = obj.Description;

		dataObjectOriginal.value = JSON.parse(JSON.stringify(dataObject.value))
		quickSelected.value = true;
    };

	const isSaveButtonDisabled = computed({
		get() {
			if(!dataObject.value.Name){
				return false;
			} else {
				if(JSON.stringify(dataObject.value) === JSON.stringify(dataObjectOriginal.value)){
					return true;
				}
			}
			return false;
		},
		set(newValue) {},
	})

	const save = () => {
		isLoading.value = true;
		endpoint.value.update(dataObject.value.Id, dataObject.value).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['service_description']['update_success']
					},
					"success",
					false
			);
			if(res.new_service_desciption_id){
				loadItems();
			}else{
				updated(dataObject.value);
			}
			dataObject.value = {};
			quickSelected.value = false;
			isLoading.value = false;
			
		});
	};

	const created = (serviceDescription) => {
		showCreateModal.value = false;  
		selectedItem.value = serviceDescription;
		loadItems();
	}

	const updated = (serviceDescription) => {
		let index = mainList.value.findIndex((i) => i.Id == serviceDescription.Id)
		mainList.value[index].Description = serviceDescription.Description;
		mainList.value[index].Name = serviceDescription.Name;
	}

	const deleteItem = () => {
		showDeleteConfirmModal.value = false;
		itemModalLoading.value = !itemModalLoading.value;
		endpoint.value.delete(selectedItem.value.Id).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['service_description']['delete_success']
					},
					"success",
					false
			);
			selectedItem.value = null;
			loadItems();
		});
	}


	const getItem = (serviceDescriptionId) => {
		endpoint.value.getById(serviceDescriptionId).then(function(res){
			updated(res.data)
			isLoading.value = false;
		});
	}

	//File library

	const showFileLibraryModal = ref(false);


    return {
		loadItems,
		filteredItems,
		endpoint,
		mainList,
		mainListHead,
		renderFunctions,
		searchString,
		actions,
		routes,
		loaded,
		quickSelected,
		dataObject,
		dataObjectOriginal,
		save,
		isLoading,
		selectedItem,
		hideEditModal,
		showCreateModal,
		created,
		reloadEditModal,
		itemModalLoading,
		//delete
		showDeleteConfirmModal,
		deleteItem,
		updated,
		isSaveButtonDisabled,
		//File library
		showFileLibraryModal
    };
  }
};
</script>