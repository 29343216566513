
import APIKey from "@/views/crud/APIKey/APIKeys.vue";

export const apiKey = [{
  path: "/admin/view/api-keys",
  component: APIKey,
  isCRUD: true,
  name: "APIKey",
  nav: {
    name: "Admin",
    icon: "fa-hdd"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "API keys" }]
  },
}
]
