
import ArticleCategory from "@/views/crud/ArticleCategory/ArticleCategories.vue";

export const articleCategory = [{
  path: "/admin/view/article-categories",
  component: ArticleCategory,
  isCRUD: true,
  name: "ArticleCategory",
  nav: {
    name: "Admin",
    icon: "fa-hdd"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Article categories" }]
  },
}
]
