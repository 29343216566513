<template>
    <div class="mas-glob-file-library">
        <div class="select-upload-library-file">
             <p @click="showFiles = true; $emit('showFileLibraryModal', true)" v-if="!showFiles">{{ $t('administration.asset.select_from_library') }}</p><span @click="showFiles = true; $emit('showFileLibraryModal', true)"><i class="fas fa-photo-video"></i></span>
        </div>
       
        
        <GlobModal 
            :title="$t('file_library.select_files')" 
            :show="showFiles && !showAddNewFileModal && !showFilePreview" :isCRUD="true" @close="showFiles = false; $emit('showFileLibraryModal', false)" v-if="showFiles" >
            <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" :isCRUD="true">
                <GlobSpinner :isLoading="isMainListLoading" ></GlobSpinner>
                <button @click="showAddNewFileModal = true" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">{{ $t('general.create_new') }}</button>
                <InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
                <GlobTable
                    :maxHeight="50"
                    :data="{
                        head: tableHead,
                        body: updatedTableBody
                    }"
                    :enableEditing="true"
                    :fields="fieldsToEdit"
                    :actions="actions"
                    @change-item="(item) => inputChanged(item)"
                />

            </GlobXpanel>
        </GlobModal>

        <!-- Add new file modal -->
        <GlobModal 
            :title="$t('file_library.add_file')" 
            :show="showAddNewFileModal" :isCRUD="true" @close="showAddNewFileModal = false;" v-if="showAddNewFileModal" >
            <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" :isCRUD="true">
                <createFile @created="(file) => fileCreated(file)"></createFile>
            </GlobXpanel>
        </GlobModal>

        <!-- Show file Preview -->
        <GlobModal
                :title="selectedFile.Name"
                :width="50"
                :show="showFilePreview" 
                :isCRUD="true" 
                @close="showFilePreview = false;" 
                v-if="showFilePreview">
            <GlobXpanel  class="mas-glob-file-library-preview" :noShadow="true" :tools="false" :avoidHR="true" :isCRUD="true">
                <GlobFilePreview 
                    :width="'500px'"
                    :height="'500px'"
                    :file="{
                        Name: selectedFile.Name,
                        Type: selectedFile.Type,
                        URL: selectedFile.ExternalLink ? selectedFile.ExternalLink : getSavedFileURLFromPath(selectedFile.Path)
                    }" />
            </GlobXpanel>
        </GlobModal>
        
    </div>
    
</template>


<script>
import { ref, onMounted, computed, watch } from 'vue';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import { getSavedFileURLFromPath } from '@/helpers.js';
import createFile from '@/views/crud/FileLibrary/Create.vue';
    
    export default {
    props: ['addedFiles', 'disableMultipleSelect'],
    components: {
        createFile
    },
    setup(props, {emit}) {

        const { tm } = useI18n();
        const api = useApi();
        const endpoint = api.fileLibrary;

        const showFiles = ref(false);
        const searchString = ref('');
        const showAddNewFileModal = ref(false);
        const showFilePreview = ref(false);
        const selectedFile = ref(null);
        const isMainListLoading = ref(false);
        const files = ref([]);
        const tableHead = ref([
            {
                Alias: "Name",
                Value: computed(() => tm('file_library')['name'])
            },
            {
                Alias: "Description",
                Value: computed(() => tm('file_library')['description'])
            },
            {
                Alias: "Type",
                Value: computed(() => tm('file_library')['type'])
            },
            {
                Alias: "Active",
                Value: computed(() => tm('file_library')['selected'])
            },

        ]);

        const actions = computed(() => {
            return [
                {
                    name: tm('file_library')['preview'],
                    color: "green",
                    action: (obj) => {
                        selectedFile.value = obj;
                        showFilePreview.value = true;
                    }
                }
            ];
        });

        const fieldsToEdit = ref([
            { key: "Active", type: "checkbox" },
        ]);

        onMounted(() => {
            getFiles();
        })
        

        const getFiles = () => {
            endpoint.get().then((res) => {
                files.value = res.data;
            });
        } 

        const inputChanged = (item) => {
            isMainListLoading.value = true;
            emit('inputChanged', item)
            if (props.disableMultipleSelect) {
                showFiles.value = false;
                emit('showFileLibraryModal', false)
            }
        }

        watch(() => props.addedFiles, () => {
            setTimeout(() => {
                isMainListLoading.value = false;
            }, 400);
        });

        const updatedTableBody = computed(() => {
            console.log('props.addedFiles', props.addedFiles)
            let data = _.cloneDeep(files.value);
            if (searchString.value && searchString.value.length) {

                data = data
                    .filter(
                        i => (i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase()))
                            || (i.Description && i.Description.toLowerCase().match(searchString.value.toLowerCase()))
                    )
                
            }
            if (props.addedFiles && props.addedFiles.length) {
                data = data.map((file) => {
                    file.Active = props.addedFiles.includes(file.Id) ? 1 : 0;
                    return file;
                });

                return _.orderBy(data, ['Active'], ['desc']);
            } else {
                return data;
            }
        })

        //Add new file to FileLibrary

        const fileCreated = (file) => {
            showAddNewFileModal.value = false;
            files.value.push({
                'Id': file.Id,
                'Name': file.Name,
                'Description': file.Description,
                'Type': file.Type,
                'ExternalLink': file.ExternalLink,
                'Path': file.Path
            });

            inputChanged({
                item: file,
                value: true
            })
        };


        return {
            showFiles,
            searchString,
            showAddNewFileModal,
            files,
            actions,
            tableHead,
            fieldsToEdit,
            updatedTableBody,
            isMainListLoading,
            inputChanged,
            fileCreated,
            getSavedFileURLFromPath,
            showFilePreview,
            selectedFile
        }
    }
}
</script>

<style lang="scss">

.mas-glob-file-library{

    .select-upload-library-file.border{
        border-bottom: 1px dashed $mas-gunmetalBlack !important;
    }
    .select-upload-library-file{
        display: flex;
        div{
            display: inline-flex;
        }
        i{
            margin-left: 9px;
            cursor: pointer;
            font-size: 18px;
            color: $mas-traditionalBlue;
        }
        p{
            margin: 0;
            margin-bottom: 15px;
            min-width: 8rem;
            cursor: pointer;
        }
    }

}

.mas-glob-file-library-preview{
    .ips-xpanel__content{
        text-align: center;
    }
}

</style>