import { dynamicTranslation } from '@/helpers.js';
import { i18n } from '@/plugins/i18n/lang.js'

export const validateForm = (form) =>{
   let error = null;
   let translations = i18n.global.messages.value[i18n.global.locale.value];

   if(form.Name.length > 150){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['service_description']['fields']['name'],
         value: 150
      })
      return error;
   }

   return error;
}


export const validateCheckListForm = (form) =>{
   let error = null;
   let translations = i18n.global.messages.value[i18n.global.locale.value];

   if(form.Heading.length > 150){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['service_description']['fields']['heading'],
         value: 150
      })
      return error;
   }

   return error;
}