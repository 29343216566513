<template>
  <Carousel>
	 <Slide v-for="slide in images" :key="slide">
       <img :src="slide" class="carousel__item" />
    </Slide>

    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { onMounted, ref } from 'vue';

export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: ['images'],
  setup(props){
     return {
	   ...props
     }
  }
}
</script>
<style lang="scss">
.carousel__item {
  min-height: 200px;
  max-height: 400px;
  width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
}
.carousel__icon{
	fill: white ;
    transform: scale(2);
}

.carousel__viewport{
    border: 49px solid $mas-gunmetalBlack !important;
}
.carousel__track{
  margin: 0;
}

.carousel__slide {
  width: 636px;
}

</style>