<template>
    <div>
        <GlobXpanel :title="$t('dashboard.executed_maintenance_last_30_days')">
            <template v-if="data && data.length">
                <ChartBar 
                class="mas-mt-4"
                :xPanel="false"
                :axisX="'date'"
                :axisY="'value'"
                :data="data" 
                :color="'#3A7CB7'"
                />
            </template>
        </GlobXpanel>
    </div>
</template>

<script>


export default {
    props: ["data"],
    setup(props) {
    }
}
</script>
