<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="quickSelected ? 7 :12">
				<GlobXpanel :title="$t('administration.article.articles')">
					<button @click="showCreateArticleModal = true" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">{{$t('general.create_new')}}</button>
					<InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
					<GlobTable
						:maxHeight="50"
						:data="{
							head : articleListHead,
							body : filteredArticles
						}"
						:actions="actions"
					/>
				</GlobXpanel>
			</GlobCol>
			<GlobCol v-if="quickSelected" :medium="5">
				<GlobXpanel title="Snabbredigera">
					<div class="ips-admin-fieldgroup">
						<p class="section-heading">{{ $t('general.general') }}</p>
						<div class="ips-admin-quickedit">
							<label>
							{{$t('administration.article.fields.article_number')}}*
							<InputDynamic
								:fullWidth="true"
								styling="width: 100%; padding: .5rem;"
								:type="'text'"
								v-model="dataObject.ArticleNumber"
								:disabled="true"
							/>
							</label>
							<label>
							{{$t('administration.article.fields.name')}}*
							<InputDynamic
								:fullWidth="true"
								styling="width: 100%; padding: .5rem;"
								:type="'text'"
								v-model="dataObject.Name"
							/>
							</label>
							<button :disabled="!dataObject.Name" @click="save" class="button">
							{{$t('general.button.save')}}
							</button>
							<button @click="quickSelected = false; dataObject = {};" class="button button--orange">
							{{$t('general.button.cancel')}}
							</button>
						</div>
					</div>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>

<!-- Create article modal -->
<GlobModal :widthInPx="1000" :title="$t('administration.article.create')" @close="showCreateArticleModal = false" :show="showCreateArticleModal" :isCRUD="true" v-if="showCreateArticleModal">
	<CreateArticle @articleCreated="(article) => articleCreated(article)" />
</GlobModal>
<!-- Edit article modal -->
<GlobModal :widthInPx="1000" :title="selectedArticle.Name ? selectedArticle.Name : selectedArticle.ArticleNumber" @close="selectedArticle = null" :show="selectedArticle && selectedArticle.Id && !hideEditModal" v-if="selectedArticle && selectedArticle.Id" :isCRUD="true">
	<EditArticle 
		:articleId="selectedArticle.Id" 
		:loading="articleModalLoading"
		:reloadData="reloadArticleEdit"
		@articleUpdated="(article) => articleUpdated(article)"
		@enlargeQRCodeActive = "(value) => enlargeQRCodeActive = value"
		@showArticleDeleteConfirmModal="showArticleDeleteConfirmModal = true"/>
</GlobModal>
	<!-- Delete confirm modal -->
	<GlobConsequence :show="showArticleDeleteConfirmModal" 
		@close="showArticleDeleteConfirmModal=false"  
		@confirm = "deleteArtcile()"
		:title="$t('administration.article.delete_article')" 
		:message="$t('administration.article.delete_confirm')"  />
</template>

<script>
import { onMounted, computed, watch, ref, watchEffect } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import CreateArticle from "@/views/crud/Article/Create.vue";
import EditArticle from "@/views/crud/Article/Edit.vue";

export default {
components: {
	Navbar,
	CreateArticle,
	EditArticle
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.articles);
    
	const selectedArticle = ref(null);

	const showCreateArticleModal = ref(false);
	const showArticleDeleteConfirmModal = ref(false)
	const reloadArticleEdit = ref(false);
	const articleModalLoading = ref(false);
	const hideEditModal = computed(() =>{
		return showArticleDeleteConfirmModal.value || enlargeQRCodeActive.value ? true : false;
	})


	const file = ref(null);
	 const fileInput = ref(null);
	const isLoading = ref(false);
	 const notifications = useNotifications();
	const enlargeQRCodeActive = ref(false);
	const showArchived = ref(false);
	const loaded = ref(false);
	const searchString = ref('');
	const articleList  = ref([]);
	const articleListHead = ref([
			{
				Alias: "Name",
				Value: computed(() => tm('administration')['article']['fields']['name'])
			},
			{
				Alias: "ArticleNumber",
				Value: computed(() => tm('administration')['article']['fields']['article_number'])
			},

		]);
	
	const actions = computed(() => { return [
		{
			name: tm('general')['button']['edit'],
			color: "green",
				action: (obj) => {
					selectedArticle.value = obj;
				}
		},
		{
			name: tm('general')['button']['quick_edit'],
			color: "blue",
			action: (obj) => {
				setEditor(obj);
			}
		},
		];
	});
	
	 onMounted(async() => {
          loadArticles();
	 });

	const loadArticles = async() => {
		isLoading.value = true;
		endpoint.value.get().then(function(res){
           articleList.value = res.data;
		   isLoading.value = false;
		})
	}

	const filteredArticles = computed(() => {
		return searchString.value.length > 0 ? articleList.value
				.filter(
					i => (i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase()))
					||  (i.ArticleNumber && i.ArticleNumber.toLowerCase().match(searchString.value.toLowerCase()))
					) : articleList.value;
	})

	const dataObject = ref({});
	const quickSelected = ref(false);
	const setEditor = (obj) => {
		dataObject.value.Id = obj.Id;
		dataObject.value.Name = obj.Name;
		dataObject.value.ArticleNumber = obj.ArticleNumber;
		quickSelected.value = true;
    };

	const img = computed(() => (file.value ? URL.createObjectURL(file.value) : dataObject.value.ImagePath));

	 const formData = new FormData();
	 const addImage = (files) => {
		file.value = files[0];
		formData.append("image", files[0]);
    };

	const save = () => {
		endpoint.value.update(dataObject.value.Id, dataObject.value).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['article']['update_success']
					},
					"success",
					false
			);
			articleUpdated(dataObject.value);
			dataObject.value = {};
			quickSelected.value = false;
			
		});
	};

	const articleCreated = (article) => {
		showCreateArticleModal.value = false;  
		selectedArticle.value = article;
		loadArticles();
	}

	const articleUpdated = (article) => {
		let articleIndex = articleList.value.findIndex((i) => i.Id == article.Id)
		articleList.value[articleIndex].ArticleNumber = article.ArticleNumber;
		articleList.value[articleIndex].Name = article.Name;
	}

	const deleteArtcile = () => {
		showArticleDeleteConfirmModal.value = false;
		articleModalLoading.value = !articleModalLoading.value;
		endpoint.value.delete(selectedArticle.value.Id).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['article']['delete_success']
					},
					"success",
					false
			);
			let articleIndex = articleList.value.findIndex((i) => i.Id == selectedArticle.value.Id)
			delete articleList.value[articleIndex];
			selectedArticle.value = null;
		});
	}


	const getArticle = (articleId) => {
		endpoint.value.getById(articleId).then(function(res){
			articleUpdated(res.data)
			isLoading.value = false;
		});
	}


    return {
		loadArticles,
		filteredArticles,
		endpoint,
		articleList,
		articleListHead,
		searchString,
		actions,
		routes,
		loaded,
		showArchived,
		quickSelected,
		dataObject,
		save,
		isLoading,
		selectedArticle,
		hideEditModal,
		showCreateArticleModal,
		articleCreated,
		//archive
		reloadArticleEdit,
		articleModalLoading,
		//delete
		showArticleDeleteConfirmModal,
		deleteArtcile,
		articleUpdated,
		enlargeQRCodeActive
    };
  }
};
</script>