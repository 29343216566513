<template>
	<div class="ma-filter-wrapper">
		<i @click="toggleFilter" :class="`fas fa-${showFilter ? 'eye-slash' : 'filter'}`" title="Dölj filter"></i>
		<div class="ma-filter" v-show="showFilter">
				<GlobSpinner :isLoading="loading" :isTopRight="true"/>
				<GlobRow v-if="false">
					<GlobCol v-if="showHistoryToggle" :medium="1">
						<label>Historik</label>
						<br/>
						<GlobToggle
							v-model="historyToogle"
							:toggles="{on: 'På', off: 'Av'}"
							:width="90"
							@input="fetchFilters"
						/>
					</GlobCol>
					<GlobCol v-if="showOrdernumberToogle" :medium="1">
						<label>Typ</label>
						<br/>
						<GlobToggle
							v-model="ordernumberToogle"
							:toggles="{on: 'Order', off: 'Orderrad'}"
							:width="90"
							@input="fetchFilters"
						/>
					</GlobCol>
					<GlobCol v-if="assemblyToggle" :medium="1">
						<label>Typ</label>
						<br/>
						<GlobToggle
							v-model="articleToggle"
							:toggles="{on: 'Assembly', off: 'Part'}"
							:width="110"
							@input="fetchFilters"
						/>
					</GlobCol>
					<GlobCol v-if="showDeductedToggle" :medium="1" style="margin-bottom: .3rem; min-width: 300px;" :style="inputMinWidth ? 'min-width:'+inputMinWidth : ''">
						<label>Typ</label>
						<br/>
						<GlobToggle
							v-model="deductedToggle"
							:toggles="{off: 'visa alla', on: 'Endast uttag'}"
							:width="110"
							@input="fetchFilters"
						/>
					</GlobCol>
					<GlobCol v-if="showArchiveToggle" :medium="1">
						<label> {{languageSupport || languageSupportCustomField ? $t('general.button.view_archived') : 'Visa arkiverade'}}</label>
						<br/>
						<GlobToggle
							v-model="archiveToggle"
							:toggles="{on: languageSupport || languageSupportCustomField ? $t('general.Yes') : 'Ja', off: languageSupport || languageSupportCustomField ? $t('general.No') : 'Nej'}"
							:width="110"
							@input="fetchFilters"
						/>
					</GlobCol>
					<GlobCol v-if="dateToggle" :medium="1">
						<label>Jämför</label>
						<br/>
						<GlobToggle
							v-model="compareToggle"
							:toggles="{on: 'Revision', off: 'Datum'}"
							:width="110"
						/>
					</GlobCol>
					<GlobCol v-if="materialPlanToggle" :medium="3">
						<label>Inkludera parter i andra planer</label>
						<br/>
						<GlobToggle
							v-model="materialPlanToggleValue"
							:toggles="{on: 'Nej', off: 'Ja'}"
							:width="80"
						/>
					</GlobCol>
					<GlobCol v-if="materialPlanSelectToggle" :medium="2">
						<label>Materialplanerad</label>
						<br/>
						<GlobSelectInput
							v-model="materialPlanSelect"
							:items="[
								{
									value: 'NOT_PLANNED',
									name: 'Ej planerad',
								},
								{
									value: 'PLANNED_FROM_STOCK',
									name: 'Planerad från lager',
								},
								{
									value: 'PLANNED_FROM_MRP',
									name: 'Planerad från nettobehov',
								},
								{
									value: 'DELIVERY',
									name: 'Planerad från inköp',
								}
							]"
							:width="80"
						/>
					</GlobCol>
					<GlobCol v-if="!compareToggle && useDate" :medium="2" style="margin-bottom: .3rem; min-width: 300px;" :style="inputMinWidth ? 'min-width:'+inputMinWidth : ''">
						<label>{{languageSupport || languageSupportCustomField ? $t('general.button.date_range') : 'Datumspan'}}</label>
						<GlobDatePicker @setDates="getDates" @clear="setDates"/>
					</GlobCol>
					<GlobCol v-if="compareToggle" :medium="1">
						<label>Från revision</label>
						<model-select
						:options="revisions"
						v-model="oldRevision"
						placeholder="Revision"
					></model-select>
					</GlobCol>
					<GlobCol v-if="compareToggle" :medium="1">
						<label>Till revision</label>
						<model-select
						:options="revisions"
						v-model="currentRevision"
						placeholder="Revision"
					></model-select>
					</GlobCol>

					<GlobCol v-if="showChange" :medium="2">
						<label>Typ av ändring</label>
						<br/>
						<GlobSelectInput
							v-model="changeSelect"
							:items="[
								{
									value: 'Changed',
									name: 'Förändrad',
								},
								{
									value: 'Added',
									name: 'Tillagd',
								},
								{
									value: 'Deleted',
									name: 'Borttagen',
								}
							]"
							:width="80"
						/>
					</GlobCol>


				</GlobRow>
				<GlobRow>
					<GlobCol :medium="12">
						<GlobRow>
							<GlobCol v-for="(filter, index) in rawFilters" :key="filter.key + index + timeStamp" :medium="2" :small="12" style="margin-bottom: .3rem; min-width: 300px;" :style="inputMinWidth ? 'min-width:'+inputMinWidth : ''">
								<label style="width: 100%;">
									{{languageSupport ? $t('general.fields.'+filter.label) : (languageSupportCustomField ? $t(filter.label) : filter.label)}}
									<template v-if="filter.type == 'toggle'">
										<br/>
										<InputSwitch
											v-model="availableFilters[filter.key]"
											:disabled="availableFilters[filter.key] || loading"
											:toggles="{on: $t('general.yes'), off: $t('general.no')}"
											:width="80"
											@input="(val) => fetchFilters(val, filter.key)"
										/>
									</template>	
									<template v-else>
										<InputDynamic
											class="ips-dyamic-type-input-full-width"
											style="width: 100%;"
											:fullWidth="true"
											:isFilter="true"
											:type="getFilterInputType(filter)"
											:data="filter.values"
											:ignoreValueWatch="false"
											:placeholder="`${languageSupport || languageSupportCustomField ? $t('general.search') : 'Sök '+filter.label}`"
											:disabled="availableFiltersCheck[filter.key] || loading"
											v-model="availableFilters[filter.key]"
											@clear="fetchFilters"
											@input="(val) => fetchFilters(val, filter.key)"
										/>
									</template>	
								</label>
								<label v-if="allowNullSearch"> 
								<input type="checkbox" v-model="availableFiltersCheck[filter.key]" value="true">
								Utan {{filter.label}}
								</label>
							</GlobCol>
							<GlobCol :medium="2">
								<div style="min-width: 200px; margin-top: 24px;">
									<button class="button button--small filter" :disabled="loading || disableFilterButton" @click="sendFilter">{{ languageSupport || languageSupportCustomField ? $t('general.button.filter') : 'Filtrera' }}</button>
									<button class="button button--small filter-reset" @click="resetFilters">{{ languageSupport || languageSupportCustomField ? $t('general.button.reset') : 'Återställ' }}</button>
								</div>

							</GlobCol>
						</GlobRow>
					</GlobCol>
				</GlobRow>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { ref, reactive, watch, computed, onMounted, watchEffect, inject, toRaw } from "vue";
import axios from "axios";
export default {
	props: {
		enable: {
			type: Boolean,
			default: true,
			required: false
		},
		endpoint: {
			type: String,
			required: true
		},
		dateToggle: {
			type: Boolean,
			required: false,
			default: true
		},
		multiselect: {
			type: Boolean,
			required: false,
			default: false
		},
		showChange: {
			type: Boolean,
			required: false,
			default: false,
		},
		useDate: {
			type: Boolean,
			required: false,
			default: true
		},
		defaultDate: {
			type: Object,
			required: false
		},
		showArchiveToggle: {
			type: Boolean,
			required: false,
			default: false
		},
		showOrdernumberToogle: {
			type: Boolean,
			required: false,
			default: false
		},
		assemblyToggle: {
			type: Boolean,
			required: false,
			default: true
		},
		materialPlanSelectToggle: {
			type: Boolean,
			required: false,
			default: false,
		},
		materialPlanToggle: {
			type: Boolean,
			required: false,
			default: false,
		},
		extraFilters: {
			type: Array,
			required: false
		},
		template: {
			type: Object,
			required: false
		},
		viewName: {
			type: String,
			required: false
		},
		allowNullSearch: {
			type: Boolean,
			required: false
		},
		enableByDefault: {
			required: false,
			default: true
		},
		showDeductedToggle: {
			type: Boolean,
			required: false,
			default: false
		},
		showHistoryToggle: {
			type: Boolean,
			required: false,
			default: false
		},
		preselectedValues: {
			type: Array,
			required: false
		},
		disableFilterButton: {
			 type: Boolean,
			 required: false,
			 default: false
		},
		languageSupport: {
			 type: Boolean,
			 required: false,
			 default: false
		},
		languageSupportCustomField: {
			 type: Boolean,
			 required: false,
			 default: false
		},
		triggerFilter: {
			type: Boolean,
		},
		inputMinWidth: {
			default: null,
		},
		defaultValues: {
			type: Object,
			default: null
		}
		
	},
	setup(props, { emit }) {
		const showFilter = ref(props.enableByDefault);
		const requestSkeleton = reactive({
				fromDate: '',
				toDate: '',
				page: 0,
		})
		const fields = ref({})
		const rawFilters = ref([])
		const archiveToggle = ref(false)
		const availableFilters = ref({})
		const availableFiltersCheck = ref({})
		const articleToggle = ref(false)
		const ordernumberToogle = ref(true)
		const deductedToggle = ref(true)
		const compareToggle = ref(true)
		const materialPlanToggleValue = ref(false)
		const materialPlanSelect = ref(null)
		const timeStamp = ref(new Date().getTime() / 1000)
		const requestType = ref('orderdetail')
		const compareType = ref('date')
		const currentRevision = ref('')
		const changeSelect = ref(null)
		const oldRevision = ref('')
		const loading = ref(false)
		const revisions = ref([])
		const project = ref('')
		const historyToogle = ref(false)
		const toggleFilterModels = reactive({
				'IncludeDelivered': true,
				'ShowAllArticles': true
		})
		const toggleFilterModelInitData = ref({})

		const testModel = ref(null);

		//Methods

		onMounted(() => {
			init();
			toggleFilterModelInitData.value = _.cloneDeep(toggleFilterModels);
		});

		const toggleFilterUpdated = (val, key) => {
			availableFilters[key] = [val ? '1' : '0'];
		};

		const toggleFilter = () => {
			showFilter.value = !showFilter.value;
		};

		const init = async () => {
			setDates();
			await fetchFilters();
		};

		const getFilterInputType = (filter) => {
			if(filter.type == 'date_less_than'){
				return 'date';
			}else if(filter.type){
				return filter.type;
			}

			return props.multiselect ? 'multiselect' : 'virtualselect';
		};

		const sendFilter = (isReset = false) => {
			emit('apply', {
				body: availableFilters.value,
				filters: toRaw(availableFilters.value),
				toggles: {
					orderToggle: ordernumberToogle,
					assembly: articleToggle,
					archive: archiveToggle,
					isDeducted: deductedToggle,
					history: historyToogle,
					
				},
				isReset: isReset === true ? true : false,
			})
		};	

		const applyTemplate = async(values) => {
			if (values) {
				Object.keys(values).map(i => {
					[i] = values[i]
				})
			}
			await fetchFilters();
			sendFilter();
		};

		const getCategoryName = (key) => {
			return fields[key]
		};

		const setDates = () => {
			requestSkeleton.fromDate = props.defaultDate ? props.defaultDate.fromDate : null;
			requestSkeleton.toDate = props.defaultDate ? props.defaultDate.toDate : null;
		};

		const getDates = (dates) => {
			requestSkeleton.fromDate = dates.startDate;
			requestSkeleton.toDate = dates.endDate;
			fetchFilters();
		};

		const clearRevisions = (data) => {
			revisionRequest.currentRevision = ''
			revisionRequest.oldRevision = ''
			fetchFilters()
		};

		const createObjectFromFilters = (data) => {
			return data
			.reduce((acc, curr) => {
				acc[curr.key] = curr.currentValue || '';
				return acc
			}, {})
		};

		const createObjectFromFields = (data) => {
			return data
			.reduce((acc, curr) => {
				acc[curr.Alias] = curr.Value
				return acc
			}, {})
		};

		const fetchFilters = async(values, key) => {
			if (loading.value) {
				return true;
			}
			loading.value = true;
			let tempObject = [];
			 Object.keys(computedRequest.value).filter(item => {
				if(typeof computedRequest.value[item] == 'object'){
					if(computedRequest.value[item] && Object.keys(computedRequest.value[item]).length){
						tempObject[item] = computedRequest.value[item]
					}
				}else{
					tempObject[item] = computedRequest.value[item]
				}
			})
			await axios.get(props.endpoint, { params : {...tempObject}, limit: 1, useFull: 1})
			.then(res => {
				
				let parsed = res.data; 
				rawFilters.value = [];
				parsed.filters.map((filter) => {
					rawFilters.value.push(filter);
				})
				availableFilters.value = createObjectFromFilters(parsed.filters)
				fields.value = createObjectFromFields(parsed.fields)
				revisions.value = parsed.currentMaxRevision ? Array.from(Array(parsed.currentMaxRevision+1).keys()).map(i => { return {value: i,  text: i} }) : []
				emit('updated', {
					body: computedRequest,
					filters: rawFilters,
					toggles: {
						orderToggle: ordernumberToogle,
						assembly: articleToggle,
						archive: archiveToggle,
						history: historyToogle,
					}
				})
				loading.value = false;
				return true;
			},
			() => {
				loading.value = false;
			})
		};

		const resetFilters = async() => {
			computedRequest.value = {
				compareToggle: false,
				availableFilters: {},
				oldRevision : '',
				currentRevision: '',
				changeSelect: null,
				materialPlanSelect: null,
				materialPlanToggleValue: false,
				articleToggle: true,
				ordernumberToogle:true,
				archiveToggle: false,
				historyToogle: false,
			}
			availableFilters.value = {};
			toggleFilterModels.value = toggleFilterModelInitData;
			await fetchFilters();
			timeStamp.value = new Date().getTime() / 1000;
			sendFilter(true);
		};

		const getFilters = async() => {
			const extra = props.extraFilters;
			const filterObject = Object.keys(availableFilters)
				.filter(item => {
					return (availableFilters[item] && availableFilters[item].length > 0) || availableFiltersCheck[item]
				})
				.reduce((acc, curr) => {
					acc[curr] = !availableFiltersCheck[curr] ? availableFilters[curr] : null
					return acc
				}, {})
				extra && extra.forEach(item => {
					filterObject[Object.entries(item)[0][0]] = Object.entries(item)[0][1]
				})
				if (changeSelect.value && changeSelect.value !== 'Välj') {
					filterObject[changeSelect.value] = 1;
				}
			return filterObject;
		};

		//Compuetd
		
		const computedRequest = computed({
				get() {
					return { filters: Object.keys(availableFilters).length ? availableFilters.value : null}
				},
				set(newValue) {
					compareToggle.value = newValue.compareToggle
					changeSelect.value = newValue.changeSelect;
				}
		})

		const filterValidation = computed(() =>{
			return compareToggle.value && !checkInput.value && !props.enable
		});

		const checkInput = computed(() =>{
			return oldRevision.value !== '' && currentRevision.value !== ''
		});

		watch(() => props.triggerFilter, () => {
			fetchFilters();
		})

		return {
			// Param
			testModel,
			showFilter,
			requestSkeleton,
			fields,
			rawFilters,
			archiveToggle,
			availableFilters,
			availableFiltersCheck,
			articleToggle,
			ordernumberToogle,
			deductedToggle,
			compareToggle,
			materialPlanToggleValue,
			materialPlanSelect,
			timeStamp,
			requestType,
			compareType,
			currentRevision,
			changeSelect,
			oldRevision,
			loading,
			revisions,
			project,
			historyToogle,
			toggleFilterModels,
			toggleFilterModelInitData,
			...props,
			computedRequest,
			filterValidation,
			checkInput,

			//Methods
			toggleFilterUpdated,
			toggleFilter,
			init,
			setDates,
			getFilterInputType,
			sendFilter,
			applyTemplate,
			getCategoryName,
			getDates,
			clearRevisions,
			createObjectFromFilters,
			fetchFilters,
			createObjectFromFields,
			getFilters,
			resetFilters

	}

	},

}
</script>

<style lang="scss" scoped>
	.button {
		margin-bottom: 1.5rem;
	}
	.ma-filter-wrapper {
		padding: 1rem 0;
		position: relative;
		> i {
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.ma-filter{
		margin-top: 1rem;
		label{
			font-family: 'Inter';
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
			color: $ma-darkBluishGray;
			font-size: 16px !important;
		}
		background-color: #EBF2F8;
		padding-left: 39px;
		border-radius: 10px;
		padding-top: 30px;

		button.filter{
			background: $ma-brightOrange !important;
			border-radius: 6px !important;
			font-size: 16px !important;
			font-weight: 500;
		}

		button.filter-reset{
			background: $ma-mediumBlue !important;
			border-radius: 6px !important;
			font-size: 16px !important;
			font-weight: 500;
		}
	}
</style>

