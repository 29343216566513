<template>
  <div class="ips-navbar" @mouseleave="closeNav" @click="openNav" :class="activeNav ? 'active' : ''">
    <div class="ips-navbar-items">
      <div
          class="ips-navbar-items__item logo">
        <img class="app-logo" src="@/assets/logo/logo.svg" @click="$router.push({'name': 'Home'})"/>
      </div>
      <div
        class="ips-navbar-items__item"
        :class="
          `${isExpanded(item) ? 'ips-navbar-items__item--active' : ''} ${
            isCurrent(item) || hasActiveChild(item) ? 'ips-navbar-items__item--active' : ''
          }`
        "
        @click="handleClick(item, $event)"
        v-for="(item, index) in filteredMenu"
        :key="`menu-item-${item.id}`"
      >
         <hr class="item-break" v-if="index == 0">
        <div class="ips-navbar-items__item-parent">
          <div class="ips-navbar-items__item-icon">
            
            <span><i :class="`fas fa-${item.nav.icon}`"></i> {{ $t(item.nav.name) }}</span>
          </div>
          <div class="ips-navbar-items__item-content">
            {{ $t(item.nav.name) }}
          </div>
        </div>
        <div
          class="ips-navbar-items__item-children"
          :class="`${isExpanded(item) ? 'ips-navbar-items__item-children--active' : ''}`"
          :style="getChildrenStyles(item)"
        >
          <div
            class="ips-navbar-items__item-child"
            :class="`${isCurrent(child) ? 'ips-navbar-items__item-child--current' : ''}`"
            v-for="child in item.children"
            :key="`menu-child-${child.id}`"
          >
            <router-link :to="{ path: child.path }">{{$t(child.nav.name)}}</router-link>
          </div>
        </div>
        <hr class="item-break">
      </div>
    </div>
    <GlobModal :show="showInfo" :width="10" @close="showInfo = false">
      <div class="ips-info" style="text-align: center;">
        <img style="width: 70%;" class="mas-logo" src="@/assets/logo/logo.svg" />
        <div class="ips-info__content">
          <p>Support: <a href="mailto:helpdesk@invens.se">helpdesk@invens.se</a></p>
          <p>Inquires: <a href="mailto:hello@invens.se">hello@invens.se</a></p>
        </div>
        <p style="font-size: .7rem;">Keep It Smart &copy; Invens AB 2020</p>
      </div>
    </GlobModal>
	<p class="all-rights-reserved">© All rights reserved</p>
  </div>
</template>

<script>
import menuItems from "./menuItems";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import {routes, createNav } from "@/router";
export default {
  setup() {
    const router = useRouter();
    const expanded = ref([]);
    const handleClick = (item, e) => {
      if (item.children && item.children.length > 0) {
        if (isExpanded(item)) {
          expanded.value.splice(expanded.value.indexOf(item.id), 1);
        } else {
          expanded.value = [];
          expanded.value.push(item.id);
        }
      } else {
				e.stopPropagation();
        router.push(item.path);
      }
    };
    const isExpanded = (item) => {
      return expanded.value.includes(item.id);
    };
    const getChildrenStyles = (item) => {
      return isExpanded(item) ? `max-height: ${item.children.length * 50}px` : "";
		};
		
		const activeNav = ref(false);
    const closeNav = () => {
    //   	expanded.value = [];
		// activeNav.value = false;
    };
    const openNav = () => {
      activeNav.value = true;
    };

    const path = computed(() => router.currentRoute.value.path);
    const isCurrent = (item) => {
      return item.path && item.path == path.value;
    };
    const hasActiveChild = (item) => {
      return router.currentRoute.value.matched.map((i) => i.path).includes(item.path);
    };

    const showInfo = ref(false);
    const toggleInfo = () => {
      showInfo.value = !showInfo.value;
    };

    const filteredMenu = createNav(routes);
    return {
      filteredMenu,
      handleClick,
      isExpanded,
      getChildrenStyles,
      expanded,
      closeNav,
      isCurrent,
      hasActiveChild,
      showInfo,
      toggleInfo,
			openNav,
			activeNav
    };
  }
};
</script>

<style lang="scss" scoped>
.ips-info {
  &__content {
    margin: 1rem 0;
    p {
      margin: 0.5rem 0;
    }
  }
}

.ips-navbar {
  // New version
  height: 100vh;
  width: 120px;
  min-width: 120px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  background: $mas-onyx;
  z-index: 10;

  // Old version
  overflow: hidden;
  overflow-y: auto;
  position: sticky;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  @include scrollbar(4px);
  &.active {
    min-width: 140px;
  }
}

.ips-navbar-items {
  &__item {
    &-parent {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      display: flex;
      align-items: center;
    }
    &-content {
      transition: opacity 0.2s ease-in;
      color: white;
      font-size: 0.7rem;
      max-width: 0;
      opacity: 0;
    }
    &-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 95px;
      i {
        transition: all 0.3s ease-in-out;
        font-size: 1.3rem;
        color: $mas-philippineSilver;
      }
      span {
        transition: all 0.3s ease-in-out;
        font-size: 12px;
        display: block;
        color: $mas-philippineSilver;
        margin-top: 5px;
      }
    }
    &-children {
      max-height: 0;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
    }
    &-child {
      transition: all 0.2s ease-in-out;
      position: relative;
      display: flex;
      justify-content: center;
      font-size: 12px;
      display: contents;
      a:hover {
        color: white;
      }
      &:hover {
        background-color: white;
      }
      &--current {
        border-right: 4px solid $mas-green;
        background-color: darken($mas-green, 10%);
      }
    }
    &--current &-parent {
      border-left: 4px solid $mas-green;
      i,
      span {
        margin-left: -4px;
        color: $mas-white;
      }
    }
    &--logo {
      height: 40px;
      border-bottom: 2px solid darken($mas-green, 10%);
      img {
        height: 25px;
        width: auto;
        transition: all 0.3s ease-in-out;
        filter: grayscale(100%) brightness(2);
      }
      &:hover img {
        filter: grayscale(0%) brightness(1);
      }
    }
    &--logo &-icon {
      padding: 0.5rem 0;
    }
  }
}

.ips-navbar-items__item--logo {
  height: unset;
  border-bottom: none;
}

.ips-navbar-items__item.logo {
    margin-bottom: 5rem;
}

.ips-navbar-items__item-icon {
  margin: auto;
  img {
    height: 57.34323501586914px;
    width: 30px;
  }
}

.ips-navbar-items__item--current {
  .ips-navbar-items__item-parent {
    border-left: unset;
    background-color: unset;
  }
}

.ips-navbar-items__item-parent:hover {
  .ips-navbar-items__item-icon i,
  .ips-navbar-items__item-icon span {
    color: $mas-white;
  }
}

.router-link-active {
  color: white !important;
}

// New design

.ips-navbar {
  background: linear-gradient(217.39deg, #002241 21.66%, #19203D 79.39%);
  width: 250px;

  img.app-logo {
    width: 100px;
    height: 114.68646240234375px;
    cursor: pointer;
    margin-left: -18px;
  }

  .ips-navbar-items {
    margin-top: 50px;

    .ips-navbar-items__item {
      text-align: center;

      span {
        font-size: 19px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: $ma-lightGray;
        margin-left: -44px;
        i {
          font-size: 20.89999771118164px;
          height: 22px;
          border: 1.5px;
          color: $ma-orange;
          margin-right: 8px;
        }
      }

      .item-break {
        width: 178px;
        border: 1px;
        opacity: 0.1;
        margin-left: 34px;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }

    .ips-navbar-items__item--active {
      .ips-navbar-items__item-parent {
        span {
          color: $ma-white;
        }
      }
    }

    .ips-navbar-items__item-icon {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 95px;
    }

    .ips-navbar-items__item--logo {
      margin-bottom: 4rem;
      .ips-navbar-items__item-icon {
        align-items: center !important;
      }
    }

    .ips-navbar-items__item-children {
      display: none;
      max-height: 150px;
      margin-top: 1rem;
      .ips-navbar-items__item-child {
        a {
          height: 40px;
          display: flex;
          color: #AEB2B8;
          font-weight: 400;
          text-decoration: none;
          justify-content: center;
          margin-top: 6px;
          justify-content: flex-start;
          margin-left: 66px;
          font-size: 17px;
        }
      }
    }
    .ips-navbar-items__item-children--active {
      display: table;
    }
  }
}

.all-rights-reserved{
	color: $ma-lightGray;
	font-size: 16px;
	position: absolute;
	bottom: 7px;
	left: 16%;
}

</style>
