<template>
      <div>
	  		<div v-show="!hideDropZoneOnFileUpload || (hideDropZoneOnFileUpload && uploadedFiles.length == 0)">
				<form enctype="multipart/form-data" novalidate>
						<div class="dropbox" :style="{'height': height+'px', 'line-height': height+'px'}">
							<input type="file" :multiple="enableMultipleUpload ? true : false" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
								:accept="accept" class="input-file">
								<p v-if="!text">
									<i class="far fa-folder"></i>{{$t('general.file_drag_drop')}}
								</p>
								<p v-else>
									{{text}}
								</p>
						</div>
				</form>
			</div>
			<div>
                <template  v-for="(uploadedDoc, index) in uploadedFiles">
				<div class="attahced-files-v1" :key="'uploaded-file-'+index" v-if="!uploadedDoc.isRemoved">
				 <span><i class="far fa-file-alt"></i></span>
				 <a  :href="uploadedDoc.frontEndURL" target="_blank" download>{{uploadedDoc.name.length > 20 ? uploadedDoc.name.substring(0,20)+".." : uploadedDoc.name}}</a>
				 <span @click="removeFile(index, uploadedDoc.uniueFileId)" class="remove-icon"><i class="fas fa-times"></i></span>
				</div>
                </template>
			</div>
			<p class="text-danger mas-mt-3" v-if="uploadError">{{uploadError}}</p>
      </div>
</template>

<!-- Javascript -->

<script>

  export default {
    props:{
        enableMultipleUpload: {
            type: Boolean,
            default: false,
            required: false
        },
		height: {
			type: Number,
            default: 160,
            required: false
		},
		text: {
			type: String,
			required: false
		},
		accept: {
			type: String,
			required: false,
			default: '*'
		},
		addedFiles: {
			type: Array,
			required: false,
		},
		hideDropZoneOnFileUpload : {
			type: Boolean,
			required: false,
		}
    },
    data() {
      return {
        uploadedFiles: [],
		removedFiles: [],
        uploadError: null,
      }
    },
    methods: {
      reset() {
        this.uploadedFiles = [];
        this.uploadError = null;
		this.removedFiles = [];
		this.$emit('filesRemoved', this.removedFiles)
		this.presetFiles();
      },
      filesChange(fieldName, fileList) {
		this.uploadError = null;
		if(!this.enableMultipleUpload && this.uploadedFiles.length) {
			this.uploadError = this.$t('general.validation.only_upload_one_file');
			return false;
		}
		this.$emit('filesAdded', fileList)
		let newFiles = [];
        const self=this;
		  Object.keys(fileList).forEach(function (key) {
			const fileExtension = '.'+fileList[key].name.split('.').pop();
            if(self.accept == '*' || self.accept.split(',').includes(fileExtension)){
				fileList[key].uniueFileId = self._uid;
				fileList[key].frontEndURL = URL.createObjectURL(fileList[key]);
				self.uploadedFiles.push(fileList[key]);
				newFiles.push(fileList[key]);
			}
        });
      },
      removeFile(index, uniueFileId){
		this.uploadError = null;
		this.$emit('filesRemoved', this.uploadedFiles[index].id)
		this.uploadedFiles.splice(index, 1);
		this.$emit('fileList', this.uploadedFiles)
      },
	  presetFiles(){
		//Preselect the already uploaded files
		this.uploadedFiles = [];
		if(this.addedFiles && this.addedFiles.length){
			const self = this;
			this.addedFiles.forEach(function(file){
				const fileURL = file.URL;
				if(fileURL && fileURL.length){
					let fileObj = {};
					fileObj.id = file.Id;
					fileObj.isSaved = true;
					fileObj.name = fileURL.substring(fileURL.lastIndexOf('/')+1);
					fileObj.frontEndURL = fileURL;
					self.uploadedFiles.push(fileObj);
				}
			});
		}
	  }
    },
    mounted() {
		if(this.addedFiles){
			this.presetFiles()
		}
    },
	watch: {
		addedFiles : {
			handler: function() {
                this.presetFiles()
            },
            deep: true
		}
	}
  }

</script>

<!-- SASS styling -->
<style lang="scss" scoped>
  .dropbox {
    background-color: $ma-lightPaleBlueGray;
	border: 1px dashed $ma-lightBluishGray;
	border-radius: 6px;;
    outline-offset: -10px;
    position: relative;
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    font-weight: bold;
    vertical-align: middle;
    height: 160px;
    line-height: 126px;
	i{
		position: absolute;
		font-size: 31px;
		margin-right: 9px;
		opacity: .4;
		left: -9%;
		top: -26%;
	}
  }
  
  .input-file {
	opacity: 0; 
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    left: 0;
  }
  
  .dropbox:hover {
    background: #1ABC9C;
    color: white;
  }
  
  .dropbox p {
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
	font-size: 19px;
    font-weight: 400;
	position: relative;
	margin-left: 36px;
  }

  .attachment{
      width: unset !important;
      max-width: 286px;
      a{
        margin-right: 2rem;
      }
  }
  .attahced-files-v1{
		width: fit-content;
		display: inline-block;
		border: 1px solid #8bb8f4;
		margin-right: 12px;
		margin-top: 13px;
		cursor: pointer;
		border-radius: 4px;
		color: black;
		background-color: #e0ecfc;
		font-weight: 500;
		padding: 8px;

	  i{
		  font-size: 16px;
	  }

	  	a{
			margin: 0;
			align-items: center;
			padding: 1px;
			padding-left: 6px;
			cursor: pointer;
		}

		.remove-icon{
			font-size: 12px;
    		margin-left: 4px;
		}
  }

  .attahced-files-v1:hover{
	  	a{
			color: #15c !important;
			cursor: pointer;
		}
  }
</style>