<template>
  <div style="max-width: 99%">
    <Navbar :items="routes" />
    <GlobXpanel :title="$t('administration.administer')" v-if="$route.name == 'Admin'">
      <div class="mas-admin-cruds">
        <router-link
          :to="`/admin/view/${crud.slug}`"
          class="mas-admin-cruds__item"
          v-for="crud in filteredCruds"
          :key="'crud-' + crud.key"
        > 	
		<span> <i :class="`fas fa-${crud.config.icon}`"></i></span>
		<p>{{ $t('administration.menus.' + crud.slug) }}</p>
        </router-link>
      </div>
    </GlobXpanel>
    <MainView/>
  </div>
</template>

<script>
import { contentTypes, routes, cruds } from "./config";
import Navbar from "./Navbar.vue";
import MainView from "./MainView.vue";
import userGroups from './config/userGroups'
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
		Navbar,
		MainView
	},
	setup() {
		const store = useStore();
		const user = computed(() => store.state.USER);
    	const userRole = store.getters.GET_USER.UserRole;
		const filteredCruds = computed(() => cruds.filter(i => {

			if(i.access && !i.access.includes(userRole)){
				return false;
			}

			if(i.hide){
				return false;
			}
			if(i.isCustom){
				return true;
			}
			if (userGroups[user.value.UserGroup]) {
				if (userGroups[user.value.UserGroup] === '*') {
					return true
				} else {
					return userGroups[user.value.UserGroup].includes(i.slug);
				}
			} else {
				return false
			}
		}))
		return {
      types: contentTypes,
      routes,
			cruds,
			filteredCruds
		}
	},
};
</script>

<style lang="scss">
.mas-admin-cruds {
	display: flex;
	flex-flow: row wrap;
	margin-left: .5rem;
	margin-bottom: 1rem;
  &__item {
	position:relative;
	width: 303px;
    height: 160px;
	margin-right: 1rem;
	margin-top: 1rem;
   	cursor: pointer;
    border-radius: 15px;
	text-decoration: none !important;
	background: linear-gradient(105.57deg, #FF8E3D 2.84%, #F36748 110.41%);
	padding-left: 50px;
    padding-top: 36px;
    
	span {
		i{
			font-size: 50px;
    		color: $ma-white;
		}
	}
	p{
		margin: 0;
   		font-size: 22px;
		color: $ma-white;
		margin-top: 8px;
	}
  }
  &__item:nth-child(1) {
	background: linear-gradient(105.57deg, #FF8E3D 2.84%, #F36748 110.41%);
  }
  &__item:nth-child(2) {
	background: linear-gradient(105.82deg, #90DD54 -5.34%, #3BB948 106.41%);
  }
  &__item:nth-child(3) {
	background: linear-gradient(105.57deg, #FFD43D 2.84%, #F39A48 110.41%);
  }
  &__item:nth-child(4) {
	background: linear-gradient(101.74deg, #1AE0B1 7.55%, #1AC2CD 94.96%);
  }
  &__item:nth-child(5) {
	background: linear-gradient(106.06deg, #9333FF 5.08%, #6633FF 103.46%);
  }
  &__item:nth-child(6) {
	background: linear-gradient(106.06deg, #FF5733 5.08%, #FF3357 103.46%);
  }
  &__item:nth-child(7) {
	background: linear-gradient(106.06deg, #FF33A1 5.08%, #FF33E8 103.46%);
  }
  &__item:nth-child(8) {
	background: linear-gradient(106.06deg, #3F3FCC 5.08%, #645EFF 103.46%);
  }
  &__item:nth-child(9) {
	background: linear-gradient(106.06deg, #36CFF0 5.08%, #4DA1EF 103.46%);
  }
}
</style>
