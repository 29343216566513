import { dynamicTranslation } from '@/helpers.js';
import { i18n } from '@/plugins/i18n/lang.js'

export const validateForm = (form) =>{
   let error = null;
   let translations = i18n.global.messages.value[i18n.global.locale.value];

   if(form.Name.length > 100){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['supplier']['fields']['name'],
         value: 100
      })
      return error;
   }

   if(form.Address && form.Address.length > 100){
        error = dynamicTranslation(translations['general']['errors']['max_length'], {
        attribute: translations['administration']['supplier']['fields']['address'],
        value: 100
        })
        return error;
    }

    if(form.Postal && form.Postal.length > 20){
        error = dynamicTranslation(translations['general']['errors']['max_length'], {
        attribute: translations['administration']['supplier']['fields']['postal'],
        value: 20
        })
        return error;
    }

    if(form.City && form.City.length > 100){
        error = dynamicTranslation(translations['general']['errors']['max_length'], {
        attribute: translations['administration']['supplier']['fields']['city'],
        value: 100
        })
        return error;
    }

    if(form.ContactName && form.ContactName.length > 100){
        error = dynamicTranslation(translations['general']['errors']['max_length'], {
        attribute: translations['administration']['supplier']['fields']['contact_person'],
        value: 100
        })
        return error;
    }

    if(form.ContactPhone && form.ContactPhone.length > 100){
        error = dynamicTranslation(translations['general']['errors']['max_length'], {
        attribute: translations['administration']['supplier']['fields']['phone'],
        value: 100
        })
        return error;
    }

    if(form.ContactEmail && form.ContactEmail.length > 100){
        error = dynamicTranslation(translations['general']['errors']['max_length'], {
        attribute: translations['administration']['supplier']['fields']['email'],
        value: 100
        })
        return error;
    }

   return error;
}