<template>
   <GlobRow class="dashboard">
      <GlobSpinner :isLoading="isLoading" ></GlobSpinner>
      <GlobCol :medium="7">
          <TodayMaintenance :data="todayMaintenance"></TodayMaintenance>
      </GlobCol>
      <GlobCol :medium="5">
            <TodaysOEE :data="todaysOEE"></TodaysOEE>
      </GlobCol>
       <GlobCol :medium="3">
              <MaintenanceProgress :data="progress"></MaintenanceProgress>
        </GlobCol>
      <GlobCol :medium="3">
            <AssetCount :assetCount="totalAsset"></AssetCount>
       </GlobCol>
       <GlobCol :medium="3"> 
          <StoppedMinutes :stoppedMinutes="stoppedMinutes"></StoppedMinutes>
        </GlobCol>
       <GlobCol :medium="3">
              <DelayedMaintenance :data="delayedMaintenance"></DelayedMaintenance>
        </GlobCol>
        <GlobCol :medium="12">
          <Last30DaysMaintenance :data="last30DaysMaintenances"></Last30DaysMaintenance>
        </GlobCol>
   </GlobRow>
</template>

<script>
import { useApi } from "@/plugins/api";
import { reactive, ref, onMounted } from "vue";

import TodayMaintenance from "@/views/Dashboard/TodayMaintenance.vue";
import TodaysOEE from "@/views/Dashboard/TodaysOEE.vue";
import MaintenanceProgress from "@/views/Dashboard/MaintenanceProgress.vue";
import AssetCount from "@/views/Dashboard/AssetCount.vue";
import StoppedMinutes from "@/views/Dashboard/StoppedMinutes.vue";
import DelayedMaintenance from "@/views/Dashboard/DelayedMaintenance.vue";
import Last30DaysMaintenance from "@/views/Dashboard/Last30DaysMaintenance.vue";



export default {
  name: "Home",
  components: {
    TodayMaintenance,
    MaintenanceProgress,
    AssetCount,
    DelayedMaintenance,
    Last30DaysMaintenance,
    TodaysOEE,
    StoppedMinutes
  },
  setup() {
	const api = useApi();
	const endpoint = api.dashboard;
  const isLoading = ref(false);

    const todayMaintenance = ref([]);
    const todaysOEE = ref([]);
    const delayedMaintenance = ref([]);
    const progress = ref([]);
    const totalAsset = ref(0);
    const stoppedMinutes = ref(0);
    const last30DaysMaintenances = ref([]);

    const lockers = reactive({
      total: 0,
      active: 0
	});

    onMounted(() => {
      isLoading.value = true;
      endpoint.index().then((res) => {
        todayMaintenance.value = res.todays_maintenance;
        todaysOEE.value = res.todays_oee;
        progress.value = res.progress;
        totalAsset.value = res.total_assets;
        stoppedMinutes.value = res.stopped_minutes ? res.stopped_minutes : 0;
        delayedMaintenance.value = res.delayed_maintenance;
        last30DaysMaintenances.value = res.last_30_days_maintenances;
        isLoading.value = false;
      });
	})

    return {
      todayMaintenance,
      todaysOEE,
      progress,
      totalAsset,
      delayedMaintenance,
      last30DaysMaintenances,
       isLoading,
       stoppedMinutes
    };
  }
};
</script>

<style lang="scss">
.dashboard{
  margin-top: 2rem;
  .today-maintenance-done-status, .today-oee-reported-status{
    background-color: $ma-brightGreen !important;
    &:hover{
		  background-color: $ma-brightGreen !important;
	  }
  }
}
</style>
