<template>
    <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" :isCRUD="true" class="maintenance-plan-service-execution">
        <GlobSpinner :isLoading="isLoading" />
        <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
            <GlobRow>
                <GlobCol :medium="4">
                    <template v-if="!isServiceStarted">
                        <button type="button" class="button" @click="startService()">{{$t('maintenance_plan.service_execution.start_maintenance')}}</button>
                    </template>
                    <template v-else-if="serviceLogResponsible">
                        <template v-if="serviceLog.status.Code =='done'">
                            <span class="badge badge-success">{{$t('maintenance_plan.service_execution.status_button.done')}}</span>
                        </template>
                        <template v-else-if="serviceLog.status.Code =='onhold_waiting_sparepart'">
                            <span class="badge badge-yellow">{{$t('maintenance_plan.service_execution.status_button.onhold_waiting_sparepart')}}</span>
                        </template>
                        <template v-else-if="serviceLog.status.Code =='onhold_awaiting_additional_personel'">
                            <span class="badge badge-orange">{{$t('maintenance_plan.service_execution.status_button.onhold_awaiting_additional_personel')}}</span>
                        </template>
                        <template v-else>
                            <span class="badge badge-success">{{serviceLog.status.Name}}</span>
                        </template>
                    </template>
                    <template v-else>
                         <button type="button" class="button" @click="joinToService()">{{$t('maintenance_plan.service_execution.work_on_this_task')}}</button>
                    </template>
                </GlobCol>
                <GlobCol :medium="4">
                    <GlobQrCode 
                        :size="100"
                        :enableDownload="false" 
                        :content="generateQRCodeContentURL('service', maintenancePlanCalendarId, 'mobile')" 
                        @enlargeQRCodeActive="(val) => enlargeQRCodeActive(val)"/>
                </GlobCol>
                <GlobCol :medium="4" v-if="isServiceStarted && serviceLogResponsible">
                    <label>
                    <InputDynamic 
                            :fullWidth="true" :type="'virtualselect'"
							v-model="currentStatus" :data="statusList" @input="(val) => updateStatus(val)"/>
                    </label>
                </GlobCol>
            </GlobRow>
            <GlobRow v-if="maintenancePlanCalendar.IsExternalSupplierIncluded">
                <GlobCol :medium="12" class="external-supplier-section" :class="maintenancePlanCalendar.SupplierBookingConfirmed && maintenancePlanCalendar.SupplierConfirmedDate ? 'active' : ''">
                    <p class="heading">{{$t('maintenance_plan.service_execution.this_require_external_supplier_to_be_executed')}}</p>
                    <GlobRow>
                        <GlobCol :medium="3"></GlobCol>
                        <GlobCol :medium="3">
                            <label class="external-suppler-booked">
                                {{$t('maintenance_plan.service_execution.external_supplier_booked')}}
                                <InputDynamic
                                :fullWidth="true"
                                :type="'checkbox'"
                                @update:modelValue="(value) => updateExternalSupplierBooking('SupplierBookingConfirmed', value)"
                                v-model="maintenancePlanCalendar.SupplierBookingConfirmed"
                                />
                            </label>
                        </GlobCol>
                        <GlobCol :medium="3">
                            <label>
                                {{$t('maintenance_plan.service_execution.set_booked_date')}}
                                <InputDynamic
                                :fullWidth="true"
                                :type="'date'"
                                @update:modelValue="(value) => updateExternalSupplierBooking('SupplierConfirmedDate', value)"
                                v-model="maintenancePlanCalendar.SupplierConfirmedDate"
                                />
                            </label>
                         </GlobCol>
                         <GlobCol :medium="3"></GlobCol>
                    </GlobRow>
                </GlobCol>
            </GlobRow>
            <GlobRow>
                <GlobCol :medium="12" v-if="description">
                    <p class="section-head">
                        {{$t('maintenance_plan.service_execution.description')}}
                    </p>
                    <div class="description">
                        {{description}}
                    </div>
                </GlobCol>
                <GlobCol :medium="12" v-if="checkList.length && (!isServiceStarted || (isServiceStarted && !serviceLogResponsible))">
                    <p class="section-head">
                        {{$t('maintenance_plan.service_execution.check_list')}}
                    </p>
                    <template  v-for="item in checkList" :key="'check-list-'+item.Id">
                        <div class="check-list">
                            <div class="check-list-item">
                                <span><i class="fas fa-check"></i></span>
                                <p>{{item.Description}}</p>
                            </div>
                            <div class="files-section " :medium="2"  v-if="item.file_library">
                                <div class='parent'>
                                    <GlobFilePreview 
                                    :width="'90px'"
                                    :height="'90px'"
                                    :file="{
                                        Name: item.file_library.Name,
                                        Type: item.file_library.Type,
                                        URL: item.file_library.ExternalLink ? item.file_library.ExternalLink : getSavedFileURLFromPath(item.file_library.Path)
                                    }" />
                                </div>
                            </div>
                        </div>
                    </template>
                </GlobCol>
                <GlobCol :medium="12" v-if="descriptionAttachments && descriptionAttachments.length">
                    <p class="section-head">
                        {{$t('maintenance_plan.service_execution.attachments')}}
                    </p>
                    <GlobRow class="file-library-section">
                        <GlobCol class="files-section " :medium="2" v-for="attachment in descriptionAttachments" :key="'library-file-'+attachment.Id">
                            <div class='parent'>
                                <p>{{attachment.file_library.Name.length > 10 ? attachment.file_library.Name.slice(0, 10)+'...' : attachment.file_library.Name}}</p>
                                <GlobFilePreview 
                                :width="'90px'"
                                :height="'90px'"
                                :file="{
                                    Name: attachment.file_library.Name,
                                    Type: attachment.file_library.Type,
                                    URL : attachment.file_library.ExternalLink ? attachment.file_library.ExternalLink : getSavedFileURLFromPath(attachment.file_library.Path)
                                }" />
                            </div>
					    </GlobCol>
                     </GlobRow>
                </GlobCol>
                <GlobCol :medium="12" v-if="images.length">
                    <p class="section-head">
                        {{$t('maintenance_plan.service_execution.images')}}
                    </p>
                    <GlobCarousel :images="images"/>
                </GlobCol>
                <template v-if="isServiceStarted && serviceLogResponsible">
                    <GlobCol :medium="12">
                        <p class="section-head">
                            {{$t('maintenance_plan.service_execution.describe_service_or_comments')}}
                        </p>
                        <InputDynamic :type="'textarea'" v-model="userInput.description" :rows="10" :fullWidth="true" />
                    </GlobCol>
                     <GlobCol :medium="12" v-if="checkList && checkList.length">
                        <p class="section-head">
                            {{$t('maintenance_plan.service_execution.completed_check_list')}}
                        </p>
                        <div class="d-grid">
                            <template v-for="item in checkList" :key="'completed-check-list-'+item.Id">
                                <div class="confirm-checklist">
                                    <label class="d-inline-flex" :class="userInput.checkListToComplete && userInput.checkListToComplete[item.Id]  ? 'checked' : ''">
                                        <InputCheckbox
                                            class="mas-mr-2"
                                            :name="'alert-by-email'"
                                            v-model="userInput.checkListToComplete[item.Id]"
                                            />
                                            <p class="checklist-heading">{{item.Heading}}</p>
                                    </label>
                                    <p class="checklist-description">{{item.Description}}</p>
                                     <div class="files-section " :medium="2"  v-if="item.file_library">
                                        <div class='parent'>
                                            <GlobFilePreview 
                                            :width="'90px'"
                                            :height="'90px'"
                                            :file="{
                                                Name: item.file_library.Name,
                                                Type: item.file_library.Type,
                                                URL: item.file_library.ExternalLink ? item.file_library.ExternalLink : getSavedFileURLFromPath(item.file_library.Path)
                                            }" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </GlobCol>
                    <GlobCol :medium="12">
                        <p class="section-head">
                            {{$t('maintenance_plan.service_execution.upload_images')}}
                        </p>
                        <GlobFileUpload 
                        :addedFiles="userAttachments"
                        :enableMultipleUpload="true"
                        @filesRemoved="(files) => filesRemoved(files)"
                        @filesAdded="(files) => filesAdded(files)"/>
                    </GlobCol>
                    <GlobCol :medium="12" v-if="assetServiceReasons.length">
                        <p class="section-head">
                            {{$t('maintenance_plan.service_execution.service_reasons')}}
                        </p>
                        <InputDynamic 
                            :fullWidth="true" 
                            :type="'multiselect'"
							v-model="userInput.assetServiceReasons"
                            :data="assetServiceReasons"
                            :selected="userInput.assetServiceReasons"
                            />
                     </GlobCol>
                    <GlobCol :medium="12">
                        <p class="section-head">
                            {{$t('maintenance_plan.service_execution.service_tags')}}
                        </p>
                        <InputDynamic 
                            :fullWidth="true" 
                            :type="'multiselect'"
							v-model="userInput.tags"
                            :data="[]" 
                            :selected="serviceLog.service_tag_relations"
                            :searchAction="tagSearchAction"
                            :enableSearchAndAdd="true"/>
                     </GlobCol>
                     <GlobCol :medium="12">
                        <button type="button" class="button mas-mt-5" @click="updateStatus('done')">{{$t('maintenance_plan.service_execution.status_button.done')}}</button>
                        <button type="button" class="button button--orange" @click="updateStatus('onhold_waiting_sparepart')">{{$t('maintenance_plan.service_execution.status_button.onhold_waiting_sparepart')}}</button>
                        <button type="button" class="button button--red" @click="updateStatus('onhold_awaiting_additional_personel')">{{$t('maintenance_plan.service_execution.status_button.onhold_awaiting_additional_personel')}}</button>
                    </GlobCol>
                </template>
            </GlobRow>
        </GlobXpanel>
    </GlobXpanel>
</template>
<script>
import { onMounted, ref, reactive, computed, watch} from "vue";
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import { dynamicTranslation, getSavedFileURLFromPath,generateQRCodeContentURL } from '@/helpers.js';
import bgThumb from '@/assets/icon/image-thumbnail.png';

export default {
components: {},
props:['maintenancePlanCalendarId', 'assetId'],
setup(props, {emit}) {
        const { tm } = useI18n();
        const api = useApi();
        const notifications = useNotifications();

        const isLoading = ref(false);
        const endpoint = computed(() => api.maintenancePlans);

        const description = ref('');
        const descriptionAttachments = ref([]);
        const checkList = ref([]);
        const images = ref([]);
        const isServiceStarted = ref(false);
        const serviceLogResponsible = ref(false);
        const userInput = reactive({
            description: '',
            checkListToComplete: [],
            tags: [],
            assetServiceReasons: []
        })
        const userAttachments = ref([]);
        const serviceLog = ref({});
        const maintenancePlanCalendar = reactive({
            'SupplierBookingConfirmed': false,
            'SupplierConfirmedDate': '',
        })

        const statusList = ref([]);
        const assetServiceReasons = ref([]);
        const currentStatus = ref('');

        onMounted(() => {
            getData();
        })

        const getData = () => {
            isLoading.value = true;
            endpoint.value.getServiceDetails(props.maintenancePlanCalendarId).then((res) => {
                description.value = res.description;
                checkList.value = res.check_lists;
                images.value = res.images;
                descriptionAttachments.value = res.description_attachments;
                assetServiceReasons.value = res.asset_service_reasons;
                
                //External supplier
                Object.assign(maintenancePlanCalendar, res.maintenance_plan_calendar)

                //Fill user inputs
                if(res.service_log){
                    serviceLog.value = res.service_log;

                    statusList.value = res.status_list;
                    currentStatus.value = res.service_log.status.Code;

                    userInput.description = res.service_log.Comment;
                    res.service_log.service_log_check_list.forEach((item) => {
                        userInput.checkListToComplete[item.MaintenanceServiceDescriptionCheckListId] = item.Value;
                    })

                    userInput.assetServiceReasons = res.service_log.service_asset_reason_relations.map((item) => {
                        return item.value;
                    });

                    userAttachments.value = res.attachments;

                    isServiceStarted.value = res.is_service_started;
                    serviceLogResponsible.value = res.service_log_responsible;

                    serviceLog.value.service_tag_relations = serviceLog.value.service_tag_relations.map((item) => {
                        return {
                            name: item.service_tag.Name,
                            value: item.service_tag.Id,
                        }
                    })
                    

                }
                isLoading.value = false;
            })
        }

        const startService = () => {
            isLoading.value = true;
            endpoint.value.startService(props.maintenancePlanCalendarId).then((res) => {
                getData();
            });
        }

        const joinToService = () => {
            isLoading.value = true;
            endpoint.value.joinToService(props.maintenancePlanCalendarId).then((res) => {
                getData();
            });
        }

        const filesAdded = (files) => {
            isLoading.value = true;
            let formData = new FormData();
            formData.append('serviceLogResponsibleId', serviceLogResponsible.value)
            Object.keys(files).forEach(function(key){
                  formData.append('files['+key+']', files[key])
            })

            endpoint.value.uploadFile(props.maintenancePlanCalendarId, formData).then((res) => {
                notifications.push(
                    {
                        msg: tm('maintenance_plan')['service_execution']['file_upload_success']
                    },
                    "success",
                    false
			    );
                
                getData();
            })            
        }

        const filesRemoved = (file) => {
                isLoading.value = true;
                endpoint.value.deleteFile(props.maintenancePlanCalendarId, file).then((res) => {
                    notifications.push(
                    {
                        msg: tm('maintenance_plan')['service_execution']['file_removed_success']
                    },
                    "success",
                    false
			    );
                    getData();
                })   
        }

        const updateStatus = (statusCode) => {

            if(!statusCode){
                return false;
            }

            isLoading.value = true;
            let data = Object.assign({}, userInput);
            let checkListsTemp = [];

            data.checkListToComplete.forEach((value, index) => {
                checkListsTemp.push({'id': index,'value': value})
            })

            data.checkListToComplete = checkListsTemp;
            data.serviceLogResponsibleId = serviceLogResponsible.value;
            data.statusCode = statusCode;

            endpoint.value.updateStatus(props.maintenancePlanCalendarId, data).then((res) => {
                
                notifications.push(
                    {
                        msg: tm('maintenance_plan')['service_execution']['update_success']
                    },
                    "success",
                    false
			    );
                emit('maintenanceUpdated', props.maintenancePlanCalendarId)
                emit('close')
            })
        }


        const tagSearchAction = (searchKey, selectedOptionsIds) => {

            return endpoint.value.searchTags(props.maintenancePlanCalendarId, {
                    'searchKey': searchKey,
                    'assetId': props.assetId,
                    'selectedOptions': selectedOptionsIds
                }).then((res) => {
                return res.data;
            })
        }
        const updateExternalSupplierBooking = (inputField, value) => {
            let data = {
                SupplierBookingConfirmed: inputField == 'SupplierBookingConfirmed'? value : maintenancePlanCalendar.SupplierBookingConfirmed,
                SupplierConfirmedDate: inputField == 'SupplierConfirmedDate'? value : maintenancePlanCalendar.SupplierConfirmedDate,
            }
            isLoading.value = true;
            endpoint.value.updateExternalSupplierBooking(props.maintenancePlanCalendarId, data).then((res) => {
                emit('externalSupplierUpdated', data)
                isLoading.value = false;
            })
        }

        const enlargeQRCodeActive = (val) => {
            emit('enlargeQRCodeActive', val)
        }

        return {
            isLoading,
            description,
            descriptionAttachments,
            checkList,
            images,
            startService,
            joinToService,
            isServiceStarted,
            serviceLogResponsible,
            userInput,
            updateStatus,
            filesAdded,
            filesRemoved,
            userAttachments,
            serviceLog,
            statusList,
            currentStatus,
            bgThumb,
            getSavedFileURLFromPath,
            tagSearchAction,
            maintenancePlanCalendar,
            updateExternalSupplierBooking,
            assetServiceReasons,
            enlargeQRCodeActive,
            generateQRCodeContentURL
        }
    }
}
</script>

<style lang="scss">
.maintenance-plan-service-execution{
    .section-head{
        font-size: 16px;
        margin:0;
        margin-bottom: 1rem;
        font-weight: 600;
        color: $ma-bluish;
    }
    .description{
        padding: 17px 10px 17px 10px;
        font-size: 14px;
        line-height: 20px;
        border: 1px solid $mas-gray;
        border-radius: 6px;
    }

    .check-list{
        .check-list-item{
            display: flex; 
            margin-bottom: 12px;
            p{
                margin: 0;
                margin-left: 1rem;
                font-size: 15px;
                font-weight: bolder;
            }

            span i{
                font-size: 22px;
            }
        }

        .files-section{
            margin-left: 36px;
            margin-top: -19px;
            cursor: pointer;
        }
    }

    .file-library-section{
            .parent{
                cursor: pointer;
            }
    }

    .external-supplier-section{
        background-color: $mas-lightRed;
        border: 2px dashed $mas-red;
        
        margin-top: 25px;
        margin-bottom: 25px;
        .heading{
            font-weight: 900;
            text-align: center;
            margin: 15px 0 23px 0;
            font-size: 16px;
        }

        label{
            font-weight: 600;
        }
        label.external-suppler-booked{
            justify-content: center;
            display: grid;
            width: 100%;
            .dynamic-type-input{
                text-align: center;
            }
        }
    }

    .external-supplier-section.active{
        background-color: $mas-greenBlue;
        border: 2px dashed $mas-sanFelixGreen;
    }

    .confirm-checklist{
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 17px;
        padding-top: 17px;
        label{
            font-weight: bold;
            color: $mas-steelGray;
            p.checklist-heading{
                margin: 0;
                margin-top: 1px;
                font-size: 15px;
                font-weight: bold;
            }
        }
        label.checked{
            text-decoration: line-through;
            p.checklist-heading{
                color: #7e8487 !important;
            }
        }
        p.checklist-description{
            margin: 0;
            margin-left: 36px;
            font-weight: 900;
            opacity: .6;
            margin-top: 3px;
        }

        .checkBoxs{
            margin-right: 12px !important;
        }

        .files-section{
            margin-left: 36px;
            cursor: pointer;
        }
    }

    .mas-glob-qr-code{
        justify-content: center;
    }
}
</style>