import { dynamicTranslation } from '@/helpers.js';
import { i18n } from '@/plugins/i18n/lang.js'

export const validateForm = (form) =>{
   let error = null;
   let translations = i18n.global.messages.value[i18n.global.locale.value];

   if(form.ApiName.length > 50){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['api_key']['fields']['name'],
         value: 50
      })
      return error;
   }

   return error;
}