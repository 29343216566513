import Admin from "@/views/Admin/Admin.vue";

export const admin = {
  path: "/admin",
  component: Admin,
  name: "Admin",
  nav: {
    name: "Admin",
    icon: "sliders-h"
  },
  meta: {
    noPadding: true
  },
};
