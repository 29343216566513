<template>
<div>
	<div class="dynamic-type-input" v-if="type == 'select'">
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
		<InputSelect
			:fullWidth="fullWidth"
			@input="$emit('update:modelValue', $event.target.value)"
			:disabled="disabled"
			:value="modelValue"
		/>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'checkbox'">
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
			<InputCheckbox
				class="mas-mr-2"
				:disabled="disabled"
				:name="name"
				:modelValue="modelValue"
				@update:modelValue="(val) => emitValues(val)"
				/>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'textarea'">
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
		<InputTextArea
			:fullWidth="fullWidth"
			@input="$emit('update:modelValue', $event.target.value)"
			:disabled="disabled"
			:value="modelValue"
			:placeholder="placeholder"
			:rows="rows"
		/>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'number'">
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
		<InputNumber
			:fullWidth="fullWidth"
			@input="$emit('update:modelValue', $event.target.value)"
			:disabled="disabled"
			:value="modelValue"
			:onkeypress=" preventDecimal ? 'return event.charCode >= 48 && event.charCode <= 57' : ''"
		/>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'text'">
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
		<InputText
			:fullWidth="fullWidth"
			@input="$emit('update:modelValue', $event.target.value)"
			:disabled="disabled"
			:value="modelValue"
			:placeholder="placeholder"
		/>
		<template v-if="inputIcon">
			<span class="input-icon" @click="$emit('inputIconClicked')">
				<i :class="inputIcon"></i>
			</span>
		</template>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'virtualselect'">
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
		<InputVirtualSelect 
		  	:fullWidth="fullWidth"
			:hideInput="hideInput"
			@input="(val) => emitValues(val)"
			:value="modelValue"
			:placeholder="placeholder"
			:data="data"
			:isFilter="isFilter"
			:disabled="disabled"
		/>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'multiselect'">
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
		<InputMultiSelect 
			:fullWidth="fullWidth"
			@updateValue=" (values) => emitValues(values)"
			:disabled="disabled"
			:value="modelValue"
			:placeholder="placeholder"
			:data="data"
			:selected="selected"
			:isFilter="isFilter"
			:enableSearchAndAdd="enableSearchAndAdd"
			:searchAction="searchAction"
		/>
	</div>
	<div class="dynamic-type-input" v-else>
		<div class="dynamic-type-input__validation-message">{{validationMessage}}</div>
		<input
			class="mas-input"
			:class="fullWidth ? 'full-width' : ''"
			@input="$emit('update:modelValue', $event.target.value)"
			:value="modelValue"
			:type="type"
			:disabled="disabled"
			:min="minValue"
		/>
	</div>
</div>
</template>

<script>
import { props } from './common';
import { ref } from 'vue';
export default {
	props: [
		...props,
		'type',
		'hideInput',
		'fullWidth',
		'preventDecimal',
		'rows',
		'min',
		'name'
	],
	setup(props, {emit}){
		const validationMessage = ref('')
		const minValue = ref(props.min)

		const emitValues = (values) => {
			emit('update:modelValue', values)
			emit('input', values)
		}

		return {
			validationMessage,
			minValue,
			emitValues
		}
	}
}
</script>

<style lang="scss">
.input-icon{
      background-color: $mas-gray;
      width: 34px;
      height: 33px;
      justify-content: center;
      display: flex;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;

      i{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
  }
</style>