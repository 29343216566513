<template>
    <div>
      <GlobXpanel :title="$t('dashboard.todays_oee')">
         <GlobTable
             :maxHeight="50"
             :data="{
                 head: head,
                 body: data
             }"
             :itemRenderFunctions="renderFunctions"
             :rowStylingFunction="rowStylingFunction"
         />
     </GlobXpanel>
    </div>
 </template>
 
 <script>
 
 import { ref, computed } from "vue";
 import { useI18n } from 'vue-i18n';
 import { convertUtcToLocalTime } from '@/helpers.js';
 
 export default {
     props: ["data"],
     setup(props) {
 
         const { tm } = useI18n();
 
         const head = ref([
             {
                 Alias: "AssetName",
                 Value: computed(() => tm('dashboard')['asset'])
             },
             {
                 Alias: "StopDateTime",
                 Value: computed(() => tm('dashboard')['stopped'])
             },
             {
                 Alias: "Reason",
                 Value: computed(() => tm('dashboard')['reason'])
             },
             {
                 Alias: "RunTime",
                 Value: computed(() => tm('dashboard')['run_time'])
             },
             {
                 Alias: "TimeStopped",
                 Value: computed(() => tm('dashboard')['time_stopped'])
             }
 
         ]);
 
         const rowStylingFunction = (obj) => {
             if (obj.ReportedByAccountId) {
                 return 'today-oee-reported-status';
             }
         }
 
         const renderFunctions = computed(() => {
             return {
                        StopDateTime(obj) {
                            return convertUtcToLocalTime(obj.StopDateTime, 'YYYY-MM-DD HH:mm')
                        },
                        RunTime(obj) {
                            if (obj.RunTime) {
                                return obj.RunTime + ' ' + tm('maintenance_plan')['oee']['minutes'];
                            } else {
                                return '0 ' + tm('maintenance_plan')['oee']['minutes'];
                            }
                        },
                        TimeStopped(obj) {
                            if (obj.TimeStopped) {
                                return obj.TimeStopped + ' ' + tm('maintenance_plan')['oee']['minutes'];
                            } else {
                                return '0 ' + tm('maintenance_plan')['oee']['minutes'];
                            }
                        }
                    }
         });
 
         return {
             head,
             renderFunctions,
             rowStylingFunction
         }
     }
 }
 </script>
 