<template>
    <div>
        <GlobSpinner :isLoading="isLoading" />
        <GlobTabs :initialTab="'general'" :tabs="tabs" :keepState="false">
            <!-- Ganeral -->
			<template v-slot:tab-panel-general>
                <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
                    <GlobRow>
                        <GlobCol :medium="3">
                            <label>
                                {{$t('administration.service_description.fields.name')}}*
                                <InputDynamic :fullWidth="true" :type="'text'"
                                    :placeholder="$t('administration.service_description.fields.name')"
                                    v-model="serviceDescription.form.Name" />
                            </label>
                        </GlobCol>
                        <GlobCol :medium="6" v-if="assetGroups.length">
                            <label>
                                {{$t('administration.service_description.fields.asset_groups')}}
                                <InputDynamic :fullWidth="true" :type="'multiselect'"
                                    :placeholder="$t('administration.service_description.fields.asset_groups')"
                                    v-model="serviceDescription.form.AssetGroup" :data="assetGroups"
                                    :selected="serviceDescription.form.AssetGroup" />
                            </label>
                        </GlobCol>
                    </GlobRow>
                    <GlobRow>
                        <GlobCol :medium="9">
                            <label>
                                {{$t('administration.service_description.fields.description')}}*
                                <InputDynamic :fullWidth="true" :type="'textarea'" :rows="10"
                                    :placeholder="$t('administration.service_description.fields.description')"
                                    v-model="serviceDescription.form.Description" />
                            </label>
                        </GlobCol>
                    </GlobRow>
                    <p class="text-danger" v-if="errorMessage">{{errorMessage}}</p>
                    <button @click="save" class="button mas-mt-4"
                        :disabled="isGeneralTabSaveButtonDisabled">{{$t('general.button.save')}}</button>
                    <button @click="$emit('showDeleteConfirmModal', true)"
                        class="button button--red">{{$t('general.button.delete')}}</button>
                </GlobXpanel>
            </template>
			<!-- Check list -->
            <template v-slot:tab-panel-checklist>
                <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
                    <GlobRow>
						<GlobCol :medium="3">
                            <label>
                                {{$t('administration.service_description.fields.input_type')}}*
								                            <InputDynamic :type="'virtualselect'" fullWidth="true" :placeholder="$t('administration.service_description.fields.input_type')"
                                v-model="checkListObject.InputType" :data="inputTypes" />
                            </label>
                        </GlobCol>
						<GlobCol :medium="6">
							 <label>
                                {{$t('administration.service_description.fields.heading')}}*
								<InputDynamic :type="'text'" fullWidth="true" :placeholder="$t('administration.service_description.fields.heading')"
                                v-model="checkListObject.Heading" />
                            </label>
						</GlobCol>
                        <GlobCol :medium="9">
                            <label>
                                {{$t('administration.service_description.fields.description')}}*
							<InputDynamic :type="'textarea'" 
								:rows="4"
								fullWidth="true" 
								:placeholder="$t('administration.service_description.fields.description')"
                                v-model="checkListObject.Description" />
							</label>

                        </GlobCol>
                        <GlobCol :medium="4" v-if="checkListObject.InputType == 'image' || checkListObject.InputType == 'checkbox'" class="image-upload-section">
				
                            <input type="file" ref='image' style="display: none" accept="image/*"
                                @change="imageAttached()" v-if="false">
                            <template v-if="!checkListObject.ImageURL">
                                <GlobFileLibrary 
											:disableMultipleSelect="true"
											@showFileLibraryModal="(val) => $emit('showFileLibraryModal', val)"
											@inputChanged = "(item) => imageAttached(item)"
											:addedFiles="[]">
								</GlobFileLibrary>
                            </template>
                            <template v-else>
								<label>{{ $t('administration.service_description.attached_image') }}</label>
                                <div class="attachment" >
                                    <a id="uploaded-doc-link" :href="checkListObject.ImageURL" download=""
                                        target="_blank">{{checkListObject.ImageName.length > 25 ? checkListObject.ImageName.substring(0,25)+".." : checkListObject.ImageName}}</a>
                                    <span @click.stop="removeImage()"><i class="fas fa-times"></i></span>
                                </div>
                            </template>
                        </GlobCol>
                    </GlobRow>
					<p class="text-danger" v-if="checkListFormError">{{checkListFormError}}</p>
					<button class="button button--small mas-mt-3" :disabled="isCheckListSaveButtonDisabled"
                                @click="saveOrUpdateCheckList()">{{checkListObject.Id ? $t('general.button.update') : $t('general.button.save')}}</button>
                    <button class="button button--small button--outline"
                                @click="resetCheckListForm()">{{$t('general.button.clear')}}</button>
					<GlobTable
						class="mas-mt-4"
						:maxHeight="50"
						:itemRenderFunctions="renderFunctions"
						:data="{
							head : checkListHead,
							body : checkList
						}"
						:actions="actions"
					/>
                </GlobXpanel>
            </template>
			<!-- Attachments -->
            <template v-slot:tab-panel-attachment>
                <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" class="file-library-section">
                    <GlobRow>
                        <GlobCol :medium="12" class="select-upload-library-file">
							<GlobFileLibrary 
								@showFileLibraryModal="(val) => $emit('showFileLibraryModal', val)"
								@inputChanged = "(item) => attachmentAddedOrRemoved(item)"
								:addedFiles="attachedFiles">
								</GlobFileLibrary>
						</GlobCol>
					</GlobRow>
						<template  v-if="attachments && attachments.length">
						<div class="grid-container">
							<div class="grid-x add-file" v-sortable="{ disabled: false, sorting: attachments, callback: sortingCallback}">
								<GlobCol class="files-section " :medium="3" v-for="attachment in attachments" :key="'library-file-'+attachment.Id">
									<div class='parent'>
										<p>{{attachment.file_library.Name.length > 10 ? attachment.file_library.Name.slice(0, 10)+'...' :  attachment.file_library.Name}}</p>
										<div class="thumb" :style="'background-image: url('+bgThumb+')'" 
											@click="downloadFile(attachment.file_library.Path, attachment.file_library.Name+'.'+attachment.file_library.Type)"
											>
											<span class="remove" @click.stop="removeFileFromDescription(attachment.MediaLibraryId)">-</span>
										</div>						
									</div>
								</GlobCol>
							</div>
						</div>
						</template>
				</GlobXpanel>
			</template>
        </GlobTabs>
    </div>
</template>
<script>
import {onMounted, reactive, ref, computed, watchEffect, watch, toRaw } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import { useRoute, useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { dynamicTranslation, getSavedFileURLFromPath } from '@/helpers.js';
import { validateForm, validateCheckListForm } from "@/views/crud/ServiceDescription/common.js";
import { maxFileSize, maxFileSizeInMB } from '@/defaultConfig.js';
import bgThumb from '@/assets/icon/image-thumbnail.png';

export default {
  props: ["manager", "router", "itemId", "reloadData", "loading"],
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.serviceDescriptions);
	const api = useApi();
	const router = useRouter();
	const route = useRoute();

	const itemId = ref(props.itemId);

	const notifications = useNotifications();
    const reloadDataLocal = ref(props.reloadData);

	const tabs = ref([
		{
			value: "general",
			name: computed(() => tm('administration')['service_description']['tabs']['general'])
		},
		{
			value: "checklist",
			name: computed(() => tm('administration')['service_description']['tabs']['check_list'])
		},
		{
			value: "attachment",
			name: computed(() => tm('administration')['service_description']['tabs']['attachments'])
		},
	]);
	
	watch(() => props.reloadData, (selection, prevSelection) => { 
		getData();
	})

	watch(() => props.itemId, (selection, prevSelection) => { 
		itemId.value = props.itemId;
		getData();
	})

	watch(() => props.loading, (selection, prevSelection) => { 
		isLoading.value = true;
	})

	const isLoading = ref(true);
	const errorMessage = ref(null);

	const serviceDescription = reactive({
		form: {
			Id: "",
			Name: "",
            Description: "",
			AssetGroup: []
		},
	});

	const serviceDescriptionOriginal = ref({});

	const assetGroups = ref([]);
	const attachments = ref([]);

	onMounted(async() => {
		getData();
	 });

	 const getData = (updateMainList = false) => {
		isLoading.value = true;
		endpoint.value.getById(itemId.value, true).then(function(res){
			if(!res.data){
				notifications.push(
					{
						msg: tm('general')['something_went_wrong']
					},
					"warning",
					false
					);
				router.push(`/admin/view/service-descriptions`);
			}
			serviceDescription.form = res.data;

			serviceDescription.form.AssetGroup = res.data.AssetGroupRelation.map((item) => {
				return item.AssetGroupId;
			});

			serviceDescriptionOriginal.value = JSON.parse(JSON.stringify(serviceDescription.form))

			assetGroups.value = res.asset_groups.map((item) => {
				return {
					name: item.Name,
					value: item.Id
				}
			});

			//Check list
			checkList.value = res.check_lists;
			
			attachments.value = res.attachments;

			isLoading.value = false;

			if(updateMainList){
				emit('updated', res.data);
			}
		});
	 };

	const isGeneralTabSaveButtonDisabled = computed({
		get() {
			if(!serviceDescription.form.Name){
				return false;
			} else {
				if(JSON.stringify(serviceDescription.form) === JSON.stringify(serviceDescriptionOriginal.value)){
					return true;
				}
			}
			return false;
		},
		set(newValue) {},
	})

    const save = () => {
		errorMessage.value = validateForm(serviceDescription.form);

		if(errorMessage.value){
			return false;
		}
		
		isLoading.value = true;
		endpoint.value.update(serviceDescription.form.Id, serviceDescription.form).then((res) => {
			//Check whether any error exists when create item
			if(res && res.success == 0 && res.errors){
				for (const item in res.errors) {
					errorMessage.value = tm(res.errors[item][0]);
					break;
				}
				return false;
			}
			notifications.push(
					{
						msg: tm('administration')['service_description']['update_success']
					},
					"success",
					false
			);
			isLoading.value = false;
			if(res.new_service_desciption_id){
				itemId.value = res.new_service_desciption_id;
				emit('refreshList', itemId.value);
			}
			getData(true)
		});
    };

	//Check list
	const checkList = ref([]);
	const checkListHead = ref([
		{
			Alias: "Heading",
			Value: computed(() => tm('administration')['service_description']['fields']['heading'])
		},
		{
			Alias: "Description",
			Value: computed(() => tm('administration')['service_description']['fields']['description'])
		},
		{
			Alias: "InputType",
			Value: computed(() => tm('administration')['service_description']['fields']['input_type'])
		},

	]);

	const renderFunctions = computed(()=> {
		return {
			InputType(obj){
				return tm('general')['input_types'][obj.InputType]
			},
			Heading(obj){
				if(obj.Heading && obj.Heading.length > 10){
					return obj.Heading.slice(0, 10)+'...';
				}
				return obj.Heading;
			},
			Description(obj){
				if(obj.Description && obj.Description.length > 10){
					return obj.Description.slice(0, 10)+'...';
				}
				return obj.Description;
			}
		}
	})

	const actions = computed(() => { return [
			{
				name: tm('general')['button']['edit'],
				color: "blue",
				action: (obj) => {
					checkListObject.Id = obj.Id;
					checkListObject.Heading = obj.Heading;
					checkListObject.Description = obj.Description;
					checkListObject.InputType = obj.InputType;
					if((obj.InputType == 'image' || obj.InputType == 'checkbox') && obj.FileLibrary){
						checkListObject.ImageURL = getSavedFileURLFromPath(obj.FileLibrary.Path);
						checkListObject.ImageName = obj.FileLibrary.Name;
						checkListObject.FileLibraryId = obj.FileLibrary.Id;
					}else{
						checkListObject.ImageURL = null;
						checkListObject.ImageName = null;
						checkListObject.FileLibraryId = null;
					}
					
					checkListObjectOriginal.value = JSON.parse(JSON.stringify(checkListObject))
					
				}
			},
			{
				name: tm('general')['button']['delete'],
				color: "red",
				action: (obj) => {
					deleteCheckList(obj.Id);
				}
			},
		];
	});

	const checkListObject = reactive({
		Id: '',
		Heading: '',
		Description: '',
		InputType: '',
		Image: ''
	});

	const checkListObjectOriginal = ref(null);

	const image = ref(null);
	const saveCheckListError = ref(null);


	const isCheckListSaveButtonDisabled = computed(() => {
		if(!checkListObject.Heading || !checkListObject.Description || !checkListObject.InputType){
			return true;
		}
		if(checkListObject.InputType == 'image' && !checkListObject.ImageURL){
			return true;
		}

		if(JSON.stringify(checkListObject) === JSON.stringify(checkListObjectOriginal.value)){
			return true;
		}

		return false;
	})

	const inputTypes = ref([
			{
				value: "text",
				name: computed(() => tm('general')['input_types']['text'])
			},
			{
				value: "checkbox",
				name: computed(() => tm('general')['input_types']['checkbox'])
			},
			{
				value: "image",
				name: computed(() => tm('general')['input_types']['image'])
			},

	]);

	const imageAttached = (file) => {

		checkListObject.FileLibraryId = file.item.Id;
		checkListObject.ImageName = file.item.Name;
		checkListObject.ImageURL = getSavedFileURLFromPath(file.item.Path);
	}

	const removeImage = () => {
			checkListObject.ImageURL = null;
			checkListObject.FileLibraryId = null;
			checkListObject.ImageName = null;
			
	}

	const resetCheckListForm = () =>{
		checkListObject.Id = '';
		checkListObject.Heading = '';
		checkListObject.Description = '';
		checkListObject.InputType = '';
		checkListObject.FileLibraryId = '';
		checkListObject.ImageURL='';
		checkListObject.ImageName = '';

		checkListObjectOriginal.value = null;
	}
	const checkListFormError = ref(null);

	const saveOrUpdateCheckList = () => {

			checkListFormError.value = validateCheckListForm(checkListObject);
			if(checkListFormError.value){
				return false;
			}

			isLoading.value = true;
			
			var formData = new FormData();
			
			formData.append('Id', checkListObject.Id ? checkListObject.Id : '');
			formData.append('Heading', checkListObject.Heading);
			formData.append('Description', checkListObject.Description);
			formData.append('InputType', checkListObject.InputType);
			formData.append('FileLibraryId', checkListObject.FileLibraryId);

			endpoint.value.saveOrUpdateCheckList(itemId.value, formData).then((res) => {
				
				notifications.push(
						{
							msg: tm('administration')['service_description']['check_list_save_success']
						},
						"success",
						false
				);
				checkList.value = res.check_lists;
				resetCheckListForm();
				if(res.new_service_desciption_id){
					itemId.value = res.new_service_desciption_id;
					emit('refreshList', itemId.value);
				}
				isLoading.value = false;
			})
	}

	const deleteCheckList = (checkListId) => {

			isLoading.value = true;

			endpoint.value.deleteCheckList(itemId.value, checkListId).then((res) => {
				notifications.push(
						{
							msg: tm('administration')['service_description']['check_list_delete_success']
						},
						"success",
						false
					);
				
				checkList.value = res.check_lists;
				resetCheckListForm();
				if(res.new_service_desciption_id){
					itemId.value = res.new_service_desciption_id;
					emit('refreshList', itemId.value);
				}
				isLoading.value = false;
			})
	}


	const sortingCallback = (items) => {
      
	   let ids = items.map(i => i.Id);
	   isLoading.value = true;
	   endpoint.value.sortFiles(itemId.value, {ids: ids}).then((res) => {
		   isLoading.value = false;
	   })
    };

	const downloadFile = (path, filename) => {
		const url = getSavedFileURLFromPath(path);
		const link = document.createElement("a");
		link.target = '_blank';
		link.href = url;
		link.download = filename;
		link.click();
	}

	//Attachments
	const attachmentAddedOrRemoved = (item) => {
		if (item.value) {
			addFileToDescription(item.item.Id);
		} else {
			removeFileFromDescription(item.item.Id);
		}
	}

	  const attachedFiles = computed(() => {
			console.log('attachments', attachments.value)
			return attachments.value.map(file => parseInt(file.MediaLibraryId))
	})

	const addFileToDescription = (fileLibraryId) => {
		isLoading.value = true;
		api.serviceDescriptions.addFileToDescription(itemId.value, fileLibraryId).then((res) => {
			notifications.push(
				{
					msg: tm('administration')['service_description']['file_library_modal']['file_add_success']
				},
				"success",
				false
			);
			emit('reloadData');
			if (res.new_service_desciption_id) {
				itemId.value = res.new_service_desciption_id;
				emit('refreshList', itemId.value);
			}
			getData();
		})
	}

	const removeFileFromDescription = (fileLibraryId) => {
			isLoading.value = true;
			endpoint.value.removeFileFromDescription(itemId.value, fileLibraryId).then((res) => {
				notifications.push(
					{
						msg: tm('administration')['service_description']['file_library_modal']['file_remove_success']
					},
					"success",
					false
				);
				if (res.new_service_desciption_id) {
					itemId.value = res.new_service_desciption_id;
					emit('refreshList', itemId.value);
				}
				getData();
			})
		}

    return {
	  isLoading,
	  tabs,
	  isGeneralTabSaveButtonDisabled,
	  serviceDescriptionOriginal,
      save,
	  errorMessage,
	  serviceDescription,
	  reloadDataLocal,
	  assetGroups,
	  ...props,
	  //check list
	  checkList,
	  checkListHead,
	  actions,
	  checkListObject,
	  isCheckListSaveButtonDisabled,
	  inputTypes,
	  imageAttached,
	  image,
	  removeImage,
	  saveOrUpdateCheckList,
	  saveCheckListError,
	  resetCheckListForm,
	  deleteCheckList,
	  maxFileSizeInMB,
	  renderFunctions,
	  bgThumb,
	  removeFileFromDescription,
	  sortingCallback,
	  downloadFile,
		checkListFormError,
	//Attachment
		attachmentAddedOrRemoved,
		attachedFiles,
	  	attachments
    };
  },
};
</script>

<style lang="scss" scoped>

.file-upload-input-block{
	display: flex;
	input{
		pointer-events: none;
		cursor: pointer;
		margin-top: 5px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		width: 100%;
	}

	span{
		background-color: $mas-gray;
		margin-top: 5px;
		height: 33px;
		width: 36px;

		i{
			font-size: 20px;
			text-align: center;
			display: block;
			margin-top: 7px;
		}
	}
}

p.hint-image-upload{
    margin: 0;
    font-style: oblique;
    font-size: 12px;
}

.attachment {
	position: relative;
	padding-bottom: 8px !important;
	padding-top: 8px !important;
	background-color: $mas-lightGray;
	height: 33px;
	margin-top: 6px;
	border-radius: 6px;

	a{
		margin-left: 9px;
		color: $mas-carbonGrey;
	}

	span{
		position: absolute;
    	right: 12px;
		cursor: pointer;
	}

}

//Attachments tabs
.select-upload-library-file.border{
	border-bottom: 1px dashed $mas-gunmetalBlack !important;
}
.select-upload-library-file{
	display: grid;
	div{
		display: inline-flex;
	}
	i{
		margin-left: 9px;
		cursor: pointer;
		font-size: 18px;
	}
	i.blue{
		color: $mas-traditionalBlue;
	}
	p{
		margin: 0;
    	margin-bottom: 15px;
		min-width: 8rem;

	}
}
.add-file{
	border-top: 1px dashed $mas-gunmetalBlack !important;
	.files-section{
		margin-top: 2rem;
	}

	.thumb{
		cursor: pointer;
	}
}

.file-library-section{
	.uploaded-file-type{
        font-size: 15px;
        border: 1px solid;
        display: block;
        width: 5rem;
        height: 1.9rem;
        padding-left: 8px;
        padding-top: 4px;
        background-color: $mas-lightGray;
		border-radius: 6px;
		border: 1px solid #c9c9c9;
    }

	.files-section.border{
		margin-top: 10px;
		border-top: 1px dashed $mas-gunmetalBlack !important;
		div.parent{
    		margin-top: 32px;
		}
	}
	.files-section{		
		div.parent{
			justify-content: center;
    		display: grid;
			position: relative;
		}
		p{
			margin: 0;
			font-weight: bolder;
			text-align: center;
		}

		span {
			border-radius: 50%;
			right: 23%;
			width: 26px;
			height: 26px;
			display: inline-block;
			position: absolute;
			text-align: center;
			font-size: 30px;
			color: white;
			line-height: 18px;
			font-weight: 900;
			cursor: pointer;

		}
		span.add{
			background-color: $mas-green;
			right: -3%;
    		top: -5%;
		}
		span.remove{
			background-color: $mas-solidRed;
			right: -3%;
    		top: -3%;
		}


		div.thumb{
			width: 110px;
			height: 110px;
			background-size: contain !important;
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;
			position: relative;

			.image-background{
				span.add{
					right: -9%;
					top: -6%;
				}
			}
		}
	}

}
</style>
