/* Standard routes */
export const standard = [
	'Login',
	'Home',
	'Maintenance',
	'test3d'
]

/* Specialized modules */
export const modules = [
	'Admin'
]