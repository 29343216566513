
import Supplier from "@/views/crud/Supplier/Suppliers.vue";

export const supplier = [{
  path: "/admin/view/suppliers",
  component: Supplier,
  isCRUD: true,
  name: "Supplier",
  nav: {
    name: "Admin",
    icon: "fa-hdd"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Suppliers" }]
  },
}
]
