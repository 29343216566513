<template>
  <div style="position: absolute; overflow: hidden; left: -0px; right: -0px; top: -0px; bottom: -0px;">
    <div style="height: 100vh; position: relative; z-index: 2;">
      <div class="ips-center-content">
        <div class="ips-login-form">
			<div class="section-1"><img class="logo-text logo-text--big" src="@/assets/logo/logo-with-text.png" /></div>
			<div class="section-2">
				<div>
				<p class="title">Welcome back</p>
				<p class="jfyi">Please enter you details</p>
				
				<form @submit.prevent="login" accept-charset="utf-8">
	            <div class="form">
				   <div class="form-group">
	                <label for="email">E-post</label>
	                <input
	                  type="email"
	                  spellcheck="false"
	                  class="form-control text"
	                  placeholder="Ange epost"
	                  id="validateUsername"
	                  name="validateUsername"
	                  v-model="userData.username"
	                />
				</div>
				<div class="form-group">
	              <label for="pwd">Lösenord</label>
	              <input
	                type="password"
	                class="form-control text"
	                :class="passwordError ? 'error' : ''"
	                placeholder="Ange lösenord"
	                id="validateUserPassword"
	                name="validateUserPassword"
	                @input="passwordError = false"
	                v-model="userData.password"
	              />
				  </div>
	            </div>
	            <div class=" form-check mas-mt-3">
					<label class="mas-mr-3 d-inline-flex">
						<InputCheckbox
							class="mas-mr-2"
							:name="'alert-by-phone'"
							v-model="remember"
							/>
							Kom ihåg mig
					</label>
	            </div>
	            <button
	              type="submit"
	              class="button"
	            >Logga in</button>
	          </form>
			</div>
			</div>
        </div>
      </div>
    </div>
    <div
	 class="background"
      :style="
        `background-image: linear-gradient(0deg, rgba(0,0,0, 0.3), rgba(0,0,0, 0.3)), url(${bg}); `
      "
    ></div>
  </div>
</template>

<script>
import bg from "@/assets/login-background-image-with-graphics.png";
import Cookies from "js-cookie";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useApi } from "@/plugins/api";
import { useNotifications } from "@/plugins/notifications";
import $ from "jquery";
export default {
  setup() {
    const api = useApi();
    const notifications = useNotifications();
		const router = useRouter();

    const remember = ref(false);
    const passwordError = ref(false);
    const SessionKey = ref("");

    const userData = ref({
      username: "",
      password: ""
    });

    const getCredentials = () => {
      const user = Cookies.get("ips_credentials_user");
      if (user) {
        userData.value.username = user;
        remember.value = true;
      }
    };

    const login = () => {
      if (remember.value) {
        Cookies.set("ips_credentials_user", userData.value.username, { expires: 365 });
      }
      api.login(
          {
            validateUsername: userData.value.username,
            validateUserPassword: userData.value.password,
            SessionKey: Cookies.get("MASFrontendSessionKey")
          },
          notifications
        )
        .then((res) => {
          Cookies.set("MASFrontendSessionKey", res.SessionKey);
          return true;
        })
        .then((res) => {
          router.push(router.currentRoute.value.query.redirect ? router.currentRoute.value.query.redirect : "/");
        })
        .catch((err) => {
          userData.value.password = "";
          passwordError.value = true;
        });
    };

    onMounted(() => {
      getCredentials();
    });

    return {
      passwordError,
      userData,
      remember,
      login,
      bg
    };
  }
};
</script>

<style lang="scss" scoped>

.background{
	left: -20px;
	right: -20px; 
	top: -20px; 
	bottom: -20px; 
	transition: all 1s ease-in-out; 
	background-size: 50% 100%; 
	position: absolute;
	background-repeat: no-repeat; /* Prevent the image from repeating */
  	background-color: $ma-paleBlueGray; 
}
.ips-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.ips-login-form {
  width: 1065px;
  height: 566px;
  color: white;
  background: linear-gradient(254.65deg, $ma-deepBlueNavy -5.08%, $ma-bluish 91.04%);
  display: inline-flex;
  border-radius: 30px;

.section-1{
	width: 50%;
	position: relative;
	img.logo-text{
		width: 220px;
		height: 216px;
		position: absolute;
		top: 29%;
		left: 29%;
	}
}
p{
	margin: 0;
}

.section-2{
	background-color: $ma-deepNavyBlue;
	width: 50%;
	position: relative;


		.form{
			display: inline-grid;
			row-gap: 15px;
		.form-group{
			display: inline-grid;
			row-gap: 7px;
		}
	}

	input.text{
		width: 305px;
		height: 50px;
		top: 450px;
		left: 918px;
		border-radius: 6px;
		background: $ma-darkBlueLightGrey;
		border: 1px solid $ma-greenishGray33;
		padding-left: 19px;
		color: $ma-lightGray;

	}
	

	input:focus{
		outline: unset;
		border: 1px solid $ma-greenishGray100
	}

	.form-check-input{
		width: 18px;
		height: 18px;
		border-radius: 6px;
		background: linear-gradient(0deg, rgba(95, 169, 238, 0.2), rgba(95, 169, 238, 0.2)),
		linear-gradient(0deg, rgba(95, 169, 238, 0.05), rgba(95, 169, 238, 0.05));
		border: 1px solid $ma-lightBlue33;

	}

	button{
		margin-top: 1rem;
		width: 305px;
		height: 55px;
		border-radius: 8px;
		background: linear-gradient(91.28deg, $ma-brightOrangeV1 -10.43%, $ma-brightOrangeV2 121.84%) !important;
		box-shadow: 0px 10px 30px 0px $ma-orangeShade;
		font-size: 20px;
	}

}

.section-2 > div {
	position: absolute;
    top: 16%;
    left: 22%;
}

.title{
	margin: 0;
    color: white;
    font-size: 40px;
}

.jfyi{
	color: $ma-lightGray;
	font-size: 17px;
	margin-top: -6px;
	margin-bottom: 21px;
}

label{
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: $ma-lightGray;
}

.checkBoxs{
	width: 18px;
	height: 18px;
	background: $ma-darkBlueLightGrey;
	border: 1px solid $ma-greenishGray33;
}

	.checkBoxs:checked {
		background: $ma-darkBlueLightGrey;
		border: 1px solid $ma-greenishGray100 !important;
	}

	.checkBoxs:checked:after {
		font-size: 13px;
		color: $ma-lightGray;
	}
}

</style>
