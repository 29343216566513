<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="12">
				<GlobXpanel :title="$t('administration.user.users')">
					<button @click="showCreateModal = true" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">{{$t('general.create_new')}}</button>
					<InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
					<GlobTable
						:maxHeight="50"
						:data="{
							head : mainListHead,
							body : filteredItems
						}"
						:actions="actions"
					/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>

<!-- Create user modal -->
<GlobModal :widthInPx="1000" :title="$t('administration.user.create')" @close="showCreateModal = false" :show="showCreateModal" :isCRUD="true" v-if="showCreateModal">
	<Create 
		:roles="roles"
		@created="(user) => loadMainList()"
		@close="showCreateModal = false" />
</GlobModal>
<!-- Edit user modal -->
<GlobModal :widthInPx="1100" :title="selectedItem.Name ? selectedItem.Name : selectedItem.ArticleNumber" @close="selectedItem = null" :show="selectedItem && selectedItem.Id && !hideEditModal" v-if="selectedItem && selectedItem.Id" :isCRUD="true">
	<Edit 
		:roles="roles"
		:itemId="selectedItem.Id" 
		@updated="(user) => updated(user)"
		@showDeleteConfirmModal="showDeleteConfirmModal = true"
		@showPlanDeleteConfirmModal="(id) => selectedMaintenancePlanId = id"
		@showAssetServiceReasonDeleteModal = "(id) => selectedAssetServiceRelationId = id"
		@showAssetSurveillanceDeleteModal = "showAssetSurveillanceDeleteModal = true"
			
		/>
</GlobModal>
	<!-- Delete confirm modal -->
	<GlobConsequence :show="showDeleteConfirmModal" 
		@close="showDeleteConfirmModal=false"  
		@confirm = "deleteItem()"
		:title="$t('administration.user.delete_user')" 
		:message="$t('administration.user.delete_confirm')"  />

</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import Create from "@/views/crud/User/Create.vue";
import Edit from "@/views/crud/User/Edit.vue";

export default {
components: {
	Navbar,
	Create,
	Edit
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.users);
    
	const roles = ref([]);
	const selectedItem = ref(null);

	const showCreateModal = ref(false);
	const showDeleteConfirmModal = ref(false)

	const hideEditModal = computed(() =>{
		return showDeleteConfirmModal.value ? true : false;
	})

	const isLoading = ref(false);
	const notifications = useNotifications();
	const searchString = ref('');
	const mainList  = ref([]);
	const mainListHead = ref([
			{
				Alias: "Name",
				Value: computed(() => tm('administration')['user']['fields']['name'])
			},
			{
				Alias: "LastName",
				Value: computed(() => tm('administration')['user']['fields']['last_name'])
			},
			{
				Alias: "Email",
				Value: computed(() => tm('administration')['user']['fields']['email'])
			},
			{
				Alias: "EmployeeNumber",
				Value: computed(() => tm('administration')['user']['fields']['employee_number'])
			},
			{
				Alias: "Pin",
				Value: computed(() => tm('administration')['user']['fields']['pin'])
			}
		]);
	
	const actions = computed(() => { return [
		{
			name: tm('general')['button']['edit'],
			color: "green",
				action: (obj) => {
					selectedItem.value = obj;
				}
		}
		];
	});
	
	 onMounted(async() => {
          loadMainList();
	 });

	const loadMainList = async() => {
		isLoading.value = true;
		endpoint.value.get().then(function(res){
		   roles.value = res.roles;
           mainList.value = res.data;
		   isLoading.value = false;
		})
	}

	const filteredItems = computed(() => {
		return searchString.value.length > 0 ? mainList.value
				.filter(
					i => (i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase()))
					) : mainList.value;
	})


	const created = (user) => {
		showCreateModal.value = false;  
		selectedItem.value = user;
		loadMainList();
	}

	const updated = (user) => {
		let index = mainList.value.findIndex((i) => i.Id == user.Id)
		mainList.value[index] = user;
		selectedItem.value = null;
	}

	const deleteItem = () => {
		showDeleteConfirmModal.value = false;
		isLoading.value = true;
		endpoint.value.delete(selectedItem.value.Id).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['user']['delete_success']
					},
					"success",
					false
			);
			selectedItem.value = null;
			loadMainList();
		});
	}


    return {
		loadMainList,
		filteredItems,
		endpoint,
		mainList,
		mainListHead,
		searchString,
		actions,
		roles,
		routes,
		isLoading,
		selectedItem,
		hideEditModal,
		showCreateModal,
		created,
		//delete
		showDeleteConfirmModal,
		deleteItem,
		updated
    };
  }
};
</script>