<template>
  <teleport to="#status-field-portal">
    <ul class="ips-admin-navbar">
      <li class="ips-admin-navbar__item" v-for="item in items" :key="'navbar-item-' + item.key">
        <router-link :to="item.route" v-if="!item.children">{{ item.languageSupport ? $t(item.name) : item.name }}</router-link>
        <button v-else @click="expand(item.key)">
          {{ item.name }}<i class="fas" :class="cExpanded.includes(item.key) ? 'fa-caret-up' : 'fa-caret-down'"></i>
        </button>
        <ul
          class="ips-admin-navbar__subitems"
          v-if="item.children"
          :style="`height: ${cExpanded.includes(item.key) ? item.children.length * 80 : 0}px`"
        >
          <li
            class="ips-admin-navbar__subitems-item"
            @click="expand(false)"
            v-for="child in item.children"
            :key="item.key + '-child-' + child.slug"
          >
            <router-link :to="`/admin/view/${child.slug}`">{{ child.name }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </teleport>
</template>

<script>
export default {
  props: ["items", "languageSupport"],
  data() {
    return {
      expanded: []
    };
  },
  methods: {
    expand(key, closeAll, closeAfter) {
      if (closeAll) {
        this.expanded = [];
      }
      if (this.expanded.includes(key)) {
        this.expanded.splice(this.expanded.indexOf(key), 1);
      } else {
        this.expanded.push(key);
      }
      if (key == false) {
        this.expanded = [];
      }
    }
  },
  computed: {
    cExpanded() {
      return this.expanded;
    }
  }
};
</script>

<style lang="scss">
.ips-admin-navbar {
  
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 3rem;
  color: white;
  list-style-type: none;
  padding: 0 1rem;
  margin-top: 0;
  margin-left: 7px;
  margin-bottom: 15px;
  &__item {
    position: relative;
    button,
    a {
      transition: 0.2s ease-in-out;
      margin: 0;
      font-size: 18px;
      border: none;
      background: transparent;
      color: $ma-bluish-80per;
      font-weight: 400;
      &:focus {
        outline: 0;
      }
      i {
        margin-left: 0.5rem;
      }
    }
  }
  &__subitems {
    z-index: 999;
    list-style-type: none;
    position: absolute;
    right: 0;
    overflow: hidden;
    overflow-y: auto;
    @include scrollbar(4px);
    min-width: 100%;
    margin-top: 1rem;
    padding: 0;
    transition: all 0.3s ease-in-out;
    &-item {
      width: 100%;
      text-align: center;
      a {
        border-bottom: 1px solid $mas-gray;
        border-left: 1px solid $mas-gray;
        border-right: 1px solid $mas-gray;
        background-color: $mas-lightGray;
        padding: 0.5rem 1rem;
        color: black;
        display: block;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: darken($mas-lightGray, 10%);
        }
      }
      &:first-child {
        a {
          border-top: 1px solid $mas-gray;
        }
      }
    }
  }
}
</style>
