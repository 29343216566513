<template>
    <div class="asset-count">
        <GlobXpanel>
             <h2>{{ $t('dashboard.assets') }}</h2>
             <p class="count">{{ assetCount }}</p>
        </GlobXpanel>
    </div>
</template>

<script>

export default {
    props: ["assetCount"],
    setup(props) {
        return {
        }
    }
}
</script>
<style lang="scss">
.asset-count {
    .ips-xpanel__content {
        padding-top: 4px;
    }

    h2 {
        text-align: center;
    }

    p.count{
        margin: 0;
        text-align: center;
        font-weight: bold;
        font-size: 70px;
    }
}</style>
