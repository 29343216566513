<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="12">
				<GlobFilter
						@apply="(e) => loadItems(e)"
						:languageSupportCustomField="true"
						:inputMinWidth="'250px'"
						:enable="false"
						:dateToggle="false"
						:useDate="false"
						:assemblyToggle="false"
						:materialPlanSelectToggle="false"
						:multiselect="true"
						:endpoint="filterURL"
						>
				</GlobFilter>
			</GlobCol>
			<GlobCol :medium="12">
				<GlobXpanel :title="$t('maintenance_plan.service_log_menu.service_logs')">
					<GlobTable
						:maxHeight="50"
						:data="{
							head : mainListHead,
							body : mainList
						}"
						:itemRenderFunctions="renderFunctions"
						:actions="actions"
					/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>

		<!-- Show service logs -->
		<GlobModal :widthInPx="800" :title="$t('maintenance_plan.service_log_menu.view.heading')" @close="showServiceLogsModal = false" :show="showServiceLogsModal">
			<GlobSpinner :isLoading="isServiceLogModalLoading" />
			<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" :isCRUD="true" class="mas-service-log-view">
				<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
					<GlobRow>
						<GlobCol :medium="12" v-if="serviceLog && serviceLog.service_log_responsible && serviceLog.service_log_responsible.length">
							<p class="section-head">
								{{$t('maintenance_plan.service_log_menu.view.executed_by')}}
							</p>
							<GlobRow v-for="logResponsible in serviceLog.service_log_responsible" :key="'service-log-view-responsible-'+logResponsible.Id" class="service-log-respoisble-persons">	
								<GlobCol :medium="3">
									<label>
										{{$t('maintenance_plan.service_log_menu.view.user')}}
										<h4>{{logResponsible.account.Name}}</h4>
									</label>
								</GlobCol>
								<GlobCol :medium="3">
									<label>
										{{$t('maintenance_plan.service_log_menu.view.started')}}
										<h4>{{moment(logResponsible.StartTime).format('YYYY-MM-DD HH:mm')}}</h4>
									</label>
								</GlobCol>
								<GlobCol :medium="3">
									<label>
										{{$t('maintenance_plan.service_log_menu.view.stopped')}}
										<h4>{{logResponsible.StopTime ? moment(logResponsible.StopTime).format('YYYY-MM-DD HH:mm') : ''}}</h4>
									</label>
								</GlobCol>
								<GlobCol :medium="3">
									<label>
										{{$t('maintenance_plan.service_log_menu.view.total_time')}}
										<h4>{{logResponsible.TotalTime ? logResponsible.TotalTime : 0 }} {{$t('maintenance_plan.service_log_menu.view.minutes')}}</h4>
									</label>
								</GlobCol>
							</GlobRow>
						</GlobCol>
						<GlobCol :medium="12">
							<p class="section-head">
								{{$t('maintenance_plan.service_log_menu.view.comments')}}
							</p>
							<div class="description">
								{{serviceLog.Comment}}
							</div>
						</GlobCol>
						<GlobCol :medium="12" v-if="serviceLog && serviceCheckList.length">
							<p class="section-head">
								{{$t('maintenance_plan.service_log_menu.view.check_list')}}
							</p>
							 <div class="check-list">
								<div class="check-list-item" v-for="checkListItem in serviceCheckList" :key="'check-list-'+checkListItem.Id">
									<span v-if="serviceLog && serviceLog.service_log_check_list && serviceLog.service_log_check_list.find(i => i.MaintenanceServiceDescriptionCheckListId == checkListItem.Id)">
										<i class="fas fa-check"></i>
									</span>
									<span class="not-completed-checklist" v-else>
										<i class="fas fa-times"></i>
									</span>
									<p>{{checkListItem.Description}}</p>
								</div>
							</div>
							
						</GlobCol>
						<GlobCol :medium="12" v-if="serviceLog && serviceLog.attachments && serviceLog.attachments.length">
							<p class="section-head">
								{{$t('maintenance_plan.service_log_menu.view.uploaded_images')}}
							</p>
							<GlobRow class="file-library-section">
								<GlobCol class="files-section " :medium="3" v-for="attachment in serviceLog.attachments" :key="'library-file-'+attachment.Id">
									<div class='parent'>
										<p>{{attachment.Name.length > 8 ? attachment.Name.slice(0, 8)+'...' : attachment.Name}}</p>
										<div class="thumb" :style="'background-image: url('+bgThumb+')'" @click="downloadFile(attachment.URL, attachment.Name)"></div>						
									</div>
								</GlobCol>
							</GlobRow>
							
						</GlobCol>
						<GlobCol :medium="12" v-if="serviceLog && serviceLog.assetServiceReasons && serviceLog.assetServiceReasons.length">
							<p class="section-head">
								{{$t('maintenance_plan.service_log_menu.view.service_reasons')}}
							</p>
							<GlobRow>
								<GlobCol :medium="12">
									<span v-for="(assetServiceReason, index) in serviceLog.assetServiceReasons" :key="'service-view-asset-reason-'+index" class="multiselect__tag">
										{{assetServiceReason}}
									</span>
								</GlobCol>
							</GlobRow>
							
						</GlobCol>
						<GlobCol :medium="12" v-if="serviceLog && serviceLog.service_tag_relations && serviceLog.service_tag_relations.length">
							<p class="section-head">
								{{$t('maintenance_plan.service_log_menu.view.tags')}}
							</p>
							<GlobRow>
								<GlobCol :medium="12">
									<span v-for="(tagRelation, index) in serviceLog.service_tag_relations" :key="'service-view-tag-'+index" class="multiselect__tag">
										{{tagRelation.service_tag.Name}}
									</span>
								</GlobCol>
							</GlobRow>
							
						</GlobCol>
					</GlobRow>
				</GlobXpanel>
			</GlobXpanel>
		</GlobModal>

		<!-- Show approve service modal -->
		<GlobModal :widthInPx="800" 
				:title="selectedServiceLog && selectedServiceLog.ApprovalStatus == 'approved' ? $t('maintenance_plan.service_log_menu.view.heading') : $t('maintenance_plan.service_log_menu.approve_service.heading')" @close="showApproveServiceModal = false" 
			:show="showApproveServiceModal && !hideApproveServiceModal" v-if="showApproveServiceModal">
			<ApproveService 
				:serviceLogId="selectedServiceLog.Id"
				@close="serviceApproved()"
				@hideApproveServiceModal="(val) => hideApproveServiceModal = val"></ApproveService>
		</GlobModal>
		
</div>
</template>

<script>
import { onMounted, computed, watch, ref, watchEffect, reactive } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import bgThumb from '@/assets/icon/image-thumbnail.png';
import {getSavedFileURLFromPath } from '@/helpers.js';
import moment from 'moment';
import { useNotifications } from "@/plugins/notifications";
import ApproveService from './ApproveService.vue';

export default {
	components: {
	ApproveService
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const notifications = useNotifications();
	const router = useRouter();
	const endpoint = computed(() => api.serviceLogs);
	const filterURL =  computed(() => api.filterURLs.serviceLogs);
	const serviceLog = ref({});
	const serviceCheckList = ref([]);
	 const showApproveServiceModal = ref(false);
	 const selectedServiceLog = ref(null);
	 const hideApproveServiceModal = ref(false);
    
	const isLoading = ref(false);
	const mainList  = ref([]);
	const mainListHead = ref([
			{
				Alias: "AssetName",
				Value: computed(() => tm('maintenance_plan')['service_log_menu']['fields']['asset'])
			},
			{
				Alias: "MaintenancePlanName",
				Value: computed(() => tm('maintenance_plan')['service_log_menu']['fields']['maintenance_plan'])
			},
            {
				Alias: "ServiceDescription",
				Value: computed(() => tm('maintenance_plan')['service_log_menu']['fields']['service_description'])
			},
            {
				Alias: "ServiceTags",
				Value: computed(() => tm('maintenance_plan')['service_log_menu']['fields']['service_tags'])
			},
			{
				Alias: "ExecutionDate",
				Value: computed(() => tm('maintenance_plan')['service_log_menu']['fields']['execution_date'])
			},
            {
				Alias: "ExecutedBy",
				Value: computed(() => tm('maintenance_plan')['service_log_menu']['fields']['executed_by'])
			}

	]);

	const renderFunctions = computed(()=> {
		return {
			ServiceTags(obj){
				let serviceTags = '';
				obj.service_tag_relations.map((item)=>{
					if(serviceTags == ''){
						serviceTags = item.service_tag.Name;
					}else{
						serviceTags = serviceTags+', '+item.service_tag.Name;
					}
				})
				return serviceTags;
			},
			ExecutedBy(obj){
				let responsible = '';
				obj.service_log_responsible.map((item)=>{
					if(responsible == ''){
						responsible = item.account.Name;
					}else{
						responsible = responsible+', '+item.account.Name;
					}
				})
				return responsible;
			},
		}
	});

	const showServiceLogsModal = ref(false);
	const isServiceLogModalLoading = ref(false);



	const actions = computed(() => { return [
		{
			icon: 'fas fa-file-alt',
			if: (obj) => {
				return obj.NeedValidation ? false : true;
			},
			description: tm('maintenance_plan')['service_logs']['service_logs'],
			color: "#01188d",
				action: (obj) => {
					isServiceLogModalLoading.value = true;
					showServiceLogsModal.value = true;
					endpoint.value.getDetails(obj.Id).then((res) => {
						serviceLog.value = res.service_log;
						serviceCheckList.value = res.service_check_list;
						isServiceLogModalLoading.value = false;
					});
				}
		},
		{
			icon: 'fas fa-check',
			if: (obj) => {
				return obj.NeedValidation && obj.ApprovalStatus != 'approved' ? true : false;
			},
			color: "green",
					description: tm('maintenance_plan')['service_log_menu']['approve'],
					action: (obj) => {
						selectedServiceLog.value = obj;
						showApproveServiceModal.value = true;
						
					}
		},
		{
					icon: 'fas fa-file-alt',
					if: (obj) => {
						return obj.NeedValidation && obj.ApprovalStatus == 'approved' ? true : false;
					},
					color: "#01188d",
					description: tm('maintenance_plan')['service_log_menu']['approve'],
					action: (obj) => {
						selectedServiceLog.value = obj;
						showApproveServiceModal.value = true;
					}
				},
		];
	});
	
	 onMounted(async() => {
          loadItems();
	 });

	const loadItems = async(e) => {
		isLoading.value = true;

		let body = {filters: {}};
		if (e && e.body) {
			body.filters = e.filters;
		} 
		endpoint.value.get({...body}).then(function(res){
           mainList.value = res.data;
		   isLoading.value = false;
		})
	}

	const downloadFile = (url, filename) => {
		const link = document.createElement("a");
		link.target = '_blank';
		link.href = url;
		link.download = filename;
		link.click();
	}

	 const serviceApproved = () => {
		loadItems();
		showApproveServiceModal.value = false
	}

    return {
		loadItems,
		endpoint,
		mainList,
		mainListHead,
		renderFunctions,
		isLoading,
		filterURL,
		actions,
		showServiceLogsModal,
		isServiceLogModalLoading,
		serviceLog,
		serviceCheckList,
		bgThumb,
		downloadFile,
		moment,
		//Approval
		showApproveServiceModal,
		selectedServiceLog,
		hideApproveServiceModal,
		serviceApproved
    };
  }
};
</script>
<style lang="scss">
.mas-service-log-view{
	.service-log-respoisble-persons{
		h4{
			margin-top: 7px !important;
    		margin-bottom: 7px !important;
		}
	}
	.section-head{
        font-size: 15px;
        margin:0;
        margin-bottom: 1rem;
        font-weight: 600;
    }
	.description{
        padding: 17px 10px 17px 10px;
        font-size: 14px;
        line-height: 20px;
        border: 1px solid $mas-gray;
    }
	 .check-list{
        .check-list-item{
            display: flex; 
            margin-bottom: 12px;
            p{
                margin: 0;
                margin-left: 1rem;
                font-size: 15px;
                font-weight: bolder;
            }

			.not-completed-checklist{
				margin-right: 6px;
			}


            span i{
                font-size: 22px;
            }

			.fa-check{
				color: $mas-parisGreen;
			}

			.fa-times{
				color: $mas-crimsonRed;
			}
        }
    }

	.files-section {
		p{
			margin: 0;
		}
	}
}
</style>