
import TroubleshootGuide from "@/views/crud/TroubleshootGuide/TroubleshootGuide.vue";

export const troubleshootGuide = [{
  path: "/admin/view/troubleshoot-guides",
  component: TroubleshootGuide,
  isCRUD: true,
  name: "TroubleshootGuide",
  nav: {
    name: "Admin",
    icon: "fa-hdd"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Troubleshoot guides" }]
  },
}
]