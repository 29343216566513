<template>
 <div>
	 <Navbar :items="routes" />
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="quickSelected ? 7 :12">
				<GlobXpanel :title="$t('administration.api_key.api_keys')">
					<button @click="showCreateForm()" style="margin-bottom: 1rem; display: inline-block;" class="button button--margin button--small">{{$t('general.create_new')}}</button>
					<InputText type="text" v-model="searchString" :placeholder="$t('general.search')" :style="'margin-left: 1rem; padding: .4rem 1rem'"/>
					<GlobTable
						:maxHeight="50"
						:data="{
							head : mainListHead,
							body : filteredItems
						}"
						:actions="actions"
					/>
				</GlobXpanel>
			</GlobCol>

			<GlobCol v-if="quickSelected" :medium="5">
				<GlobXpanel :title="!dataObject.Id ? $t('general.create') : $t('general.update')">
					<div class="ips-admin-fieldgroup">
							<div class="ips-admin-quickedit">
								<label>
								{{$t('administration.api_key.fields.name')}}*
								<InputDynamic
									:fullWidth="true"
									styling="width: 100%; padding: .5rem;"
									:type="'text'"
									v-model="dataObject.ApiName"
								/>
								</label>
								<label v-if="dataObject.Id">
								{{$t('administration.api_key.fields.name')}}*
								<InputDynamic
									:fullWidth="true"
									styling="width: 100%; padding: .5rem;"
									:type="'text'"
									:disabled="true"
									v-model="dataObject.ApiToken"
								/>
								</label>
								<p class="text-danger mas-mt-2" v-if="errorMessage">{{errorMessage}}</p>
								<button :disabled="!dataObject.ApiName" @click="save()" class="button mas-mt-3">
								{{$t('general.button.save')}}
								</button>
								<button @click="hideCreateForm()" class="button button--orange">
								{{$t('general.button.cancel')}}
								</button>
								<button @click="showDeleteConfirmModal = true;" class="button button--red" v-if="dataObject.Id">
								{{$t('general.button.delete')}}
								</button>
							</div>
					</div>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>

	<!-- Delete confirm modal -->
	<GlobConsequence :show="showDeleteConfirmModal" 
		@close="showDeleteConfirmModal=false"  
		@confirm = "deleteItem()"
		:title="$t('administration.api_key.delete')" 
		:message="$t('administration.api_key.delete_confirm')"  />
</template>

<script>
import { onMounted, computed, ref, reactive } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import Navbar from "@/views/Admin/Navbar.vue";
import { routes } from "@/views/Admin/config";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import { validateForm } from "@/views/crud/APIKey/common.js";

export default {
components: {
	Navbar
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.apiKeys);
    
	const showDeleteConfirmModal = ref(false)
	const isLoading = ref(false);
	const notifications = useNotifications();
	const searchString = ref('');
	const errorMessage = ref(null);

	const mainList  = ref([]);
	const mainListHead = ref([
			{
				Alias: "ApiName",
				Value: computed(() => tm('administration')['api_key']['fields']['name'])
			},
			{
				Alias: "ApiToken",
				Value: computed(() => tm('administration')['api_key']['fields']['key'])
			},

		]);
	
	const actions = computed(() => { return [
		{
			name: tm('general')['button']['edit'],
			color: "green",
				action: (obj) => {
					setEditor(obj);
				}
		},
		{
			name: tm('administration')['api_key']['copy_key'],
			color: "blue",
				action: (obj) => {
					navigator.clipboard.writeText(obj.ApiToken);
					notifications.push(
						{
							msg: tm('administration')['api_key']['copy_key_success']
						},
						"success",
						false
					);
				}
		}
		];
	});
	
	 onMounted(async() => {
          loadMainList();
	 });

	const loadMainList = async() => {
		isLoading.value = true;
		endpoint.value.get().then(function(res){
           mainList.value = res.data;
		   isLoading.value = false;
		})
	}

	const filteredItems = computed(() => {
		return searchString.value.length > 0 ? mainList.value
				.filter(
					i => (i.ApiName && i.ApiName.toLowerCase().match(searchString.value.toLowerCase()))) : mainList.value;
	})

	const constDataObject = {
		'Id': '',
		'ApiName': '',
		'ApiToken': ''
	}
	const dataObject = reactive({...constDataObject});
	const quickSelected = ref(false);
	const setEditor = (obj) => {
		Object.assign(dataObject, obj);
		quickSelected.value = true;
    };

	const showCreateForm = () => {
		
		quickSelected.value = true;
	}

	const hideCreateForm = () =>  {
		Object.assign(dataObject, constDataObject);
		quickSelected.value = false; 
	}


	const save = () => {
		errorMessage.value = validateForm(dataObject);

		if(errorMessage.value){
			return false;
		}
		isLoading.value = true;
		if(!dataObject.Id){
			endpoint.value.create(dataObject).then((res) => {
				notifications.push(
						{
							msg: tm('administration')['api_key']['create_success']
						},
						"success",
						false
				);
				dataObject.value = {};
				quickSelected.value = false;
				loadMainList();
			});
		}else{
			endpoint.value.update(dataObject.Id, dataObject).then((res) => {
				notifications.push(
						{
							msg: tm('administration')['api_key']['update_success']
						},
						"success",
						false
				);
				updated(dataObject)
				dataObject.value = {};
				quickSelected.value = false;
			});
		}


	};

	const updated = (obj) => {
		let index = mainList.value.findIndex((i) => i.Id == obj.Id)
		Object.assign(mainList.value[index], obj)
		isLoading.value = false;
	}

	const deleteItem = () => {
		showDeleteConfirmModal.value = false;
		isLoading.value = true;
		endpoint.value.delete(dataObject.Id).then((res) => {
			notifications.push(
					{
						msg: tm('administration')['api_key']['delete_success']
					},
					"success",
					false
			);
			hideCreateForm();
			loadMainList();
		});
	}

    return {
		loadMainList,
		filteredItems,
		showCreateForm,
		hideCreateForm,
		endpoint,
		mainList,
		mainListHead,
		searchString,
		actions,
		routes,
		quickSelected,
		dataObject,
		errorMessage,
		save,
		isLoading,
		//delete
		showDeleteConfirmModal,
		deleteItem,
    };
  }
};
</script>