<template>
  <teleport to="#modals">
    <div class="ips-modal" 
      :class="{'ips-modal--crud' : isCRUD, 'ips-modal--tabs': isTABS,  'make-modal-background' : makeBackground}"  v-show="show">
      <div ref="popup" class="ips-modal__popup" :style="width ? `width: ${width}vw;` : (widthInPx ? `width: ${widthInPx}px;` : '')" >
        <div class="ips-modal__header">
          <p>{{ title }}</p>
          <button @click="handleClose" class="ips-modal__close"><i class="fas fa-times"></i></button>
        </div>
        <div class="ips-modal__content">
          <slot v-if="isLoaded" />
          <div class="ips-modal__loader" v-else>
            Laddar
          </div>
        </div>
      </div>
      <div class="ips-modal__backdrop"></div>
    </div>
  </teleport>
</template>

<script>
export default {
  emits: ["close"],
  props: {
    show: {
      type: Boolean,
      required: false
    },
    width: {
      type: Number,
      required: false
    },
    widthInPx: {
      type: Number,
      required: false
    },
    isLoaded: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false
    },
    isCRUD: {
      type: Boolean,
      required: false
    },
    isTABS: {
      type: Boolean,
      required: false
    },
	makeBackground: {
		type: Boolean,
      	required: false
	}
  },
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };

    return {
      handleClose
    };
  }
};
</script>

<style lang="scss" scoped>
.ips-modal {
  &__popup {
    position: fixed;
	box-shadow: 0px 10px 30px 0px #00000026;
    border-radius: 10px;
    z-index: 12;
    margin: 1rem 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    width: 80vw;
    max-width: 1900px;
    max-height: 90vh;
	background-color: $ma-white;
    // overflow-y: auto;
    // overflow-y: auto;
	overflow: unset;
  }
  &__content {
	overflow-y: auto;
    max-height: 84vh;
    // background-color: $ma-lightGrayishBlueV1;
  }
  &__header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid $ma-white;
    display: flex;
    justify-content: space-between;
	height: 55px;
    padding-top: 13px;
    padding-left: 22px;
    padding-right: 17px;
    background: linear-gradient(90.1deg, $ma-darkBlue 10.9%, $ma-cornflowerBlue 90.69%);
	p{
		margin: 0;
		color: $ma-white;
		font-size: 20px;
	}
	}
	&__content {
		// padding: .5rem;
	}
  &__close {
    background-color: transparent;
    border: none;
    margin-bottom: 12px;
    color: $ma-white;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
	i{
		font-size: 23px;
		color: $ma-brightOrange;
	}
  }
  &__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 11;
  }
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    height: 100px;
    animation: blink 0.5s ease-in-out infinite;
  }

  &--tabs{
    .ips-modal__content {
      padding: 1.5rem;
    }
  }
}
@keyframes blink {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}
</style>
