<template>
	<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
		<GlobSpinner :isLoading="isLoading"/>
		<GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
			<p class="section-heading">{{ $t('general.general') }}</p>
			<GlobRow>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.user.fields.name')}}*
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.user.fields.name')"
						v-model="user.form.Name"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.user.fields.last_name')}}*
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.user.fields.last_name')"
						v-model="user.form.LastName"
						/>
					</label>
				</GlobCol>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.user.fields.email')}}*
						<InputDynamic
						:fullWidth="true"
						:type="'email'"
						:placeholder="$t('administration.user.fields.email')"
						v-model="user.form.Email"
						/>
					</label>
				</GlobCol>
			</GlobRow>
		</GlobXpanel>
        <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" class="mas-mt-4">
			<p class="section-heading">{{ $t('administration.user.contacts') }}</p>
			<GlobRow>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.user.fields.cell_phone')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.user.fields.cell_phone')"
						v-model="user.form.CellPhone"
						/>
					</label>
				</GlobCol>
			</GlobRow>
		</GlobXpanel>
        <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" class="mas-mt-4">
			<p class="section-heading">{{ $t('administration.user.security') }}</p>
			<GlobRow>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.user.fields.employee_number')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.user.fields.employee_number')"
						v-model="user.form.EmployeeNumber"
						/>
					</label>
				</GlobCol>
                <GlobCol :medium="3">
					<label>
						{{$t('administration.user.fields.pin')}}
						<InputDynamic
						:fullWidth="true"
						:type="'number'"
						:placeholder="$t('administration.user.fields.pin')"
						v-model="user.form.Pin"
						/>
					</label>
				</GlobCol>
			</GlobRow>
            <GlobRow>
				<GlobCol :medium="3">
					<label>
						{{$t('administration.user.fields.password')}}
						<InputDynamic
						:fullWidth="true"
						:type="'password'"
						:placeholder="$t('administration.user.fields.password')"
						v-model="user.form.Password"
						/>
					</label>
				</GlobCol>
                <GlobCol :medium="3">
					<label>
						{{$t('administration.user.fields.confirm_password')}}
						<InputDynamic
						:fullWidth="true"
						:type="'password'"
						:placeholder="$t('administration.user.fields.confirm_password')"
						v-model="user.form.ConfirmPassword"
						/>
					</label>
				</GlobCol>
			</GlobRow>
		</GlobXpanel>
        <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" class="mas-mt-4">
			<p class="section-heading">{{ $t('administration.user.additional_settings') }}</p>
			<GlobRow>
				<GlobCol :medium="6">
					<label>
                        <InputToggle :label="$t('administration.user.fields.account_active')" 
                                :flex="true" 
								:showLabelRightSide="true"
                                v-model="user.form.AccountActive" 
								/>
					</label>
				</GlobCol>
				<GlobCol :medium="12">
					<label>
						{{$t('administration.user.fields.roles_and_resposibility')}}
						<InputDynamic :fullWidth="true" :type="'multiselect'"
							:placeholder="$t('administration.user.fields.roles_and_resposibility')"
							v-model="user.form.AccountRoles" 
							:data="roles"
							:selected="user.form.AccountRoles"/>
					</label>
				</GlobCol>
				<GlobCol :medium="6">
					<p class="text-danger mas-mt-2" v-if="errorMessage">{{errorMessage}}</p>
					<button @click="save" class="button mas-mt-4" :disabled="isSaveButtonDisabled">{{$t('general.button.save')}}</button>
					<button @click="$emit('showDeleteConfirmModal', true)"
						class="button button--red">{{$t('general.button.delete')}}</button>
				</GlobCol>
			</GlobRow>
		</GlobXpanel>
	</GlobXpanel>

</template>

<script>
import {onMounted, reactive, ref, computed, watchEffect } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import {useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { validateForm } from "@/views/crud/User/common.js";

export default {
  props: ['itemId', 'roles'],
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.users);
	const api = useApi();
	const router = useRouter();

	const notifications = useNotifications();
	const errorMessage = ref(null);
	const isLoading = ref(false);

	const user = reactive({
		form: {
            Id: "",
			Name: "",
            LastName: "",
            Email: "",
            Pin: "",
            EmployeeNumber: "",
            Password: "",
            ConfirmPassword: "",
			AccountActive: true,
			AccountRoles: []
		},
	});

	onMounted(()=> {
        getUser();
	})

    const getUser = () => {
		isLoading.value = true;
		endpoint.value.getById(props.itemId).then(function(res){
			
			user.form = res.user;
			user.form.AccountRoles = res.user.AccountMembers.map((item) => {
				return item.AccountRoleId;
			})
			delete user.form.AccountMembers;
            isLoading.value = false;
        })
    }

    const save = () => {
		
		errorMessage.value = validateForm(user.form);

		if(errorMessage.value){
			return false;
		}

		isLoading.value = true;
		endpoint.value.update(props.itemId, user.form).then((res) => {
			isLoading.value = false;
			if(res.success){
				notifications.push(
					{
						msg: tm('administration')['user']['update_success']
					},
					"success",
					false
				);
				emit('updated', res.user)
			}else{
				for(let errorField in res.errors){
				 	let error = JSON.parse(res.errors[errorField][0]);
					errorMessage.value =  tm(error.error).replace('{attribute}', tm(error.attribute));
					break;
				}
			}
		});
    };

	const isSaveButtonDisabled = computed(() => {
		return !user.form.Name || !user.form.LastName || !user.form.Email  ? true : false
	})


    return {
      save,
	  errorMessage,
	  user,
	  isLoading,
	  isSaveButtonDisabled
    };
  },
};
</script>

<style lang="scss">
</style>
