<template>
  <GlobRow>
    <GlobCol :medium="3">
      <select v-model="modelChoice">
        <option value="" selected>Select</option>
        <option v-for="model in models" :key="model">{{model.name}}</option>
      </select>
    </GlobCol>
    <GlobCol :medium="12" v-if="false">
      <template v-if="selectedModel">
        <model-viewer 
            :src="selectedModel.path" 
            :style="{ width: '800px', height: '800px' }"
            camera-controls></model-viewer>
        </template>
    </GlobCol>

   <GlobCol :medium="12" v-if="selectedModel">

    <h3> 3D View</h3>
    <Glob3DView :filePath="selectedModel.path"
    
    />

   </GlobCol>

  </GlobRow>
</template>

<script>
import {computed, ref} from 'vue';
export default {
  setup(){
    const modelChoice = ref('');
    const selectedModel = computed(() => {
        return models.find(i => i.name == modelChoice.value);
    })
    const models = [
      {
        name: 'oven_color.gltf',
        path: '/collada/oven_color.gltf'
      },
      {
        name: 'fabric_shading_01.glb',
        path: '/collada/fabric_shading_01.glb'
      },
      {
        name: 'airboat.obj',
        path: '/collada/airboat.obj'
      },
      {
        name: 'beam.ifc',
        path: '/collada/beam.ifc'
      },
      {
        name: 'blandare4.glb',
        path: '/collada/blandare4.glb'
      },
      {
        name: 'beam.obj',
        path: '/collada/beam.obj'
      }
    ] 
    return {
      models,
      selectedModel,
      modelChoice
    }
  }
};
</script>