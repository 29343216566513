<template>
  <div>
    <label
      class="ips-small-toggle__label"
      @click="updateValue()"
      :class="flex ? 'flex' : ''"
      :style="margin ? 'margin: .5rem;' : ''"
      v-if="label"
    >
      <span v-if="!showLabelRightSide">{{ label }}</span>
      <div class="ips-small-toggle" :class="`${state ? 'active' : ''} ${center ? 'center' : ''}`">
        <div class="ips-small-toggle__track">
          <div class="ips-small-toggle__knob"></div>
        </div>
      </div>
      <span class="mas-ml-2 mas-mt-2" v-if="showLabelRightSide">{{ label }}</span>
    </label>
    <div v-else class="ips-small-toggle" :class="`${state ? 'active' : ''} ${center ? 'center' : ''}`">
      <div class="ips-small-toggle__track" @click="updateValue()">
        <div class="ips-small-toggle__knob"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { props } from "./common";
import { ref } from 'vue';
export default {
	props: [...props, "label", "flex", "margin", "center", "showLabelRightSide"],
	setup(props, { emit }) {
		const getBoolean = (val) =>  {
			if (typeof val == 'number') {
				return val > 0 ? true : false
			} else {
				return val
			}
		}
		let state = ref(getBoolean(props.modelValue));
		const updateValue = (color) => {
      state.value = !getBoolean(state.value);
			emit('update:modelValue', state.value)
		}

		
		return {
			updateValue,
			state,
		}
	}
};
</script>

<style lang="scss">
.ips-small-toggle {
  width: 3rem;
  cursor: pointer;
  &.center {
    margin: 0 auto;
  }
  &__label {
    cursor: pointer;
    &.flex {
      display: inline-flex;
      align-items: center;
      span {
        margin-right: 0.5rem;
        text-transform: uppercase;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: $mas-carbonGrey;
      }
    }
  }
  &__track {
    width: 49px;
    height: 26px;
    border-radius: 100px;
    background-color: darken($mas-lightGray, 5%);
    position: relative;
  }
  &__knob {
    transition: all 0.3s ease-in-out;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background-color: darken($mas-lightGray, 20%);
    @include box-shadow(3);
    position: absolute;
    left: 4px;
    top: calc(35% - 0.75rem);
	
  }
  &.active &__knob {
    left: calc(100% - (2rem - 8.5px));
    background-color: white;
  }

  &.active &__track {
    background-color: $mas-parisGreen;
    position: relative;
  }
}
</style>
