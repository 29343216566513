<template>
    <div class="maintenance-progress">
        <GlobXpanel>
            <div>
                <h2>{{ $t('dashboard.executed_maintanance_this_week')}}</h2>
                <div class="content">
                    <p class="current-value">{{ data.current_week }}</p>
                    <template v-if="data.current_week >= data.previous_week">
                         <span><i class="fas fa-arrow-up increase"></i></span>
                    </template>
                     <template v-else>
                            <span><i class="fas fa-arrow-down decrease"></i></span>
                    </template>
                   
                    <p class="previous-value">{{ data.previous_week }}</p>
                </div>
            </div>
            <div class="mas-mt-3">
                <h2>{{ $t('dashboard.executed_maintanance_this_month') }}</h2>
                <div class="content">
                    <p class="current-value">{{ data.current_month }}</p>
                        <template v-if="data.current_month >= data.previous_month">
                             <span><i class="fas fa-arrow-up increase"></i></span>
                        </template>
                         <template v-else>
                            <span><i class="fas fa-arrow-down decrease"></i></span>
                        </template>
                   
                        <p class="previous-value">{{ data.previous_month }}</p>
                </div>
            </div>
        </GlobXpanel>
    </div>
</template>

<script>

export default {
    props: ["data"],
    setup(props) {
        return {
        }
    }
}
</script>
<style lang="scss">
.maintenance-progress{
    .content{
        display:flex;
        justify-content: center;
        p{
            margin:0;
        }

        p.current-value{
            font-size: 40px;
            font-weight: bolder;
        }

        p.previous-value{
            font-size: 20px;
            font-weight: bolder;
            margin-top: 15px;
        }

        i{
            font-size: 25px;
            margin-left: 1rem;
            margin-right: 1rem;
            margin-top: 17px;
            
        }
        i.increase{
            color: $ma-brightGreen;
        }
        i.decrease{
            color: $ma-brightOrange;
        }
        
    }
    .ips-xpanel__content{
        padding-top: 4px;
    }

    h2{
        text-align: center;
    }
}

</style>
